import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { Col, Row } from 'react-bootstrap';
import { getPack } from '../../../actions/admin/packs/getPack';
import { formatAmount } from '../../../utils/helpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

export default function DetailsPack() {
  const store = useStore();
  let { id } = useParams();
  let navigate = useNavigate();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getPack(id, store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }, [id, store]);

  const pack = useSelector((state) => state?.admin?.pack);

  if (load)
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0">Detail Pack</h5>
          </div>
          <div className="card-body">
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="page-content w-100">
      <div className="card admin-card shadow-sm border-0">
        <div className="card-header">
          <h5 className="mb-0">{pack?.title}</h5>
        </div>

        <div className="card-body">
          {pack?.price_monthly && (
            <div className="mb-3">
              <Row style={{ minHeight: 5 }}>
                <Col md={2}>
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">
                      Prix mensuel
                      <br />
                    </h5>
                  </div>
                </Col>
                <Col md={10}>
                  <p className="mb-0">
                    {formatAmount(
                      parseFloat(pack?.price_monthly || 0).toFixed(2),
                    )}
                    €
                  </p>
                </Col>
              </Row>
            </div>
          )}
          {pack?.price_annual && (
            <div className="mb-3">
              <Row style={{ minHeight: 5 }}>
                <Col md={2}>
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">
                      Prix annuel
                      <br />
                    </h5>
                  </div>
                </Col>
                <Col md={10}>
                  <p className="mb-0">
                    {formatAmount(
                      parseFloat(pack?.price_annual || 0).toFixed(2),
                    )}
                    €
                  </p>
                </Col>
              </Row>
            </div>
          )}
          {pack?.nbr_dok && (
            <div className="mb-3">
              <Row style={{ minHeight: 5 }}>
                <Col md={2}>
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">
                      Nbr DOKs
                      <br />
                    </h5>
                  </div>
                </Col>
                <Col md={10}>
                  <p className="mb-0">{pack?.nbr_dok}</p>
                </Col>
              </Row>
            </div>
          )}
          {/* New fields */}
          <div className="mb-3">
            <Row style={{ minHeight: 5 }}>
              <Col md={2}>
                <div className="d-flex align-items-center mb-3">
                  <h5 className="mb-0">
                    Is Free
                    <br />
                  </h5>
                </div>
              </Col>
              <Col md={10}>
                <p className="mb-0">{pack?.is_free ? 'Yes' : 'No'}</p>
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            <Row style={{ minHeight: 5 }}>
              <Col md={2}>
                <div className="d-flex align-items-center mb-3">
                  <h5 className="mb-0">
                    Free Months
                    <br />
                  </h5>
                </div>
              </Col>
              <Col md={10}>
                <p className="mb-0">{pack?.free_months}</p>
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            <Row style={{ minHeight: 5 }}>
              <Col md={2}>
                <div className="d-flex align-items-center mb-3">
                  <h5 className="mb-0">
                    Is Custom
                    <br />
                  </h5>
                </div>
              </Col>
              <Col md={10}>
                <p className="mb-0">{pack?.is_custom ? 'Yes' : 'No'}</p>
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            <Row style={{ minHeight: 5 }}>
              <Col md={2}>
                <div className="d-flex align-items-center mb-3">
                  <h5 className="mb-0">
                    Details
                    <br />
                  </h5>
                </div>
              </Col>
              <Col md={10}>
                <ReactQuill
                  value={pack?.details}
                  readOnly={true}
                  theme="snow"
                  modules={{
                    toolbar: false // Disable the toolbar
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-between align-items-center">
          <div>
            <Link to="/admin/packs">
              <i className="fas fa-reply-all me-2"></i>Retour à la liste
            </Link>
          </div>
          <div>
            <button
              onClick={() => navigate(`/admin/packs/update/${pack?.id}`)}
              className="btn btn-primary"
              type="button"
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
