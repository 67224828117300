import toast from '../../../utils/toast';
import { apiCall } from '../../../utils/helpers';
import { GET_PACK_SURMESURE, GET_ALL_PACKS_SURMESURE } from '../../../redux/reducers/admin';

export async function createUpdatePackSurmesure(values, id = null, store = {}) {
  const method = id ? 'put' : 'post';
  const url = id 
    ? `/api/pack-surmesure/update/${id}` 
    : '/api/pack-surmesure/create';
  const successMessage = id 
    ? 'Pack sur mesure mis à jour avec succès' 
    : 'Pack sur mesure créé avec succès';

  const { data } = await apiCall({
    method,
    url,
    data: values,
  });
  
  handleResponse(data, store, successMessage);
  return data;
}

export async function getAllPacksSurMesure(store) {
    try {
        const { data } = await apiCall({
            method: 'get',
            url: '/api/pack-surmesure',
        });
      if (data?.type === 'success') {
        store.dispatch({
          type: GET_ALL_PACKS_SURMESURE,
          payload: {
            list: data,
          },
        });
      } else
        store.dispatch({
          type: GET_ALL_PACKS_SURMESURE,
          payload: {
            list: [],
          },
        });
      return data;
    } catch (error) {
      store.dispatch({
        type: GET_ALL_PACKS_SURMESURE,
        payload: {
          list: [],
        },
      });
    }
  }

export async function getPackSurmesure(id, store) {
  const { data } = await apiCall({
    method: 'get',
    url: `/api/pack-surmesure/${id}`,
  });
  
  if (data?.type === 'success') {
    store.dispatch({
      type: GET_PACK_SURMESURE,
      payload: {
        data: data.data,
      },
    });
  }
  
  return data;
}

export async function assignPackSurmesure(campainCode, store = {}) {
  const { data } = await apiCall({
    method: 'post',
    url: '/api/pack-surmesure/assign',
    data: { campain_code: campainCode },
  });
  
  handleResponse(data, store, 'Pack sur mesure assigné avec succès');
  return data;
}

export async function deletePackSurMesure(id, store = {}) {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/pack-surmesure/delete/${id}`,
    });
    
    handleResponse(data, store, 'Pack sur mesure supprimé avec succès');
    return data;
  }

function handleResponse(data, store, successMessage) {
  if (data?.type === 'success') {
    toast.showSuccess(store, data?.message || successMessage);
  } else {
    const errorMessage = data?.message || 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.';
    toast.showError(store, errorMessage);
  }
}