import React, { useEffect, useState } from 'react';
import DataTable from '../../../components/DataTable';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ModalComponent from '../../../components/Modal/Modal';
import toast from '../../../utils/toast';
import Skeleton from '@mui/material/Skeleton';
import { getAllPacks } from '../../../actions/admin/packs/getAllPacks';
import { formatAmount } from '../../../utils/helpers';
import { deletePack } from '../../../actions/admin/packs/delete-pack';
import { GET_CONTRIBUTOR, GET_PACK } from '../../../redux/reducers/admin';

export default function PacksAdmin() {
  const store = useStore();
  const navigate = useNavigate();
  const [modalUpdateStatus, setModalUpdateStatus] = useState(false);
  const [index, setIndex] = useState(null);
  const [idPds, setIdPds] = useState(null);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });

    getAllPacks(store);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);
  const packs = useSelector((state) => state?.admin?.packs);

  console.log(packs);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmitDelete = async (id, indexDok) => {
    try {
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      setIsLoading(true);
      const result = await deletePack(id || null, indexDok, store);
      if (result?.type === 'success') {
        setIsLoading(false);
        setModalUpdateStatus(false);
        toast.showSuccess(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response)
        toast.showError(
          store,
          error?.response?.data?.message ||
            'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        );
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  const headers = [
    {
      title: 'Titre',
      filed: `"title"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50 sorting_desc',
    },
    {
      title: 'Prix mensuel',
      filed: `"price_monthly"`,
      width: '15%',
      className: 'text-uppercase fw-semibold text-black-50',
    },
    {
      title: 'Prix annuel',
      filed: `"price_annual"`,
      width: '15%',
      className: 'text-uppercase fw-semibold text-black-50',
    },
    {
      title: 'Nbr DOKs',
      filed: `"nbr_dok"`,
      width: '10%',
      className: 'text-uppercase fw-semibold text-black-50',
    },
    {
      title: 'Action',
      width: '20%',
      className: 'text-uppercase fw-semibold text-black-50',
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="card shadow-sm border-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Link
              onClick={() =>
                store.dispatch({
                  type: GET_PACK,
                  payload: {
                    data: null,
                  },
                })
              }
              className="link-light btn btn-lg btn-primary"
              to="/admin/packs/create"
            >
              <i className="fas fa-plus-circle me-2"></i>Créer un pack
            </Link>
          </div>
          <DataTable
            headers={headers}
            handleChangeSort={() => console.log()}
            data={getColumns(
              packs,
              setModalUpdateStatus,
              setIndex,
              setIdPds,
              loading,
            )}
          />
        </div>
      </div>
      <ModalComponent
        body="Voulez-vous vraiment supprimer cet Pack"
        title="Supprimer Pack"
        show={modalUpdateStatus}
        onHide={() => setModalUpdateStatus(false)}
        footer={
          <button
            style={{
              backgroundColor: '#00B0F0',
              borderColor: '#00B0F0',
              color: 'white',
            }}
            onClick={() => onSubmitDelete(idPds, index)}
            className="btn btn-primary me-2"
            type="button"
          >
            Supprimer
          </button>
        }
        size="md"
      />
    </div>
  );
}

const getColumns = (
  data,
  setModalUpdateStatus,
  setIndex,
  setIdPds,
  isLoading,
) => {
  return (
    <>
      {isLoading ? (
        <>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
        </>
      ) : data && data.length > 0 ? (
        data.map((item, index) => (
          <tr>
            <td>
              {item?.title || `Pack ${item.nbr_dok} DOKs`}
              <br />
            </td>
            <td>
              {formatAmount(parseFloat(item?.price_monthly || 0).toFixed(2))}€
              <br />
            </td>
            <td>
              {formatAmount(parseFloat(item?.price_annual || 0).toFixed(2))}€
              <br />
            </td>
            <td>
              {item?.nbr_dok}
              <br />
            </td>
            <td className="text-center">
              <Link
                to={`/admin/packs/details/${item.id}`}
                className="btn btn-primary btn-sm btn-action"
                href="#"
              >
                <i className="far fa-eye"></i>
              </Link>
              <Link
                to={`/admin/packs/update/${item.id}`}
                className="btn btn-primary btn-sm btn-action"
                href="#"
              >
                <i className="fas fa-pen"></i>
              </Link>
              <button
                onClick={() => {
                  setIndex(index);
                  setIdPds(item.id);
                  setModalUpdateStatus(true);
                }}
                className="btn btn-primary btn-sm btn-action"
              >
                <i className="fas fa-times"></i>
              </button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="text-center" colSpan={4}>
            Vous n'avez pas encore de packs
          </td>
        </tr>
      )}
    </>
  );
};
