import { createSlice } from '@reduxjs/toolkit';

export const pdsSlice = createSlice({
  name: 'pds',
  initialState: {
    pds: {},
    notifications: { data: [] },
    paramsNotifications: {
      current_page: 1,
      limit: 10,
      is_seen: '',
    },
    countNotification: 0,
    notificationsNotSeen: [],
  },
  reducers: {
    /**Notifications*/
    getNotifications: (state, action) => {
      const { list, params } = action.payload;
      //const data = list.data.filter((item) => !item.is_seen);
      state.notifications = list;
      //state.notificationsNotSeen = { data, total: data.length };
      state.paramsNotifications = params;
    },
    countNotifications: (state, action) => {
      const { count } = action.payload;
      state.countNotification = count;
    },
    removeNotifications: (state, action) => {
      const { removedIds } = action.payload;
      state.notifications.data.splice(removedIds, 1);
    },
    editProfile: (dataSet, action) => {
      const { pds } = action.payload;
      dataSet['pds'] = {
        ...pds,
      };
    },
  },
});

export const EDIT_PROFILE = 'pds/editProfile';
export const GET_ALL_NOTIFICATIONS_USER = 'pds/getNotifications';
export const COUNT_NOTIFICATIONS_USER = 'pds/countNotifications';
export const REMOVE_NOTIFICATIONS_USER = 'pds/removeNotifications';

export default pdsSlice.reducer;
