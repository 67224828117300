import toast from '../../../utils/toast';
import { apiCall } from '../../../utils/helpers';
import { REMOVE_PACK } from '../../../redux/reducers/admin';

export async function deletePack(id, removedIds, store) {
  const { data } = await apiCall({
    method: 'delete',
    url: '/api/packs/destroy/' + id,
  });
  if (data?.type === 'success') {
    store.dispatch({
      type: REMOVE_PACK,
      payload: {
        removedIds,
      },
    });
    return data;
  } else {
    const message = data?.message
      ? data?.message
      : 'error occured while deleting the card';
    toast.showError(store, message);
    return data;
  }
}
