import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Input from '../../../components/Inputs';
import { Formik } from 'formik';
import { useSelector, useStore } from 'react-redux';
import { LOADING_STARTED, LOADING_STOPED } from '../../../redux/reducers/loader';
import toast from '../../../utils/toast';
import * as yup from 'yup';
import SkeletonCreateUpdatePackSurMesure from './Components/SkeletonCreateUpdatePackSurMesure';
import { getPackSurmesure, createUpdatePackSurmesure } from '../../../actions/admin/pack_surmesure';

const CreateUpdatePackSurMesure = () => {
  let navigate = useNavigate();
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const packSurMesure = useSelector((state) => state?.admin?.packSurMesure);

  const initialValues = id
  ? {
      campain_code: packSurMesure?.campain_code || '',
      start_date: packSurMesure?.start_date ? new Date(packSurMesure.start_date).toISOString().split('T')[0] : '',
      end_date: packSurMesure?.end_date ? new Date(packSurMesure.end_date).toISOString().split('T')[0] : '',
      number_of_notifications: packSurMesure?.number_of_notifications || '',
      number_of_impressions: packSurMesure?.number_of_impressions || '',
      number_of_doks: packSurMesure?.number_of_doks || '',
      number_of_starterkit_slots: packSurMesure?.number_of_starterkit_slots || '',
    }
  : {
      campain_code: '',
      start_date: '',
      end_date: '',
      number_of_notifications: '',
      number_of_impressions: '',
      number_of_doks: '',
      number_of_starterkit_slots: '',
    };

  useEffect(() => {
    if (id) {
      store.dispatch({ type: LOADING_STARTED, payload: {} });
      getPackSurmesure(id, store);
      store.dispatch({ type: LOADING_STOPED, payload: {} });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store, id]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const result = await createUpdatePackSurmesure(values, id, store);
      if (result.type === 'success') {
        navigate('/admin/pack-sur-mesure');
        toast.showSuccess(store, result?.message || 'Opération réussie');
    } else {
        toast.showError(store, result?.message || 'Une erreur est survenue');
    }
    } catch (error) {
        toast.showError(
            store,
            error?.response?.data?.message ||
              'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.'
          );
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    campain_code: yup.string().required('Champ requis'),
    start_date: yup.date().required('Champ requis'),
    end_date: yup.date().required('Champ requis'),
    number_of_notifications: yup.number().integer().min(0).required('Champ requis'),
    number_of_impressions: yup.number().integer().min(0).required('Champ requis'),
    number_of_doks: yup.number().integer().min(0).required('Champ requis'),
    number_of_starterkit_slots: yup.number().integer().min(0).required('Champ requis'),
  });

  if (loading)
    return (
      <div className="page-content w-100">
        <div className="card shadow-sm border-0 p-2">
          <SkeletonCreateUpdatePackSurMesure />
        </div>
      </div>
    );

  return (
    <div className="page-content w-100">
      <div className="card admin-card shadow-sm border-0">
        <div className="card-header">
          <h5 className="mb-0">
            {id ? 'Modifier un pack sur mesure' : 'Créer un pack sur mesure'}
          </h5>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ errors, touched, handleSubmit, handleChange, values }) => (
            <div>
              <div className="card-body">
                <Input
                  width="col-lg-6"
                  handleChange={handleChange}
                  value={values.campain_code}
                  errors={errors}
                  touched={touched}
                  label="Code Campagne"
                  type="text"
                  name="campain_code"
                  placeholder="Code Campagne"
                  handleSubmit={handleSubmit}
                />
                <Input
                  width="col-lg-6"
                  handleChange={handleChange}
                  value={values.start_date}
                  errors={errors}
                  touched={touched}
                  label="Date de début"
                  type="date"
                  name="start_date"
                  handleSubmit={handleSubmit}
                />
                <Input
                  width="col-lg-6"
                  handleChange={handleChange}
                  value={values.end_date}
                  errors={errors}
                  touched={touched}
                  label="Date de fin"
                  type="date"
                  name="end_date"
                  handleSubmit={handleSubmit}
                />
                <Input
                  width="col-lg-6"
                  handleChange={handleChange}
                  value={values.number_of_notifications}
                  errors={errors}
                  touched={touched}
                  label="Nombre de notifications"
                  type="number"
                  name="number_of_notifications"
                  placeholder="Nombre de notifications"
                  handleSubmit={handleSubmit}
                />
                <Input
                  width="col-lg-6"
                  handleChange={handleChange}
                  value={values.number_of_impressions}
                  errors={errors}
                  touched={touched}
                  label="Nombre d'impressions"
                  type="number"
                  name="number_of_impressions"
                  placeholder="Nombre d'impressions"
                  handleSubmit={handleSubmit}
                />
                <Input
                  width="col-lg-6"
                  handleChange={handleChange}
                  value={values.number_of_doks}
                  errors={errors}
                  touched={touched}
                  label="Nombre de DOKs"
                  type="number"
                  name="number_of_doks"
                  placeholder="Nombre de DOKs"
                  handleSubmit={handleSubmit}
                />
                <Input
                  width="col-lg-6"
                  handleChange={handleChange}
                  value={values.number_of_starterkit_slots}
                  errors={errors}
                  touched={touched}
                  label="Nombre de slots Starterkit"
                  type="number"
                  name="number_of_starterkit_slots"
                  placeholder="Nombre de slots Starterkit"
                  handleSubmit={handleSubmit}
                />
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <div>
                  <Link to="/admin/pack-sur-mesure">
                    <i className="fas fa-reply-all me-2"></i>Retour à la liste
                  </Link>
                </div>
                <div>
                  <button
                    onClick={() => navigate('/admin/pack-sur-mesure')}
                    className="btn btn-danger"
                    type="button"
                  >
                    Annuler
                  </button>
                  <button
                    onClick={handleSubmit}
                    className={
                      isLoading
                        ? 'link-light btn btn-primary ms-3'
                        : 'login100-form-btn link-light btn btn-primary ms-3'
                    }
                    type="button"
                  >
                    {id ? 'Modifier' : 'Ajouter'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
    );
    };
    
    export default CreateUpdatePackSurMesure;