import toast from '../../../utils/toast';
import { apiCall } from '../../../utils/helpers';

export async function updateConfig(values, store = {}) {
  const { data } = await apiCall({
    method: 'patch',
    url: '/api/app-config/update',
    data: values,
  });
  if (data?.type === 'success') {

  } else {
    const message = data?.message ? data?.message :  'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.';
    toast.showError(store, message);
  }
  return data;
}
