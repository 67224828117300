import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { getNbrDokReference } from '../../../../actions/admin/statistic/get-nbr-dok-reference';
import DataTable from '../../../../components/DataTable';
import Skeleton from '@mui/material/Skeleton';
import { Link } from 'react-router-dom';

export default function NbrDokReference() {
  const store = useStore();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getNbrDokReference(store);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);

  const nbrDokReference = useSelector(
    (state) => state?.statistic?.nbrDokReference,
  );
  console.log(nbrDokReference);
  if (loading)
    return (
      <div className="page-content w-100">
        <div className="card shadow-sm border-0 p-2">
          <section className="mb-5"></section>
        </div>
      </div>
    );
  const headers = [
    {
      title: 'Département',
      filed: ``,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50 sorting_desc',
    },
    {
      title: 'Doks',
      filed: ``,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50',
    },
  ];

  return (
    <Row>
      <DataTable
        filter={true}
        headers={headers}
        handleChangeSort={() => console.log()}
        data={getColumns(nbrDokReference, loading)}
      />
    </Row>
  );
}
const getColumns = (data, isLoading) => {
  return (
    <>
      {isLoading ? (
        <>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
        </>
      ) : data && data.length > 0 ? (
        data.map((item, index) => (
          <tr>
            <td>
              {item.department}
              <br />
            </td>
            <td>
              {item?.totalCount}
              <br />
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="text-center" colSpan={3}>
            Vous n'avez pas encore de DOKS référencés
          </td>
        </tr>
      )}
    </>
  );
};
