import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Input from '../../../components/Inputs';
import { Formik } from 'formik';
import { useSelector, useStore } from 'react-redux';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import toast from '../../../utils/toast';
import * as yup from 'yup';
import { getSpecialitiesDoctolib } from '../../../actions/admin/getSpecialitiesDoctolib';
import SkeletonCreateUpdatePds from './Components/skeletonCreateUpdatePds';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { getPack } from '../../../actions/admin/packs/getPack';
import { createUpdatePack } from '../../../actions/admin/packs/create-update-pack';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const CreateUpdatePack = () => {
  let navigate = useNavigate();
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const paramsExpertise = useSelector((state) => state?.admin?.paramsExpertise);
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const pack = useSelector((state) => state?.admin?.pack);

  useEffect(() => {
    getSpecialitiesDoctolib(store, { ...paramsExpertise, limit: 180 });
    return () => {};
  }, [store]);

  useEffect(() => {
    if (id) {
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      getPack(id, store);
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => {};
  }, [store, id]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const val = {
        title: values?.title,
        price_monthly: values?.price_monthly,
        price_annual: values?.price_annual,
        nbr_dok: values?.nbr_dok,
        is_free: values?.is_free,
        free_months: values?.free_months,
        is_custom: values?.is_custom,
        details: values?.details,
      };
      const result = await createUpdatePack(id, val, store);
      if (result.type === 'success') {
        navigate('/admin/packs');
        setIsLoading(false);
        toast.showSuccess(store, result?.message);
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setIsLoading(false);
      toast.showError(
        store,
        error?.response?.data?.message ||
          'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      );
    }
  };

  const createUpdatePackValidationSchema = yup.object().shape({
    title: yup.string().required('Champ requis'),
    price_monthly: yup.number().typeError('Ne doit être que des chiffres').required('Champ requis'),
    price_annual: yup.number().typeError('Ne doit être que des chiffres').required('Champ requis'),
    nbr_dok: yup.number().typeError('Ne doit être que des chiffres').required('Champ requis'),
    is_free: yup.boolean().required('Champ requis'),
    free_months: yup.number()
      .typeError('Ne doit être que des chiffres')
      .min(0, 'Must be at least 0')
      .max(12, 'Must be at most 12')
      .required('Champ requis'),
    is_custom: yup.boolean().required('Champ requis'),
    details: yup.string().required('Champ requis'),
  });

  if (loading)
    return (
      <div className="page-content w-100">
        <div className="card shadow-sm border-0 p-2">
          <SkeletonCreateUpdatePds />
        </div>
      </div>
    );

  return (
    <div className="page-content w-100">
      <div className="card admin-card shadow-sm border-0">
        <div className="card-header">
          <h5 className="mb-0">
            {id ? 'Modifier un pack' : 'Créer un pack'}
          </h5>
        </div>
        <Formik
          initialValues={{
            title: pack?.title || '',
            price_monthly: pack?.price_monthly || '',
            price_annual: pack?.price_annual || '',
            nbr_dok: pack?.nbr_dok || '',
            is_free: pack?.is_free || false,
            free_months: pack?.free_months || 0,
            is_custom: pack?.is_custom || false,
            details: pack?.details || '',
          }}
          validationSchema={createUpdatePackValidationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            handleChange,
            values,
          }) => (
            <div>
              <div className="card-body">
                <Input
                  width="col-lg-6"
                  handleChange={handleChange}
                  value={values.title}
                  errors={errors}
                  touched={touched}
                  label="Titre"
                  type="text"
                  name="title"
                  placeholder="Titre"
                  handleSubmit={handleSubmit}
                />
                <Input
                  width="col-lg-6"
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  value={values.price_monthly}
                  errors={errors}
                  touched={touched}
                  label="Prix mensuel"
                  type="text"
                  name="price_monthly"
                  placeholder="Prix mensuel"
                  icon={
                    <i
                      style={{ color: '#646464' }}
                      className="fas fa-euro"
                    ></i>
                  }
                />
                <Input
                  width="col-lg-6"
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  value={values.price_annual}
                  errors={errors}
                  touched={touched}
                  label="Prix annuel"
                  type="text"
                  name="price_annual"
                  placeholder="Prix annuel"
                  icon={
                    <i
                      style={{ color: '#646464' }}
                      className="fas fa-euro"
                    ></i>
                  }
                />
                <Input
                  width="col-lg-6"
                  handleChange={handleChange}
                  value={values.nbr_dok}
                  errors={errors}
                  touched={touched}
                  label="Nbr DOKs"
                  type="text"
                  name="nbr_dok"
                  placeholder="Nbr DOKs"
                  handleSubmit={handleSubmit}
                />
                {/* New fields */}
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="is_free"
                      name="is_free"
                      checked={values.is_free}
                      onChange={() => setFieldValue('is_free', !values.is_free)}
                    />
                    <label className="form-check-label" htmlFor="is_free">
                      Is Free
                    </label>
                    {errors.is_free && touched.is_free ? (
                      <div className="text-danger">{errors.is_free}</div>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3">
                  <Input
                    width="col-lg-6"
                    handleChange={handleChange}
                    value={values.free_months}
                    errors={errors}
                    touched={touched}
                    label="Free Months"
                    type="number"
                    name="free_months"
                    placeholder="Free Months"
                    min="0"
                    max="12"
                    handleSubmit={handleSubmit}
                  />
                  {errors.free_months && touched.free_months ? (
                    <div className="text-danger">{errors.free_months}</div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="is_custom"
                      name="is_custom"
                      checked={values.is_custom}
                      onChange={() => setFieldValue('is_custom', !values.is_custom)}
                    />
                    <label className="form-check-label" htmlFor="is_custom">
                      Is Custom
                    </label>
                    {errors.is_custom && touched.is_custom ? (
                      <div className="text-danger">{errors.is_custom}</div>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Details</label>
                  <ReactQuill
                    value={values.details}
                    onChange={(value) => setFieldValue('details', value)}
                    theme="snow"
                    height="200px"
                  />
                  {errors.details && touched.details ? (
                    <div className="text-danger">{errors.details}</div>
                  ) : null}
                </div>
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <div>
                  <Link to="/admin/packs">
                    <i className="fas fa-reply-all me-2"></i>Retour à la liste
                  </Link>
                </div>
                <div>
                  <button
                    onClick={() => navigate('/admin/packs')}
                    className="btn btn-danger"
                    type="button"
                  >
                    Annuler
                  </button>
                  <button
                    onClick={handleSubmit}
                    className={
                      isLoading
                        ? 'link-light btn btn-primary ms-3'
                        : 'login100-form-btn link-light btn btn-primary ms-3'
                    }
                    type="button"
                  >
                    {id ? 'Modifier' : 'Ajouter'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateUpdatePack;
