import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Col, Row } from 'react-bootstrap';
import { getContributor } from '../../../actions/admin/getContributor';

export default function DetailsContributor() {
  const store = useStore();
  let navigate = useNavigate();
  let { id } = useParams();
  const [load, setLoad] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getContributor(id, store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }, [load]);

  const [isLoading, setIsLoading] = useState(false);
  const contributor = useSelector((state) => state?.admin?.contributor);

  if (load)
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0">Detail contributeur</h5>
          </div>
          <div className="card-body">
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="page-content w-100">
      <div className="card admin-card shadow-sm border-0">
        <div className="card-header">
          {' '}
          <div style={{ alignItems: 'center', minHeight: 40 }} className="row">
            <div className="col-md-10">
              <h5 className="mb-0">Detail contributeur</h5>
            </div>
            <div className="col-md-2 float-right">
              {contributor?.user.status === 'accepted' ? (
                <span style={{ float: 'right' }}>
                  <svg height="14" width="14">
                    <circle cx="6" cy="6" r="6" fill="#00949E"></circle>
                  </svg>{' '}
                  Actif
                </span>
              ) : (
                <span style={{ float: 'right' }}>
                  <svg height="14" width="14">
                    <circle cx="6" cy="6" r="6" fill="#E61610"></circle>
                  </svg>{' '}
                  inActif
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="card-body">
          {contributor?.society && (
            <div className="mb-3">
              <Row style={{ minHeight: 5 }}>
                <Col md={2}>
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">
                      Société
                      <br />
                    </h5>
                  </div>
                </Col>
                <Col md={10}>
                  <p className="mb-0">{contributor?.society}</p>
                </Col>
              </Row>
            </div>
          )}

          {contributor?.user?.email && (
            <div className="mb-3">
              <Row style={{ minHeight: 5 }}>
                <Col md={2}>
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">
                      Email
                      <br />
                    </h5>
                  </div>
                </Col>
                <Col md={10}>
                  <p className="mb-0">{contributor?.user?.email}</p>
                </Col>
              </Row>
            </div>
          )}
          {contributor?.address && (
            <div className="mb-3">
              <Row style={{ minHeight: 5 }}>
                <Col md={2}>
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">
                      Adresse
                      <br />
                    </h5>
                  </div>
                </Col>
                <Col md={10}>
                  <p className="mb-0">
                    {contributor?.address}
                    {contributor?.zip_code} {contributor.town}
                  </p>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div className="card-footer d-flex justify-content-between align-items-center">
          <div>
            <Link to="/admin/contributor">
              <i className="fas fa-reply-all me-2"></i>Retour à la liste
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
