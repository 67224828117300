import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { countNotifications } from './count-notifications';

export async function notificationIsSeen(id, store = {}) {
  console.log(id);
  const { data } = await apiCall({
    method: 'patch',
    url: `/api/notification/is-seen/${id}`,
  });
  if (data?.type === 'success') {
    await countNotifications(store);
  } else {
    const message = data?.message ? data?.message :  'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.';
    toast.showError(store, message);
  }
  return data;
}
