import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { LOADING_STARTED, LOADING_STOPED } from '../../../redux/reducers/loader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { Col, Row } from 'react-bootstrap';
import { getPackSurmesure } from '../../../actions/admin/pack_surmesure';

export default function DetailsPackSurMesure() {
  const store = useStore();
  let { id } = useParams();
  let navigate = useNavigate();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    store.dispatch({ type: LOADING_STARTED, payload: {} });
    getPackSurmesure(id, store);
    store.dispatch({ type: LOADING_STOPED, payload: {} });
    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }, [id, store]);

  const packSurMesure = useSelector((state) => state?.admin?.packSurMesure);

  if (load)
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0">Détail Pack Sur Mesure</h5>
          </div>
          <div className="card-body">
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="page-content w-100">
      <div className="card admin-card shadow-sm border-0">
        <div className="card-header">
          <h5 className="mb-0">Pack Sur Mesure: {packSurMesure?.campain_code}</h5>
        </div>

        <div className="card-body">
          <DetailRow label="Code Campagne" value={packSurMesure?.campain_code} />
          <DetailRow label="Date de début" value={new Date(packSurMesure?.start_date).toLocaleDateString()} />
          <DetailRow label="Date de fin" value={new Date(packSurMesure?.end_date).toLocaleDateString()} />
          <DetailRow label="Nombre de notifications" value={packSurMesure?.number_of_notifications} />
          <DetailRow label="Nombre d'impressions" value={packSurMesure?.number_of_impressions} />
          <DetailRow label="Nombre de DOKs" value={packSurMesure?.number_of_doks} />
          <DetailRow label="Nombre de slots Starterkit" value={packSurMesure?.number_of_starterkit_slots} />
        </div>

        <div className="card-footer d-flex justify-content-between align-items-center">
          <div>
            <Link to="/admin/pack-sur-mesure">
              <i className="fas fa-reply-all me-2"></i>Retour à la liste
            </Link>
          </div>
          <div>
            <button
              onClick={() => navigate(`/admin/pack-sur-mesure/update/${packSurMesure?.id}`)}
              className="btn btn-primary"
              type="button"
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const DetailRow = ({ label, value }) => (
  <div className="mb-3">
    <Row style={{ minHeight: 5 }}>
      <Col md={4}>
        <div className="d-flex align-items-center mb-3">
          <h5 className="mb-0">{label}</h5>
        </div>
      </Col>
      <Col md={8}>
        <p className="mb-0">{value}</p>
      </Col>
    </Row>
  </div>
);