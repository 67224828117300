import React from 'react';
import AddSharpIcon from '@mui/icons-material/AddSharp';
const Empty = (props) => {
  const {
    columnId,
    col,
    setColumnId,
    setIndexDok,
    setDataDok,
    setOpenModalDok,
    index,
    showBtn,
  } = props;
  return (
    <div key={columnId} className={col ? col : 'col-md-12 col-sm-12'}>
      <div style={{ height: '10em' }} className="card mb-3 shadow empty">
        {!showBtn && (
          <div className="card-body text-center  flex-column justify-content-around align-items-lg-center">
            <button
              className="btn btn-light btn-sm border rounded-pill d-lg-flex align-items-lg-center"
              type="button"
              style={{ padding: '8px 15px' }}
              onClick={() => {
                setColumnId(columnId);
                setIndexDok(index);
                setDataDok(null);
                setOpenModalDok(true);
              }}
            >
              {/* <i className="fas fa-plus fs-6" style={{ margin: '0 7px 0 0' }}></i>*/}
              <AddSharpIcon style={{ margin: '0 1px 0 0' }} />
              DOK
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Empty;
