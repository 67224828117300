import React, { useState, useEffect, Fragment } from 'react';
//import React, { Component } from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';
import { NumberToLetter } from 'convertir-nombre-lettre';
import moment from 'moment';
import Icon from '../../../assets/template/img/logo-dokatis.png';
import IconKine from '../../../assets/template/img/logo-dokatis.png';
import { useLocation } from 'react-router-dom';
import { formatAmount } from '../../../utils/helpers';
// Create styles
const newstyles = StyleSheet.create({
  page: {
    display: 'inline-grid',
    flexDirection: 'row',
    marginTop: 10,
  },
  PDFViewer: {
    width: '100%',
    height: '850px',
  },
  section: {
    marginLeft: 10,
    padding: 10,
  },
  sectionNameDoctor: {
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
  },
  sectionLogo: {
    marginLeft: 10,
    marginRight: 80,
    padding: 10,
  },
  section2: {
    padding: 10,
    marginLeft: -10,
  },
  hr: {
    BorderTop: '1px bold red',
  },
  cabinet: {
    display: 'flex',
    marginTop: 30,
    flexDirection: 'row',
    marginLeft: 30,
    marginRight: 60,
  },
  sectionBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBillText: {
    color: '#070707',
    fontWeight: '1000',
    fontSize: 12,
    fontStyle: 'gras',
    //fontFamily: 'Georgia, serif',
    margin: 3,
  },
  detailBillText2: {
    color: '#070707',
    fontWeight: 'bolder',
    marginLeft: 10,
    padding: 10,
  },
  text: {
    color: '#5446db',
    fontWeight: 'bolder',
  },
  bill: {
    color: '#5446db',
    fontWeight: '500',
    marginLeft: '1%',
  },
  text1: {
    color: '#49494c',
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    margin: 3,
  },
  text2: {
    color: '#49494c',
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    margin: 3,
    width: 150,
  },
  images: {
    marginTop: 150,
    marginLeft: 20,
    // padding: 10,
    width: 50,
    height: 20,
  },
});
function FacturePdf() {
  const location = useLocation();
  console.log(location.state);
  let { bill } = location.state;
  let price = 0;
  if (bill) price = parseFloat(bill.amount).toFixed(2);
  return (
    <>
      <Fragment>
        <PDFViewer style={newstyles.PDFViewer}>
          <Document>
            orientation="landscape"
            <Page size="A4">
              <View style={newstyles.cabinet}>
                <View style={newstyles.section}>
                  <Text style={newstyles.detailBillText}>Effectué à</Text>
                  <Text style={newstyles.detailBillText}>Email</Text>
                  <Text style={newstyles.detailBillText}>Adresse</Text>
                  <Text style={newstyles.detailBillText}>Téléphone</Text>
                </View>
                <View style={{ ...newstyles.section2, width: 400 }}>
                  <Text style={newstyles.text1}>
                    {` ${bill?.first_name} ${bill?.last_name}`}{' '}
                  </Text>
                  <Text style={newstyles.text1}>{bill.email}</Text>
                  <Text
                    style={newstyles.text1}
                  >{`${bill.address} ${bill.zip_code}, ${bill.town}`}</Text>
                  <Text style={newstyles.text1}>{bill.phone}</Text>
                </View>
                <View style={newstyles.sectionNameDoctor}>
                  <Image style={{ width: 60, height: 60 }} src={IconKine} />
                </View>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                }}
              />
              <View
                style={{
                  borderBottomColor: 'black',
                  marginTop: 5,
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 50,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    textAlign: 'center',
                    //fontFamily: 'Georgia, serif',
                    margin: 3,
                  }}
                >
                  Date Facture : {moment(bill.created_at).format('DD/MM/YYYY')}
                </Text>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 130,
                  marginTop: 30,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginLeft: 20,
                    textDecoration: 'underline',
                  }}
                >
                  Note d'honoraire
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 10,
                    marginRight: 60,
                  }}
                >
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>
                      {bill.pack ? 'Pack' : 'Campagne'}
                    </Text>
                    {bill.pack ? (
                      <Text style={newstyles.text1}>Nombre de DOKs</Text>
                    ) : (
                      <Text></Text>
                    )}
                  </View>
                  <View style={newstyles.section}>
                    <Text style={newstyles.detailBillText}>
                      :{' '}
                      {bill?.pack
                        ? bill?.pack.title
                        : bill?.campagne?.document?.title}
                    </Text>
                    {bill.pack ? (
                      <Text
                        style={newstyles.text1}
                      >{`: ${bill.pack.nbr_dok}`}</Text>
                    ) : (
                      <Text></Text>
                    )}
                  </View>
                </View>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 160,
                  marginTop: 30,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginLeft: 20,
                    textDecoration: 'underline',
                  }}
                >
                  Détails
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 10,
                    marginRight: 60,
                  }}
                >
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text2}>Total honoraires HT</Text>
                    <Text style={newstyles.text2}>TVA ( 10% )</Text>
                    <Text style={newstyles.detailBillText}>
                      Total honoraires TTC
                    </Text>
                  </View>
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>:</Text>
                    <Text style={newstyles.text1}>:</Text>
                    <Text style={newstyles.text1}>:</Text>
                  </View>
                  <View style={newstyles.section}>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 350,
                        textAlign: 'right',
                        //textDecoration: 'underline',
                      }}
                    >
                      {`${formatAmount(price - (price * 10) / 100)}€`}
                    </Text>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 350,
                        textAlign: 'right',
                        textDecoration: 'underline',
                      }}
                    >
                      {price < 100
                        ? `0${formatAmount((price * 10) / 100)}€`
                        : `${formatAmount((price * 10) / 100)}€`}
                    </Text>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 350,
                        textAlign: 'right',
                        // textDecoration: 'underline',
                      }}
                    >
                      {`${formatAmount(price)}€`}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 50,
                  marginRight: 60,
                  marginTop: 12,
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                  }}
                >
                  Arretée la présente facture à la somme de :
                </Text>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                  }}
                >
                  #{' '}
                  {NumberToLetter(
                    parseFloat(bill.amount)
                      .toFixed(3)
                      .toString()
                      .replace('.', ''),
                  )
                    .replace('mille', 'Euro')
                    .replace('millions', 'mille')}{' '}
                  #
                </Text>
              </View>
              <View
                style={{
                  height: '40px',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '100%',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <View
                  style={{
                    borderBottomColor: 'black',
                    marginLeft: 50,
                    marginRight: 60,
                    //marginTop: 350,
                    borderBottomWidth: 1,
                  }}
                />
                <View
                  style={
                    {
                      // bottom:0,
                    }
                  }
                >
                  <Text
                    style={{
                      top: 10,
                      color: '#070707',
                      fontWeight: '1000',
                      fontSize: 10,
                      fontStyle: 'gras',
                      //fontFamily: 'Georgia, serif',
                      margin: 3,
                    }}
                  >
                    {bill
                      ? `${bill.address} ${bill.zip_code} , ${bill.town}`
                      : '-'}{' '}
                    {bill && bill.phone ? ` - Tél: ${bill.phone} ` : '-'}
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </Fragment>
    </>
  );
}

export default FacturePdf;
