import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux';
import ReactGA from 'react-ga';

import { render } from 'react-dom';
import { createRoot, hydrateRoot } from 'react-dom/client';
import * as ReactDOMServer from 'react-dom/server';

const container = document.getElementById('root');
const root = hydrateRoot(container);
ReactGA.initialize('G-Y25GSYDM3M');

ReactDOM.render(
  <Suspense fallback="...">
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter basename="/">
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </Suspense>,
  container,
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
