import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { thunk } from 'redux-thunk'
import { createBrowserHistory } from 'history';

import authReducer from './reducers/auth';
import loaderReducer from './reducers/loader';
import toastReducer from './reducers/toast';
import specialityReducer from './reducers/speciality';
import pdsReducer from './reducers/pds';
import diplomaReducer from './reducers/diploma';
import priceReducer from './reducers/price';
import adminReducer from './reducers/admin';
import listReducer from './reducers/list';
import dokReducer from './reducers/dok';
import mosaicReducer from './reducers/mosaic';
import searchReducer from './reducers/search';
import contributorReducer from './reducers/contributor';
import starterKitReducer from './reducers/starter-kit';
import statisticReducer from './reducers/statistic';

const history = createBrowserHistory();
const middlewares = [thunk, routerMiddleware(history)];

export const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loaderReducer,
    toast: toastReducer,
    speciality: specialityReducer,
    diploma: diplomaReducer,
    price: priceReducer,
    pds: pdsReducer,
    admin: adminReducer,
    list: listReducer,
    dok: dokReducer,
    mosaic: mosaicReducer,
    search: searchReducer,
    contributor: contributorReducer,
    starterKit: starterKitReducer,
    statistic: statisticReducer,
    router: routerReducer,
  },
  middlewares,
});
