import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LOADING_STARTED } from '../../../redux/reducers/loader';
import { getAllPacks } from '../../../actions/admin/packs/getAllPacks';
import { useSelector, useStore } from 'react-redux';
import FormPayment from '../payment/form';
import FormModal from '../../../components/Modal/FormModal';
import { RESET_SETTINGS } from '../../../redux/reducers/auth';
import { formatAmount } from '../../../utils/helpers';
import Skeleton from '@mui/material/Skeleton';

export default function Payment() {
  let navigate = useNavigate();
  const store = useStore();
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [modalPayment, setModalPayment] = useState(false);
  const [selected, setSelected] = useState(null);
  const [price, setPrice] = useState(null);
  //const [pack, setPack] = useState(null);
  const userProfile = useSelector((state) => state.auth.userProfile);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });

    getAllPacks(store);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);
  const packs = useSelector((state) => state?.admin?.packs);
  const pack = packs.find((item) => item.id === userProfile.pack_id);
  if (loading) {
    return (
      <main>
        <section className="mb-5">
          <div className="container py-0 py-xl-3">
            <div style={{ justifyContent: 'center' }} className="row mb-2">
              <div className="col-md-8 col-xl-6 text-center mx-auto">
                <h1>
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="30%"
                    style={{ marginBottom: 6 }}
                  />
                </h1>
                <p className="w-lg-50">
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="30%"
                    style={{ marginBottom: 6 }}
                  />
                </p>
              </div>
            </div>
            <div className="tab-content pt-5">
              <div className="tab-pane active" role="tabpanel" id="tab-2">
                <div
                  style={{ justifyContent: 'center' }}
                  className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3"
                >
                  <div className="col">
                    <Skeleton
                      animation="wave"
                      height={250}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
  console.log(pack);
  return (
    <>
      <main>
        <section className="mb-5">
          <div className="container py-0 py-xl-3">
            <div className="row mb-2">
              <div className="col-md-8 col-xl-6 text-center mx-auto">
                <h1>Nos offres</h1>
                <p className="w-lg-50">
                  Curae hendrerit donec commodo hendrerit egestas tempus, turpis
                  facilisis nostra nunc. Vestibulum dui eget ultrices.
                </p>
              </div>
            </div>
            <div>
              <div className="tab-content pt-5">
                <div className="tab-pane active" role="tabpanel" id="tab-2">
                  <div
                    style={{ justifyContent: 'center' }}
                    className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3"
                  >
                    <div className="col">
                      <div className="card h-100">
                        <div className="card-body text-center p-4 flex-grow-0">
                          <h4 className="fw-bold card-subtitle">
                            {pack?.title}
                          </h4>
                          <h4 className="display-4 fw-bold card-title mb-0 mt-2">
                            {userProfile?.pack_type === 'annual'
                              ? formatAmount(pack.price_annual || 0)
                              : formatAmount(pack.price_monthly || 0)}{' '}
                            €
                            <span className="fs-3 fw-normal text-muted">
                              /
                              {userProfile?.pack_type === 'annual'
                                ? 'ans'
                                : 'mois'}
                            </span>
                          </h4>
                        </div>
                        <div className="card-footer d-flex flex-column flex-grow-1 justify-content-between p-4">
                          <div>
                            <ul className="list-unstyled">
                              <li className="d-flex mb-2">
                                <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                    className="bi bi-check-lg"
                                  >
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                  </svg>
                                </span>
                                <span>
                                  Lectus ut nibh quam, felis porttitor.
                                </span>
                              </li>
                              <li className="d-flex mb-2">
                                <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                    className="bi bi-check-lg"
                                  >
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                  </svg>
                                </span>
                                <span>Ante nec venenatis etiam lacinia.</span>
                              </li>
                              <li className="d-flex mb-2">
                                <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                    className="bi bi-check-lg"
                                  >
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                  </svg>
                                </span>
                                <span>Porta suscipit netus ad ac.</span>
                              </li>
                            </ul>
                          </div>
                          <button
                            onClick={() => {
                              if (userProfile?.id) {
                                setModalPayment(true);
                              } else {
                                navigate('/auth/register/contributor');
                                localStorage.setItem('packType', 'monthly');
                                localStorage.setItem('packId', pack.id);
                                localStorage.setItem(
                                  'packPrice',
                                  pack?.price_monthly,
                                );
                              }
                            }}
                            className="btn btn-primary d-block w-100"
                            type="button"
                          >
                            Souscrire
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FormModal
        body={
          <FormPayment
            pack={userProfile?.pack_type}
            type="pack"
            selected={pack}
            setModalPayment={setModalPayment}
            amount={
              userProfile?.pack_type === 'annual'
                ? formatAmount(pack.price_annual || 0)
                : formatAmount(pack.price_monthly || 0)
            }
          />
        }
        title="Paiement"
        edge="end"
        onHide={() => setModalPayment(false)}
        show={modalPayment}
        size="lg"
      />
    </>
  );
}
