import React, { useEffect } from 'react';

const Input = (props) => {
  const {
    touched,
    width,
    type,
    label,
    placeholder,
    errors,
    error,
    handleChange,
    value,
    name,
    disabled,
    handleSubmit,
    icon,
    counter,
    min,
    zIndex,
    notif1,
  } = props;
  const keyPressed = (event, submit) => {
    if (event.key === 'Enter') {
      submit();
    }
  };
  /* useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);*/
  return (
    <div className={`mb-3 ${width}`}>
      <div className="form-group">
        {label && <label htmlFor="basic-url">{label}</label>}
        <div className="input-group mb-1">
          <input
            style={{ zIndex }}
            onKeyPress={(e) => keyPressed(e, handleSubmit)}
            disabled={disabled}
            onChange={handleChange}
            value={value}
            name={name}
            className={`form-control ${
              (errors[name] && touched[name]) || error
                ? 'is-invalid state-invalid'
                : !errors[name] && touched[name] && value
                ? 'is-valid state-valid'
                : ''
            }`}
            min={min}
            type={type}
            placeholder={placeholder}
            maxLength={counter && 50}
          />
          {icon && <span className="input-group-text">{icon}</span>}
          {/* {notif && (

          )}{' '}*/}
          {/*{notif1 && (
            <span style={{ marginTop: 10, marginLeft: 14 }} className="">
              / Notification
            </span>
          )}*/}
        </div>

        {counter && (
          <span style={{ float: 'right' }}>{`(${
            value ? value.length : 0
          }/50)`}</span>
        )}
        {errors && errors[name] && touched[name] && (
          <>
            <small id="emailHelp" className="form-text text-danger">
              {errors[name]}
            </small>
          </>
        )}
      </div>
    </div>
  );
};

export default Input;
