import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function SkeletonCreateUpdatePds() {
  return (
    <div
      className="container  d-flex"
      style={{ maxWidth: '100%', marginTop: 30 }}
    >
      <div className="w-100 mx-auto">
        <div className="card">
          <div style={{ width: '100%' }} className="ms-4">
            <div className="form-group">
              <Skeleton
                animation="wave"
                height={20}
                width="20%"
                style={{ marginBottom: 6 }}
              />
            </div>
            <div className="form-group">
              <Skeleton
                animation="wave"
                height={20}
                width="20%"
                style={{ marginBottom: 6 }}
              />
            </div>
            <div className="form-group">
              <Skeleton
                animation="wave"
                height={20}
                width="10%"
                style={{ marginBottom: 6 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
