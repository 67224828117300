import React, { useEffect } from 'react';
import BgHomeHeader from '../../../assets/img/bg-home-header.png';

const Curation = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return (
    <>
      
      <section>
        <div className="container mb-4 mt-4">
          <p>
          <h1>La curation de contenu</h1>

          </p><br/>
          <u id="coment-ca-marche">Comment ça marche?</u>
          <p>
          <b>Aussi simple que 1,2,3.</b>
          <br/><br/>
          La curation consiste à sélectionner et partager des contenus pertinents sur un sujet donné. Dokatis permet aux médecins de jouer ce rôle de filtre au profit de la santé de tous.
          <br/><br/>
            1. Les contributeurs suggèrent autant de doks qu'ils souhaitent. Tout le monde peut être contributeur.
            <br/>2. Les experts sélectionnent les meilleurs, y ajoutent des commentaires et les épinglent sur leur mosaïque Dokatis.
            <br/>3. Dokatis propose en priorité les doks les plus recommandés dans ses résultats.
            <br/>
            <br/> Le dok, c'est le format Dokatis du lien hypertexte. Les experts peuvent le personnaliser facilement ou encore le classer dans une liste.

            <br />
            
          </p>

          <u id="ethique-editoriale">Ethique éditoriale</u>
          <p>
          <b>Pour un partage responsable.</b>
          <br /><br/>
          Aujourd'hui, tout le monde écrit, produit des contenus, fait de la vidéo. Dokatis est un un espace collaboratif ouvert. Chaque utilisateur est responsable de respecter le cadre légal et réglementaire propre à sa profession, ses communications et leur diffusion.
          <br /><br/>
          Les liens sont libres et peuvent être partagés. Dokatis encourage les bonnes pratiques en matière de propriété intellectuelle.
          <br />Auteur mentionné
          <br />Conditions d'utilisation respectées
          <br />Anonymat des données
          <br /><br/>
          L'éthique nous guide dans toutes nos actions. La modération se fait naturellement selon le principe de curation. C'est une condition essentielle pour un impact positif sur le bien-être collectif.

            <br />
           
          </p>
          <u id="besoin-daide">Besoin d'aide?</u>
          <p>
          <b>On est là pour vous.</b>
          <br /><br/>
          Consultez notre FAQ.<br />
          Vous y trouverez des réponses à la plupart des questions fréquemment posées.<br />
          <br /><br/>
          Toujours pas de réponse ?<br />
          Contactez-nous !<br />
          Nous sommes disponibles pour vous aider.

            <br />
           
          </p>
        </div>
      </section>
    </>
  );
};

export default Curation;
