import React, { useEffect, useState } from 'react';
import DataTable from '../../../components/DataTable';
import { LOADING_STARTED, LOADING_STOPED } from '../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import ModalComponent from '../../../components/Modal/Modal';
import toast from '../../../utils/toast';
import Skeleton from '@mui/material/Skeleton';
import { getAllPacksSurMesure, deletePackSurMesure } from '../../../actions/admin/pack_surmesure';
import { formatAmount } from '../../../utils/helpers';

export default function PacksSurMesureAdmin() {
  const store = useStore();
  const [modalUpdateStatus, setModalUpdateStatus] = useState(false);
  const [index, setIndex] = useState(null);
  const [idPack, setIdPack] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });

    getAllPacksSurMesure(store);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);

  const packsSurMesure = useSelector((state) => state?.admin?.packsSurMesure);

  const onSubmitDelete = async (id, indexPack) => {
    try {
      store.dispatch({ type: LOADING_STARTED, payload: {} });
      const result = await deletePackSurMesure(id, indexPack, store);
      if (result?.type === 'success') {
        setModalUpdateStatus(false);
        toast.showSuccess(store, result?.message);
      } else {
        toast.showError(store, result?.message);
      }
    } catch (error) {
      toast.showError(store, error?.response?.data?.message || 'Une erreur est survenue');
    } finally {
      store.dispatch({ type: LOADING_STOPED, payload: {} });
    }
  };

  const headers = [
    { title: 'Code Campagne', filed: 'campain_code', width: '20%' },
    { title: 'Date de début', filed: 'start_date', width: '20%' },
    { title: 'Date de fin', filed: 'end_date', width: '20%' },
    { title: 'Nombre de notifications', filed: 'number_of_notifications', width: '20%' },
    { title: 'Action', width: '20%' },
  ];

  return (
    <div className="page-content w-100">
      <div className="card shadow-sm border-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Link className="link-light btn btn-lg btn-primary" to="/admin/pack-sur-mesure/create">
              <i className="fas fa-plus-circle me-2"></i>Créer un pack sur mesure
            </Link>
          </div>
          <DataTable
            headers={headers}
            handleChangeSort={() => console.log()}
            data={getColumns(packsSurMesure, setModalUpdateStatus, setIndex, setIdPack, loading)}
          />
        </div>
      </div>
      <ModalComponent
        body="Voulez-vous vraiment supprimer ce Pack Sur Mesure ?"
        title="Supprimer Pack Sur Mesure"
        show={modalUpdateStatus}
        onHide={() => setModalUpdateStatus(false)}
        footer={
          <button
            onClick={() => onSubmitDelete(idPack, index)}
            className="btn btn-primary me-2"
            type="button"
          >
            Supprimer
          </button>
        }
        size="md"
      />
    </div>
  );
}

const getColumns = (data, setModalUpdateStatus, setIndex, setIdPack, isLoading) => {
  if (isLoading) {
    return (
      <tr>
        <td colSpan="5">
          <Skeleton animation="wave" height={50} />
        </td>
      </tr>
    );
  }

  if (!data || data.length === 0) {
    return (
      <tr>
        <td className="text-center" colSpan="5">
          Vous n'avez pas encore de packs sur mesure
        </td>
      </tr>
    );
  }

  return data.map((item, index) => (
    <tr key={item.id}>
      <td>{item.campain_code}</td>
      <td>{new Date(item.start_date).toLocaleDateString()}</td>
      <td>{new Date(item.end_date).toLocaleDateString()}</td>
      <td>{item.number_of_notifications}</td>
      <td className="text-center">
        <Link to={`/admin/pack-sur-mesure/details/${item.id}`} className="btn btn-primary btn-sm btn-action">
          <i className="far fa-eye"></i>
        </Link>
        <Link to={`/admin/pack-sur-mesure/update/${item.id}`} className="btn btn-primary btn-sm btn-action">
          <i className="fas fa-pen"></i>
        </Link>
        <button
          onClick={() => {
            setIndex(index);
            setIdPack(item.id);
            setModalUpdateStatus(true);
          }}
          className="btn btn-primary btn-sm btn-action"
        >
          <i className="fas fa-times"></i>
        </button>
      </td>
    </tr>
  ));
};