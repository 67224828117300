import { apiCall } from '../../utils/helpers';
import { GET_CURRENT_PACK } from '../../redux/reducers/contributor';

export async function getCurrentPack(store) {
  try {
    const url = `/api/contributor-packs/current/pack`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data?.type === 'success')
      store.dispatch({
        type: GET_CURRENT_PACK,
        payload: {
          data: data.data,
        },
      });
    return data;
  } catch (e) {}
}
