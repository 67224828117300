import React, { useState, useMemo } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Col, Row } from 'react-bootstrap';
import { apiCall, formatAmount } from '../../../utils/helpers';
import toast from '../../../utils/toast';
import { useSelector, useStore } from 'react-redux';
import { getAllCampagnes } from '../../../actions/admin/getAllCampagnes';
import { getMe } from '../../../actions/getMe';
import { useNavigate } from 'react-router-dom';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import Input from '../../../components/Inputs';
import * as yup from 'yup';
import { Formik } from 'formik';
import { deleteCampagne } from '../../../actions/admin/delete-campagne';

const useOptions = () => {
  const options = useMemo(() => ({
    style: {
      base: {
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'sans-serif',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }));

  return options;
};
export default function PaymentForm({
  type,
  amount,
  setModalPayment,
  selected,
  pack,
  initialValues,
  onSubmit,
  nameDok,
}) {
  const options = useOptions();
  let navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(selected?.price_monthly);
  const stripe = useStripe();
  const elements = useElements();
  const store = useStore();
  const params = useSelector((state) => state?.admin?.paramsCampagnes);
  const [checkoutError, setCheckoutError] = useState();

  const paymentValidationSchema = yup.object().shape({
    town: yup.string().required('Champ requis'),
    zip_code: yup.string().required('Champ requis'),
    address: yup.string().required('Champ requis'),
    last_name: yup.string().required('Champ requis'),
    first_name: yup.string().required('Champ requis'),
    phone: yup.string().required('Champ requis'),
    email: yup
      .string()
      .email("Adresse email n'est pas valide")
      .required('Champ requis'),
  });
  const userProfile = useSelector((state) => state.auth.userProfile);
  const handleSubmit = async (values) => {
    setIsLoading(true);
    /*e.preventDefault();
    console.log(e);
    */
    const billing_details = {
      name: `${values.first_name} ${values.last_name}`,
      email: values.email,
      address: {
        city: values.town,
        line1: values.address,
        state: values.address,
        postal_code: values.zip_code,
      },
    };
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details,
    });
    if (!error) {
      try {
        const { id } = paymentMethod;
        let data;
        if (type === 'campagne' || type === 'Campagne') {
          const result = await onSubmit(initialValues);
          console.log(result);
          if (result) {
            data = await apiCall({
              method: 'post',
              url: `/api/payment`,
              data: {
                id,
                amount: formatAmount(amount),
                type: 'campagne',
                pack_id: result?.id,
                ...values,
              },
            });
            try {
            } catch (e) {
              await deleteCampagne(result.id || null, 0, store, true);
            }
          }
        }
        if (type === 'pack') {
          data = await apiCall({
            method: 'post',
            url: `/api/payment`,
            data: {
              id,
              pack,
              amount: formatAmount(amount) || price,
              type: type || 'campagne',
              pack_id: selected.id,
              ...values,
            },
          });
        }

        console.log(data.data);
        if (data?.data?.type === 'success') {
          setSuccess(true);
          await getMe(store);
          await getAllCampagnes(store, {
            ...params,
            current_page: parseInt(localStorage.getItem('pageCampagne')) || 1,
            search: localStorage.getItem('searchCampagne') || '',
            status: localStorage.getItem('statusCampagne') || '',
          });
          navigate('/contributor/doks');
          setModalPayment(false);
          setIsLoading(false);
          toast.showSuccess(store, data?.data?.message);
          console.log('Successful payment');
        }
      } catch (error) {
        setIsLoading(false);
        console.log('error', error.response);
        toast.showError(
          store,
          error?.response?.data?.message ||
            'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        );
      }
    } else {
      setIsLoading(false);
      console.log('error.message', error.message);
      toast.showError(
        store,
        error?.message ||
          'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      );
    }
  };
  const handleCardDetailsChange = (event) => {
    event.error ? setCheckoutError(event.error.message) : setCheckoutError();
  };
  return (
    <>
      {!success ? (
        <Formik
          className="login100-form validate-form"
          initialValues={{
            email: userProfile?.user?.email || '',
            phone: userProfile?.phone || '',
            last_name: userProfile?.last_name || '',
            first_name: userProfile?.first_name || '',
            address: userProfile?.address || '',
            zip_code: userProfile?.zip_code || '',
            town: userProfile?.town || '',
          }}
          validationSchema={paymentValidationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            handleChange,
            values,
          }) => (
            <>
              {!amount && type === 'pack' && (
                <div className="form-group">
                  <div className="d-flex mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="formCheck-5"
                        name="filterBy"
                        value={selected?.price_monthly}
                        checked={price === selected?.price_monthly}
                        onChange={(e) => {
                          setPrice(selected?.price_monthly);
                        }}
                      />
                      <label className="form-check-label" htmlFor="formCheck-5">
                        Mensuel
                      </label>
                    </div>
                    <div className="form-check ms-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="formCheck-6"
                        name="filterBy"
                        value={selected?.price_annual}
                        checked={price === selected?.price_annual}
                        onChange={(e) => {
                          setPrice(selected?.price_annual);
                        }}
                      />
                      <label className="form-check-label" htmlFor="formCheck-6">
                        Annuel
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div className="form-group" style={{ display: 'flex' }}>
                <div className="col-left">
                  <Row>
                    <Col md={6}>
                      <Input
                        width="100%"
                        handleChange={handleChange}
                        value={values.first_name}
                        errors={errors}
                        touched={touched}
                        label="Nom"
                        type="text"
                        name="first_name"
                        placeholder="Nom"
                        handleSubmit={handleSubmit}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        width="100%"
                        handleChange={handleChange}
                        value={values.last_name}
                        errors={errors}
                        touched={touched}
                        label="Prénom"
                        type="text"
                        name="last_name"
                        placeholder="Prénom"
                        handleSubmit={handleSubmit}
                      />
                    </Col>
                  </Row>
                  <Input
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    value={values.email}
                    errors={errors}
                    touched={touched}
                    label="Votre adresse email"
                    type="email"
                    name="email"
                    placeholder="Votre adresse email"
                    width="100%"
                    icon={
                      <i
                        style={{ color: '#646464' }}
                        className="fas fa-envelope"
                      ></i>
                    }
                  />
                  <Row>
                    <Col md={6}>
                      <Input
                        width="100%"
                        handleChange={handleChange}
                        value={values.phone}
                        errors={errors}
                        touched={touched}
                        label="Téléphone"
                        type="text"
                        name="phone"
                        placeholder="Téléphone"
                        handleSubmit={handleSubmit}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        width="100%"
                        handleChange={handleChange}
                        value={values.address}
                        errors={errors}
                        touched={touched}
                        label="Adresse"
                        type="text"
                        name="address"
                        placeholder="Adresse"
                        handleSubmit={handleSubmit}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Input
                        width="100%"
                        handleChange={handleChange}
                        value={values.zip_code}
                        errors={errors}
                        touched={touched}
                        label="Code postal"
                        type="text"
                        name="zip_code"
                        placeholder="Code postal"
                        handleSubmit={handleSubmit}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        width="100%"
                        handleChange={handleChange}
                        value={values.town}
                        errors={errors}
                        touched={touched}
                        label="Ville"
                        type="text"
                        name="town"
                        placeholder="Ville"
                        handleSubmit={handleSubmit}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="col-right" style={{ width: '100%' }}>
                  <Row>
                    <div
                      style={{ alignItems: 'center', minHeight: 40 }}
                      className="row mb-3"
                    >
                      <div className="col-md-10">
                        <h3 className="card-title">Votre commande</h3>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="mb-3">
                        <Row style={{ minHeight: 5 }}>
                          <Col md={2}>
                            <div className="d-flex align-items-center mb-3">
                              <h5 className="mb-0">
                                {type === 'campagne' || type === 'Campagne'
                                  ? 'Campagne'
                                  : 'Pack'}
                                <br />
                              </h5>
                            </div>
                          </Col>
                          <Col md={10}>
                            <p style={{ textAlign: 'end' }} className="mb-0">
                              {selected?.title
                                ? selected.title
                                : `pour le Dok ${nameDok.toUpperCase()}`}
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <div className="mb-3">
                        <Row style={{ minHeight: 5 }}>
                          <Col md={4}>
                            <div className="d-flex align-items-center mb-3">
                              <h5 className="mb-0">
                                Total HT
                                <br />
                              </h5>
                            </div>
                          </Col>
                          <Col md={8}>
                            <p style={{ textAlign: 'end' }} className="mb-0">
                              {`${formatAmount(amount - (amount * 10) / 100)}€`}
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <div className="mb-3">
                        <Row style={{ minHeight: 5 }}>
                          <Col md={4}>
                            <div className="d-flex align-items-center mb-3">
                              <h5 className="mb-0">
                                TVA
                                <br />
                              </h5>
                            </div>
                          </Col>
                          <Col md={8}>
                            <p style={{ textAlign: 'end' }} className="mb-0">
                              {10}%
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <div className="mb-3">
                        <Row style={{ minHeight: 5 }}>
                          <Col md={4}>
                            <div className="d-flex align-items-center mb-3">
                              <h5 className="mb-0">
                                Total TTC
                                <br />
                              </h5>
                            </div>
                          </Col>
                          <Col md={8}>
                            <p style={{ textAlign: 'end' }} className="mb-0">
                              {`${formatAmount(amount)}€`}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Row>
                  <div className="row card-body" style={{ display: 'block' }}>
                    <label>
                      <span>Numéro de carte</span>{' '}
                      <a
                        target="_blank"
                        href="https://stripe.com/docs/testing?testing-method=card-numbers"
                      >
                        Exemple des numéros de cate
                      </a>
                      <CardNumberElement
                        options={options}
                        onChange={handleCardDetailsChange}
                      />{' '}
                    </label>
                    <label>
                      <span>Date d'expiration</span>
                      <CardExpiryElement
                        options={options}
                        onChange={handleCardDetailsChange}
                      />
                    </label>
                    <label>
                      <span>CVC</span>
                      <CardCvcElement
                        options={options}
                        onChange={handleCardDetailsChange}
                      />
                    </label>

                    {/*<CardElement
                    //className="form-control"
                    options={CARD_OPTIONS}
                    style={{
                      base: {
                        fontSize: '18px',
                        lineHeight: '1.6',
                        display: 'block',
                      },
                    }}
                  />{' '}*/}
                  </div>
                </div>
              </div>
              <Row style={{ justifyContent: 'center' }}>
                <button
                  onClick={handleSubmit}
                  type="button"
                  style={{
                    width: '20%',
                  }}
                  className={
                    isLoading
                      ? 'btn-loading btn btn-primary'
                      : ' btn btn-primary'
                  }
                >
                  Payer{' '}
                  {amount || price
                    ? `(${formatAmount(amount) || formatAmount(price)} € )`
                    : ''}
                </button>
              </Row>
            </>
          )}
        </Formik>
      ) : (
        <div>
          <h3></h3>
        </div>
      )}
    </>
  );
}
