import { apiCall } from '../utils/helpers';
import { GET_ALL_NOTIFICATIONS_USER } from '../redux/reducers/pds';

export async function getALlNotificationsUser(store, params) {
  try {
    let url = `/api/notification/user?limit=${params.limit || 5}&current_page=${
      params.current_page
    }`;
    if (params.is_seen && params.is_seen !== 'undefined') {
      url += `&is_seen=${params.is_seen}`;
    }

    const { data } = await apiCall({
      method: 'get',
      url,
    });

    if (data?.total > 0) {
      store.dispatch({
        type: GET_ALL_NOTIFICATIONS_USER,
        payload: {
          list: data,
          params,
        },
      });
    } else
      store.dispatch({
        type: GET_ALL_NOTIFICATIONS_USER,
        payload: {
          list: { data: [] },
          params,
        },
      });
    return data;
  } catch (error) {
    store.dispatch({
      type: GET_ALL_NOTIFICATIONS_USER,
      payload: {
        list: { data: [] },
        params,
      },
    });
  }
}
