import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getStatisticDok } from '../../../actions/admin/getStatisticDok';
import Skeleton from '@mui/material/Skeleton';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Col, Row } from 'react-bootstrap';

export default function StatisticDok() {
  const store = useStore();
  let navigate = useNavigate();
  let { id } = useParams();
  const [load, setLoad] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getStatisticDok(id, store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }, [load]);
  const statsDok = useSelector((state) => state?.admin?.statsDok);

  if (load)
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0">Statistiques de DOK</h5>
          </div>
          <div className="card-body">
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="page-content w-100">
      <div className="card admin-card  shadow-sm border-0">
        <div className="card-header">
          <h5 className="mb-0">Statistiques de DOK</h5>
        </div>
        <div className="card-body">
          {statsDok?.title && (
            <div className="mb-3">
              <Row style={{ minHeight: 5 }}>
                <Col md={2}>
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">
                      Titre du DOK
                      <br />
                    </h5>
                  </div>
                </Col>
                <Col md={10}>
                  <p className="mb-0">{statsDok?.title}</p>
                </Col>
              </Row>
            </div>
          )}

          {statsDok?.url && (
            <div className="mb-5">
              <Row style={{ minHeight: 5 }}>
                <Col md={2}>
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">
                      URL
                      <br />
                    </h5>
                  </div>
                </Col>
                <Col md={10}>
                  <p className="mb-0">
                    <Link to={statsDok?.url}>{statsDok?.url}</Link>
                  </p>
                </Col>
              </Row>
            </div>
          )}
          <h3 className="card-title">Statistiques</h3>
          <hr />
          <Row>
            <Col md={3}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <h2>{statsDok?.count_search || 0}</h2>
                <span>Resultats dans les recherches</span>
              </div>
            </Col>
            <Col md={3}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <h2>{statsDok?.count_open || 0}</h2>
                <span>Click sur le dok</span>
              </div>
            </Col>
            <Col md={3}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <h2>{statsDok?.count_move_to_selection || 0}</h2>
                <span>Ajout dans la sélection</span>
              </div>
            </Col>
            <Col md={3}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <h2>{statsDok?.count_move_to_mosaic || 0}</h2>
                <span>Ajout dans la mosaique</span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="card-footer d-flex justify-content-between align-items-center">
          <div>
            <Link to="/admin/doks">
              <i className="fas fa-reply-all me-2"></i>Retour à la liste
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
