import { createSlice } from '@reduxjs/toolkit';
import { swap } from 'react-grid-dnd';

export const diplomaSlice = createSlice({
  name: 'diploma',
  initialState: {
    list: [],
    experiences: [],
  },
  reducers: {
    getList: (state, action) => {
      const { list } = action.payload;
      let diplomasPds = [];
      let experiences = [];
      if (list.length > 0) {
        list.map((diploma) => {
          if (diploma?.type && diploma?.type.includes('Diplômes')) {
            diplomasPds.push(diploma);
          }
          if (diploma?.type && diploma?.type.includes('Expériences')) {
            experiences.push(diploma);
          }
        });
      }
      state.list = diplomasPds;
      state.experiences = experiences;
    },
    add: (state, action) => {
      const { item } = action.payload;
      if (item.type && item.type.includes('Diplômes')) {
        state.list.push(item);
      } else {
        state.experiences.push(item);
      }
    },
    remove: (state, action) => {
      const { removedIds } = action.payload;
      state.list.splice(removedIds, 1);
    },
    update: (state, action) => {
      const { data, index } = action.payload;
      if (data.type && data.type.includes('Diplômes')) {
        state.list[index] = {
          ...data,
        };
      } else {
        state.experiences[index] = {
          ...data,
        };
      }
    },
    updateOrderDiplomas: (state, action) => {
      const { sourceIndex, targetIndex } = action.payload;
      state.list = swap(state.list, sourceIndex, targetIndex);
    },
    updateOrderExp: (state, action) => {
      const { sourceIndex, targetIndex } = action.payload;
      state.experiences = swap(state.experiences, sourceIndex, targetIndex);
    },
  },
});

export const GET_LIST_DIPLOMA = 'diploma/getList';
export const ADD_DIPLOMA = 'diploma/add';
export const REMOVE_DIPLOMA = 'diploma/remove';
export const UPDATE_DIPLOMA = 'diploma/update';
export const UPDATE_ORDER_DIPLOMA = 'diploma/updateOrderDiplomas';
export const UPDATE_ORDER_EXP = 'diploma/updateOrderExp';

export default diplomaSlice.reducer;
