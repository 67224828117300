import toast from '../../utils/toast';
import { apiCall } from '../../utils/helpers';
import { REMOVE_CAMPAGNE } from '../../redux/reducers/admin';

export async function deleteCampagne(id, removedIds, store, type) {
  const { data } = await apiCall({
    method: 'delete',
    url: '/api/campagnes/destroy/' + id,
  });
  if (data?.type === 'success') {
    console.log(removedIds);
    if (!type)
      store.dispatch({
        type: REMOVE_CAMPAGNE,
        payload: {
          removedIds,
        },
      });

    return data;
  } else {
    const message = data?.message
      ? data?.message
      : 'error occured while deleting the card';
    toast.showError(store, message);
    return data;
  }
}
