import React from 'react';
import NbrDoksByMonth from './components/nbr-doks-by-month';
import { Col, Row } from 'react-bootstrap';
import PdsBySpeciality from './components/pds-by-speciality';
import GetKeywords from './components/getKeywords';
import NbrDokReference from './components/nbr-dok-reference';
import StatsOpenDok from './components/stats-open-dok';

export default function Statistic() {
  return (
    <div className="page-content w-100">
      <div className="card admin-card shadow-sm border-0">
        <div className="card-header">
          <h5 className="mb-0">Statistiques globales</h5>
        </div>
        <div className="card-body">
          <h3>Paramètres</h3>
          <hr />
          <section className="mb-2">
            <div className="container" style={{ marginLeft: 0 }}>
              {/*<Row style={{ width: '100%' }}>
                <Col>
                  <NbrDoksByMonth />
                  <div style={{ textAlign: 'center' }} className="">
                    Nombre total de DOKS
                  </div>
                </Col>
                <Col>
                  <PdsBySpeciality />
                  <div style={{ textAlign: 'center' }} className="">
                    Comptes activés par specialités
                  </div>
                </Col>
              </Row>*/}
              <br />
              <Row>
                <GetKeywords />
              </Row>
              <br />
              <br />
              <Row>
                <Col md={6}>
                  <div style={{ textAlign: 'center' }} className="">
                    Nombre de référencements par département
                  </div>
                  <NbrDokReference />
                </Col>
                <Col md={6}>
                  <div style={{ textAlign: 'center' }} className="">
                    TAUX D’OUVERTURE
                  </div>
                  <StatsOpenDok />
                </Col>
              </Row>
            </div>
          </section>
          <div className="d-flex justify-content-between mb-4"></div>
        </div>
      </div>
    </div>
  );
}
//npm install react-syntax-highlighter
