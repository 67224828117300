import toast from '../../../utils/toast';
import { apiCall } from '../../../utils/helpers';
import { REMOVE_STARTER_KIT } from '../../../redux/reducers/starter-kit';

export async function deleteStarterKit(id, removedIds, store, type) {
  const { data } = await apiCall({
    method: 'delete',
    url: '/api/starter-kit/destroy/' + id,
  });
  if (data?.type === 'success') {
    if (!type)
      store.dispatch({
        type: REMOVE_STARTER_KIT,
        payload: {
          removedIds,
        },
      });
    return data;
  } else {
    const message = data?.message
      ? data?.message
      : 'error occured while deleting the card';
    toast.showError(store, message);
    return data;
  }
}
