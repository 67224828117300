import { apiCall } from '../utils/helpers';
import { GET_LIST_LINE } from '../redux/reducers/mosaic';

export async function getLines(store, id) {
  try {
    const url = `/api/line?id=${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data?.lines.length > 0) {
      store.dispatch({
        type: GET_LIST_LINE,
        payload: {
          data: data,
        },
      });
    } else
      store.dispatch({
        type: GET_LIST_LINE,
        payload: {
          data: [],
        },
      });
    return data;
  } catch (e) {}
}
