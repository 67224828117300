import { apiCall } from '../../utils/helpers';
import { GET_SUM_DOKS } from '../../redux/reducers/contributor';

export async function getSumDoks(store) {
  try {
    const url = `/api/contributor-packs/sum/doks`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data?.type === 'success') {
      store.dispatch({
        type: GET_SUM_DOKS,
        payload: {
          data: data.data,
        },
      });
    } else
      store.dispatch({
        type: GET_SUM_DOKS,
        payload: {
          data: null,
        },
      });
    return data;
  } catch (error) {
    store.dispatch({
      type: GET_SUM_DOKS,
      payload: {
        data: null,
      },
    });
  }
}
