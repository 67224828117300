import { apiCall } from '../../utils/helpers';
import { GET_CONTRIBUTOR } from '../../redux/reducers/admin';

export async function getContributor(id, store) {
  const url = `/api/contributor/${id}`;
  const { data } = await apiCall({
    method: 'get',
    url,
  });
  if (data?.type === 'success')
    store.dispatch({
      type: GET_CONTRIBUTOR,
      payload: {
        data: data.data,
      },
    });
  return data;
}
