import toast from '../../utils/toast';
import { apiCall } from '../../utils/helpers';
import { GET_CAMPAGNE } from '../../redux/reducers/admin';

export async function createUpdateCampagne(id, values, store = {}) {

  console.log(values);
  const { data } = await apiCall({
    method: id ? 'patch' : 'post',
    url: id ? `/api/campagnes/update/${id}` : `/api/campagnes/create`,
    data: values,
  });
  if (data?.type === 'success') {
    if (!values.dok_id) {
      store.dispatch({
        type: GET_CAMPAGNE,
        payload: {
          data: data.data,
        },
      });
    }
  } else {
    const message = data?.message ? data?.message :  'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.';
    toast.showError(store, message);
  }
  return data;
}
