import { apiCall } from '../../../utils/helpers';
import { GET_ALL_PACKS } from '../../../redux/reducers/admin';
import axios from 'axios';

export async function getAllPacks(store) {
  try {
    let url = `${process.env.REACT_APP_PUBLIC_URL}/api/packs`;

    const { data } = await axios({
      method: 'get',
      url,
    });
    if (data?.type === 'success') {
      console.log(data);
      store.dispatch({
        type: GET_ALL_PACKS,
        payload: {
          list: data,
        },
      });
    } else
      store.dispatch({
        type: GET_ALL_PACKS,
        payload: {
          list: [],
        },
      });
    return data;
  } catch (error) {
    store.dispatch({
      type: GET_ALL_PACKS,
      payload: {
        list: [],
      },
    });
  }
}
