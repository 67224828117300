import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function SkeletonDok() {
  return (
    <Skeleton
      animation="wave"
      height={250}
      width="100%"
      style={{ marginBottom: 6 }}
    />
  );
}
