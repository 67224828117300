import React, { useEffect } from 'react';

const TextArea = (props) => {
  const {
    touched,
    width,
    rows,
    placeholder,
    errors,
    error,
    handleChange,
    value,
    name,
    disabled,
    handleSubmit,
  } = props;
  /*const keyPressed = (event, submit) => {
    if (event.key === 'Enter') {
      submit();
    }
  };*/
  /*useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);*/
  return (
    <div className={`mb-3 ${width}`}>
      <textarea
        //onKeyPress={(e) => keyPressed(e, handleSubmit)}
        disabled={disabled}
        value={value}
        name={name}
        className={`form-control ${
          (errors[name] && touched[name]) || error
            ? 'is-invalid state-invalid'
            : !errors[name] && touched[name] && value
            ? 'is-valid state-valid'
            : ''
        }`}
        placeholder={placeholder}
        rows={rows}
        cols={60}
        style={{ width: width }}
        onChange={handleChange}
      ></textarea>
      {errors && errors[name] && touched[name] && (
        <div
          className="bg-danger-transparent-2 text-danger"
          role="alert"
          style={{ width: width ? `${width}%` : '80%', fontSize: '11px' }}
        >
          {errors[name]}
        </div>
      )}
    </div>
  );
};

export default TextArea;
