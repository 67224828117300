import { createSlice } from '@reduxjs/toolkit';

export const dokSlice = createSlice({
  name: 'dok',
  initialState: {
    listAnnonceur: [],
    doks: [],
    countDokMySelection: 0,
  },
  reducers: {
    getCountDocsMySelection: (state, action) => {
      const { count } = action.payload;
      state.countDokMySelection = count;
    },
    getListAnnonceur: (state, action) => {
      const { list } = action.payload;
      state.listAnnonceur = list;
    },
    getDoks: (state, action) => {
      const { list } = action.payload;
      state.doks = list;
    },
    updateDok: (state, action) => {
      const { data, index } = action.payload;
      state.doks[index] = { ...data };
    },
    removeDok: (state, action) => {
      const { index } = action.payload;
      state.doks.splice(index, 1);
    },
  },
});

export const GET_LIST_DOK_ANNONCEUR = 'dok/getListAnnonceur';
export const GET_LIST_DOKS = 'dok/getDoks';
export const REMOVE_SELECTION_DOK = 'dok/removeDok';
export const COUNT_DOK_MY_SELECTION = 'dok/getCountDocsMySelection';
export const UPDATE_SELECTION_DOK = 'dok/updateDok';

export default dokSlice.reducer;
