import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Col, Row } from 'react-bootstrap';
import { getCampagne } from '../../../actions/admin/getCampagne';
import moment from 'moment';
import toast from '../../../utils/toast';
import { createUpdateCampagne } from '../../../actions/admin/create-update-campagne';

export default function DetailsCampagne() {
  const store = useStore();
  let navigate = useNavigate();
  let { id } = useParams();
  const [load, setLoad] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getCampagne(id, store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }, [load]);

  const [isLoading, setIsLoading] = useState(false);
  const campagne = useSelector((state) => state?.admin?.campagne);
  const onSubmit = async (values) => {
    console.log(values);
    setIsLoading(true);
    try {
      if (moment(values.start_date).isAfter(moment(values.end_date))) {
        toast.showError(
          store,
          'Date de début doit etre inferireur au date de fin',
        );
      }
      const result = await createUpdateCampagne(id, values, store);
      if (result.type === 'success') {
        //navigate('/admin/campagnes');
        setIsLoading(false);
        toast.showSuccess(store, result?.message);
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setIsLoading(false);
      toast.showError(
        store,
        error?.response?.data?.message ||
          'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      );
    }
  };
  if (load)
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0">Consultation de la campagne</h5>
          </div>
          <div className="card-body">
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
            <div className="mb-5">
              <Skeleton animation="wave" width="70%" height={10} />
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="page-content w-100">
      <div className="card admin-card  shadow-sm border-0">
        <div className="card-header">
          <div style={{ alignItems: 'center', minHeight: 40 }} className="row">
            <div className="col-md-10">
              <h5 className="mb-0">Consultation de la campagne</h5>
            </div>
            <div className="col-md-2 float-right">
              {campagne.status ? (
                <span style={{ float: 'right' }}>
                  <svg height="14" width="14">
                    <circle cx="6" cy="6" r="6" fill="#00949E"></circle>
                  </svg>{' '}
                  Actif
                </span>
              ) : (
                <span style={{ float: 'right' }}>
                  <svg height="14" width="14">
                    <circle cx="6" cy="6" r="6" fill="#E61610"></circle>
                  </svg>{' '}
                  inActif
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="card-body">
          <h3 className="card-title">Paramétres</h3>
          <hr />
          {campagne?.document && (
            <div className="mb-3">
              <Row style={{ minHeight: 5 }}>
                <Col md={2}>
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">
                      DOK
                      <br />
                    </h5>
                  </div>
                </Col>
                <Col md={10}>
                  <p className="mb-0">{campagne?.document?.title}</p>
                </Col>
              </Row>
            </div>
          )}

          {campagne?.speciality_data_sources && (
            <div className="mb-3">
              <Row style={{ minHeight: 5 }}>
                <Col md={2}>
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">
                      Spécialitie(s)
                      <br />
                    </h5>
                  </div>
                </Col>
                <Col md={10}>
                  <p className="mb-0">
                    {campagne?.speciality_data_sources.map((speciality) =>
                      campagne?.speciality_data_sources.length > 1 ? (
                        <span key={speciality.id}>{speciality.label}, </span>
                      ) : (
                        <span key={speciality.id}>{speciality.label}</span>
                      ),
                    ) || '-'}
                  </p>
                </Col>
              </Row>
            </div>
          )}
          {campagne?.starter_kit ||
          campagne?.notification ||
          campagne?.sidebar ||
          campagne?.in_search ? (
            <div className="mb-5">
              <Row style={{ minHeight: 5 }}>
                <Col md={2}>
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">
                      Emplacement(s)
                      <br />
                    </h5>
                  </div>
                </Col>
                <Col md={10}>
                  <p className="mb-0">
                    {campagne.starter_kit && (
                      <span>
                        Starter Kif <br />
                      </span>
                    )}
                    {campagne.notification && (
                      <span>
                        Notifications <br />
                      </span>
                    )}
                    {campagne.sidebar && (
                      <span>
                        Suggestions en barre latérale <br />
                      </span>
                    )}
                    {campagne.in_search && (
                      <span>
                        Suggestions sous le mouteur de recherche <br />
                      </span>
                    )}
                  </p>
                </Col>
              </Row>
            </div>
          ) : (
            ''
          )}
          <h3 className="card-title">Statistiques</h3>
          <hr />
          <Row>
            <Col md={2}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <h2>{campagne?.nbr_notification || 0}</h2>
                <span>Vues dans la notifications</span>
              </div>
            </Col>
            <Col md={2}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <h2>{campagne?.nbr_listing_home || 0}</h2>
                <span>Impressions moteur de recherche</span>
              </div>
            </Col>
            <Col md={2}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <h2>{campagne?.nbr_listing_sidebar || 0}</h2>
                <span>Impressions barre latérale</span>
              </div>
            </Col>
            <Col md={2}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <h2>{campagne?.nbr_add_mosaic || 0}</h2>
                <span>Ajouts à la mosaique</span>
              </div>
            </Col>
            <Col md={2}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <h2>{campagne?.nbr_add_my_selection || 0}</h2>
                <span>Ajouts à la sélection</span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="card-footer d-flex justify-content-between align-items-center">
          <div>
            <Link to="/admin/campagnes">
              <i className="fas fa-reply-all me-2"></i>Retour à la liste
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
