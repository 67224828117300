import { apiCall } from '../../utils/helpers';
import { GET_CURRENT_PACK_SURMESURE } from '../../redux/reducers/contributor';

export async function getCurrentPackSurmesure(store) {
  try {
    const url = `/api/contributor-packs/current/packSurmesure`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data?.type === 'success')
      store.dispatch({
        type: GET_CURRENT_PACK_SURMESURE,
        payload: {
          data: data.data,
        },
      });
    return data;
  } catch (e) {}
}
