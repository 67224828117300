import { createSlice } from '@reduxjs/toolkit';

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    dashboard: {},
    config: {},
    listPDSDataSource: [],
    paramsPDSDataSource: {
      current_page: 1,
      limit: 10,
      search: '',
    },
    listExpertise: [],
    paramsExpertise: {
      current_page: 1,
      limit: 10,
      search: '',
    },
    listSpecialities: [],
    paramsSpecialities: {
      current_page: 1,
      limit: 10,
      search: '',
    },
    listPdsExcel: [],
    paramsPdsExcel: {
      current_page: 1,
      limit: 10,
      search: '',
    },
    listSpecialitiesDoctolib: [],
    paramsSpecialitiesDoctolib: {
      current_page: 1,
      limit: 10,
      search: '',
      order: '',
      field: '',
    },
    interets: [],
    expertises: [],
    listPDS: [{ data: [] }],
    paramsPDS: {
      current_page: 1,
      limit: 10,
      search: '',
      speciality: '',
      order: '',
      field: '',
    },
    listDoks: [],
    paramsDoks: {
      current_page: 1,
      limit: 10,
      search: '',
      order: '',
      field: '',
    },
    listAdvantageCode: [],
    paramsAdvantageCode: {
      current_page: 1,
      limit: 10,
      search: '',
      order: '',
      field: '',
    },
    specialityDoctolib: null,
    listNotifications: [],
    paramsNotifications: {
      current_page: 1,
      limit: 10,
      search: '',
      order: '',
      field: '',
    },
    statsDok: null,
    notification: null,
    code: null,
    listCampagnes: [],
    paramsCampagnes: {
      current_page: 1,
      limit: 10,
      search: '',
      order: '',
      field: '',
      status: '',
    },
    campagne: null,
    listDoksByUser: [],
    paramsDoksByUser: {
      current_page: 1,
      limit: 10,
    },
    campagnesSideBar: [],
    listContributors: [],
    paramsContributors: {
      current_page: 1,
      limit: 10,
      search: '',
      order: '',
      field: '',
    },
    contributor: null,
    pack: null,
    setting: null,
    packs: [],
    listBills: [],
    paramsBillms: {
      current_page: 1,
      limit: 10,
      search: '',
      order: '',
      field: '',
    },
    bill: null,
    packsSurMesure: [],
    packSurMesure: null,
    paramsPacksSurMesure: {
      current_page: 1,
      limit: 10,
      search: '',
      order: '',
      field: '',
    },
  },
  reducers: {
    /**Bills*/
    getBills: (state, action) => {
      const { list, params } = action.payload;
      state.listBills = list;
      state.paramsBillms = params;
    },
    getBill: (state, action) => {
      const { bill } = action.payload;
      state.bill = bill;
    },
    /**Setting*/
    settings: (state, action) => {
      const { setting } = action.payload;
      state.setting = setting;
    },
    /**Packs*/
    getPacks: (state, action) => {
      const { list } = action.payload;
      state.packs = list.data;
    },
    getPack: (state, action) => {
      const { data } = action.payload;
      state.pack = data;
    },
    removePack: (state, action) => {
      const { removedIds } = action.payload;
      state.packs.splice(removedIds, 1);
    },
    /**Contributor*/
    getContributors: (state, action) => {
      const { list, params } = action.payload;
      state.listContributors = list;
      state.paramsContributors = params;
    },
    getContributor: (state, action) => {
      const { data } = action.payload;
      state.contributor = data;
    },
    removeContributor: (state, action) => {
      const { removedIds } = action.payload;
      state.listContributors.data.splice(removedIds, 1);
    },
    /**Campagnes*/
    getCampagnes: (state, action) => {
      const { list, params } = action.payload;
      state.listCampagnes = list;
      state.paramsCampagnes = params;
    },
    getCampagnesSideBar: (state, action) => {
      const { list } = action.payload;
      state.campagnesSideBar = list;
    },
    getCampagne: (state, action) => {
      const { data } = action.payload;
      state.campagne = data;
    },
    removeCampagne: (state, action) => {
      const { removedIds } = action.payload;
      state.listCampagnes.data.splice(removedIds, 1);
    },
    /**Notifications*/
    getNotifications: (state, action) => {
      const { list, params } = action.payload;
      state.listNotifications = list;
      state.paramsNotifications = params;
    },
    getNotification: (state, action) => {
      const { data } = action.payload;
      state.notification = data;
    },
    /**AdvantageCode*/
    getAdvantageCode: (state, action) => {
      const { list, params } = action.payload;
      state.listAdvantageCode = list;
      state.paramsAdvantageCode = params;
    },
    getAdvantageCodeById: (state, action) => {
      const { data } = action.payload;
      state.code = data;
    },
    /**specialityDoctolib*/
    getSpecialityDoctolib: (state, action) => {
      const { data } = action.payload;
      state.specialityDoctolib = data;
    },
    removeSpeciality: (state, action) => {
      const { removedIds } = action.payload;
      console.log(removedIds);
      state.specialityDoctolib.data.splice(removedIds, 1);
    },
    /**Doks*/
    getDoks: (state, action) => {
      const { list, params } = action.payload;
      state.listDoks = list;
      state.paramsDoks = params;
    },
    getDoksByUser: (state, action) => {
      const { list, params } = action.payload;
      state.listDoksByUser = list;
      state.paramsDoksByUser = params;
    },
    getStatsDok: (state, action) => {
      const { data } = action.payload;
      state.statsDok = data;
    },
    /**PDS*/
    getPds: (state, action) => {
      const { list, params } = action.payload;
      state.listPDS = list;
      state.paramsPDS = params;
    },
    removePds: (state, action) => {
      const { removedIds } = action.payload;
      state.listPDS.data.splice(removedIds, 1);
    },
    /**Interets*/
    getInterets: (state, action) => {
      const { interets } = action.payload;
      state.interets = interets;
    },
    /**PdsDataSourceExcel*/
    getPdsDataSourceExcel: (state, action) => {
      const { list, params } = action.payload;
      state.listPdsExcel = list;
      state.paramsPdsExcel = params;
    },
    /**PdsDataSource*/
    getPdsDataSource: (state, action) => {
      const { list, params } = action.payload;
      state.listPDSDataSource = list;
      state.paramsPDSDataSource = params;
    },
    /**Expertise*/
    getExpertise: (state, action) => {
      const { list, params } = action.payload;
      state.listExpertise = list;
      state.paramsExpertise = params;
    },
    /**Specialities*/
    getSpecialities: (state, action) => {
      const { list, params } = action.payload;
      state.listSpecialities = list;
      state.paramsSpecialities = params;
    },
    /**SpecialitiesDoctolib*/
    getSpecialitiesDoctolib: (state, action) => {
      const { list, params } = action.payload;
      state.listSpecialitiesDoctolib = list;
      state.paramsSpecialitiesDoctolib = params;
    },
    changeParams: (dataSet, action) => {
      const { params, type } = action.payload;
      dataSet[type] = params;
    },
    /**Pack Sur Mesure*/
    getPacksSurMesure: (state, action) => {
      const { list } = action.payload;
      state.packsSurMesure = list.data;
    },
    getPackSurMesure: (state, action) => {
      const { data } = action.payload;
      state.packSurMesure = data;
    },
    removePackSurMesure: (state, action) => {
      const { removedIds } = action.payload;
      state.packsSurMesure.splice(removedIds, 1);
    },
  },
});
export const GET_ALL_BILLS = 'admin/getBills';
export const GET_BILL = 'admin/getBill';
export const GET_ALL_PACKS = 'admin/getPacks';
export const GET_PACK = 'admin/getPack';
export const REMOVE_PACK = 'admin/removePack';
export const REMOVE_CONTRIBUTOR = 'admin/removeContributor';
export const GET_CONTRIBUTOR = 'admin/getContributor';
export const GET_CONTRIBUTORS_LIST = 'admin/getContributors';
export const GET_CAMPAGNES_SIDE_BAR = 'admin/getCampagnesSideBar';
export const GET_CAMPAGNE = 'admin/getCampagne';
export const GET_ALL_CAMPAGNES = 'admin/getCampagnes';
export const REMOVE_CAMPAGNE = 'admin/removeCampagne';
export const GET_ALL_NOTIFICATIONS = 'admin/getNotifications';
export const GET_NOTIFICATION = 'admin/getNotification';
export const GET_SPECIALITY_BY_ID = 'admin/getSpecialityDoctolib';
export const REMOVE_SPECIALITY = 'admin/removeSpeciality';
export const CHANGE_PARAMS = 'admin/changeParams';
export const GET_ALL_PDS = 'admin/getPds';
export const REMOVE_PDS = 'admin/removePds';
export const GET_ALL_DOKS = 'admin/getDoks';
export const GET_ALL_DOKS_BY_USER = 'admin/getDoksByUser';
export const GET_STATS_DOK = 'admin/getStatsDok';
export const GET_ALL_ADVANTAGE_CODE = 'admin/getAdvantageCode';
export const GET_ADVANTAGE_CODE = 'admin/getAdvantageCodeById';
export const GET_PDS_DATA_SOURCES = 'admin/getPdsDataSource';
export const GET_PDS_DATA_SOURCES_EXCEL = 'admin/getPdsDataSourceExcel';
export const GET_EXPERTISES = 'admin/getExpertise';
export const GET_SPECIALITIES = 'admin/getSpecialities';
export const GET_SPECIALITIES_DOCTOLIB = 'admin/getSpecialitiesDoctolib';
export const GET_INTERETS = 'admin/getInterets';
export const GET_SETTING = 'admin/settings';
export const GET_ALL_PACKS_SURMESURE = 'admin/getPacksSurMesure';
export const GET_PACK_SURMESURE = 'admin/getPackSurMesure';
export const REMOVE_PACK_SURMESURE = 'admin/removePackSurMesure';


export default adminSlice.reducer;
