import { apiCall } from '../../../utils/helpers';
import { GET_PACK } from '../../../redux/reducers/admin';

export async function getPack(id, store) {
  const url = `/api/packs/${id}`;
  const { data } = await apiCall({
    method: 'get',
    url,
  });
  if (data?.type === 'success')
    store.dispatch({
      type: GET_PACK,
      payload: {
        data: data.data,
      },
    });
  return data;
}
