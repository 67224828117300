import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Input from '../../../components/Inputs';
import { Formik } from 'formik';
import { useSelector, useStore } from 'react-redux';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import toast from '../../../utils/toast';
import * as yup from 'yup';
import { getSpecialitiesDoctolib } from '../../../actions/admin/getSpecialitiesDoctolib';
import SkeletonCreateUpdatePds from './Components/skeletonCreateUpdatePds';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { getContributor } from '../../../actions/admin/getContributor';
import { createUpdateContributor } from '../../../actions/admin/create-update-contributor';

const CreateUpdateContributor = () => {
  let navigate = useNavigate();
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const paramsExpertise = useSelector((state) => state?.admin?.paramsExpertise);
  let { id } = useParams();
  useEffect(() => {
    getSpecialitiesDoctolib(store, { ...paramsExpertise, limit: 180 });
    return () => {};
  }, [store]);
  const [loading, setLoading] = useState(true);
  const contributor = useSelector((state) => state?.admin?.contributor);
  useEffect(() => {
    if (id) {
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      getContributor(id, store);
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => {};
  }, [store, loading]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const val = {
        town: values?.town,
        email: values?.email,
        address: values?.address,
        /*first_name: values?.first_name,
        last_name: values?.last_name,*/
        zip_code: values?.zip_code,
        phone: values?.phone,
        society: values?.society,
      };
      const result = await createUpdateContributor(id, val, store);
      if (result.type === 'success') {
        navigate('/admin/contributor');
        setIsLoading(false);
        toast.showSuccess(store, result?.message);
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setIsLoading(false);
      toast.showError(
        store,
        error?.response?.data?.message ||
          'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      );
    }
  };

  const createUpdateContributorValidationSchema = yup.object().shape({
    /*speciality_data_source_id: yup.string().required('Champ requis'),
    first_name: yup.string().required('Champ requis'),
    last_name: yup.string().required('Champ requis'),*/
    zip_code: yup
      .number()
      .typeError('Ne doit être que des chiffres')
      .required('Champ requis'),
    town: yup.string().required('Champ requis'),
    address: yup.string().required('Champ requis'),
    society: yup.string().required('Champ requis'),
    phone: yup.string().required('Champ requis'),
    email: yup
      .string()
      .email("L'e-mail n'est pas valide")
      .required("L'e-mail est requis"),
  });

  if (loading)
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <SkeletonCreateUpdatePds />
        </div>
      </div>
    );
  else
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0">
              {id ? 'Modifier un contributeur' : 'Créer un contributeur'}
            </h5>
          </div>
          <Formik
            className="login100-form validate-form"
            initialValues={{
              email: contributor?.user?.email || '',
              phone: contributor?.phone || '',
              society: contributor?.society || '',
              address: contributor?.address || '',
              town: contributor?.town || '',
              zip_code: contributor?.zip_code || '',
            }}
            validationSchema={createUpdateContributorValidationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              handleChange,
              values,
            }) => (
              <div>
                <div className="card-body">
                  <Input
                    width="col-lg-6"
                    handleChange={handleChange}
                    value={values.society}
                    errors={errors}
                    touched={touched}
                    label="Société"
                    type="text"
                    name="society"
                    placeholder="Société"
                    handleSubmit={handleSubmit}
                  />
                  <Input
                    width="col-lg-6"
                    disabled={contributor?.user?.email}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    value={values.email}
                    errors={errors}
                    touched={touched}
                    label="Adresse e-mail"
                    type="email"
                    name="email"
                    placeholder="Adresse e-mail"
                    icon={
                      <i
                        style={{ color: '#646464' }}
                        className="fas fa-envelope"
                      ></i>
                    }
                  />
                  {/*<Input
                      width="60%"
                      handleChange={handleChange}
                      value={values.first_name}
                      errors={errors}
                      touched={touched}
                      label="Nom"
                      type="text"
                      name="first_name"
                      placeholder="Nom"
                      handleSubmit={handleSubmit}
                    />
                    <Input
                      width="60%"
                      handleChange={handleChange}
                      value={values.last_name}
                      errors={errors}
                      touched={touched}
                      label="Prénom"
                      type="text"
                      name="last_name"
                      placeholder="Prénom"
                      handleSubmit={handleSubmit}
                    />*/}
                  <Input
                    width="col-lg-6"
                    handleChange={handleChange}
                    value={values.phone}
                    errors={errors}
                    touched={touched}
                    label="Téléphone"
                    type="text"
                    name="phone"
                    placeholder="Téléphone"
                    handleSubmit={handleSubmit}
                  />
                  <Input
                    width="col-lg-6"
                    handleChange={handleChange}
                    value={values.address}
                    errors={errors}
                    touched={touched}
                    label="Adresse"
                    type="text"
                    name="address"
                    handleSubmit={handleSubmit}
                    placeholder="Adresse"
                  />
                  <Input
                    width="col-lg-6"
                    handleChange={handleChange}
                    value={values.zip_code}
                    errors={errors}
                    touched={touched}
                    label="Code postal"
                    type="text"
                    name="zip_code"
                    handleSubmit={handleSubmit}
                    placeholder="Code postal (ex : 06 ou 06380)"
                  />
                  <Input
                    width="col-lg-6"
                    handleChange={handleChange}
                    value={values.town}
                    errors={errors}
                    touched={touched}
                    label="Ville"
                    type="text"
                    name="town"
                    handleSubmit={handleSubmit}
                    placeholder="Ville"
                  />
                </div>
                <div className="card-footer d-flex justify-content-between align-items-center">
                  <div>
                    <Link to="/admin/contributor">
                      <i className="fas fa-reply-all me-2"></i>Retour à la liste
                    </Link>
                  </div>
                  <div>
                    <button
                      onClick={() => navigate('/admin/contributor')}
                      className="btn btn-danger"
                      type="button"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={handleSubmit}
                      className={
                        isLoading
                          ? 'link-light btn btn-primary ms-3'
                          : ' login100-form-btn link-light btn btn-primary ms-3'
                      }
                      type="button"
                    >
                      {id ? 'Modifer' : 'Ajouter'}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    );
};

export default CreateUpdateContributor;
