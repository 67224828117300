import { createSlice } from '@reduxjs/toolkit';

export const priceSlice = createSlice({
  name: 'price',
  initialState: {
    list: [],
  },
  reducers: {
    getList: (state, action) => {
      const { list } = action.payload;
      state.list = list;
    },
    add: (state, action) => {
      const { item } = action.payload;
      state.list.unshift(item);
    },
    remove: (state, action) => {
      const { removedIds } = action.payload;
      state.list.splice(removedIds, 1);
    },
    update: (state, action) => {
      const { data, index } = action.payload;
      state.list[index] = {
        ...data,
      };
    },
  },
});

export const GET_LIST_PRICE = 'price/getList';
export const ADD_Price = 'price/add';
export const REMOVE_Price = 'price/remove';
export const UPDATE_Price = 'price/update';

export default priceSlice.reducer;
