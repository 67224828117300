import toast from '../../../utils/toast';
import { apiCall } from '../../../utils/helpers';
import { UPDATE_STARTER_KIT } from '../../../redux/reducers/starter-kit';

export async function createUpdate(id, values, store = {}) {
  console.log(values);
  const { data } = await apiCall({
    method: id ? 'patch' : 'post',
    url: id ? `/api/starter-kit/update/${id}` : `/api/starter-kit/create`,
    data: values,
  });
  if (data?.type === 'success') {
    if (id) {
      store.dispatch({
        type: UPDATE_STARTER_KIT,
        payload: {
          data: data.data,
        },
      });
    }
  } else {
    const message = data?.message
      ? data?.message
      : 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.';
    toast.showError(store, message);
  }
  return data;
}
