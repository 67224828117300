import React, { useEffect, useState } from 'react';
import Dok from './Components/dok';
import Empty from './Components/empty';
import { useSelector, useStore } from 'react-redux';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import { isMobile } from 'react-device-detect';
import SkeletonDok from '../../pds/Components/dokSkeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { getAllDoks } from '../../../actions/admin/getAllDoks';
import { getSpecialitiesDoctolib } from '../../../actions/admin/getSpecialitiesDoctolib';
import SelectField from '../../../components/Select/Select';
import { getStarterKitById } from '../../../actions/admin/starter-kit/getStarterKitById';
import { Formik } from 'formik';
import * as yup from 'yup';
import FormModal from '../../../components/Modal/FormModal';
import { FormDokAdmin } from './Components/formDok';
import toast from '../../../utils/toast';
import { LOADING_STOPED } from '../../../redux/reducers/loader';
import { createUpdate } from '../../../actions/admin/starter-kit/create-update';
import { UPDATE_STARTER_KIT } from '../../../redux/reducers/starter-kit';
import { ADD_STARTER_KIT } from '../../../redux/reducers/starter-kit';
const initialComumns = [
  {
    id: 'e0f294fb-e063-4710-ab27-10ebe8e6c78f',
    type: 'empty',
    order: 1,
    list: null,
    document: null,
  },
  {
    id: '194180b5-c813-4486-9b16-a852a521e835',
    type: 'empty',
    order: 2,
    list: null,
    document: null,
  },
  {
    id: '6442ed4d-b0ac-4e29-8de8-dfc694101b99',
    type: 'empty',
    order: 3,
    list: null,
    document: null,
  },
  {
    id: '9e784c1e-7c2d-4ab4-803c-4fa9c6c67df5',
    type: 'empty',
    order: 4,
    list: null,
    document: null,
  },
  {
    id: '33dd70a3-ab1b-4b96-b10e-5716782c0041',
    type: 'empty',
    order: 5,
    list: null,
    document: null,
  },
  {
    id: 'be286b68-c257-428d-8790-64b0d14ace5f',
    type: 'empty',
    order: 6,
    list: null,
    document: null,
  },
  {
    id: '60ce1dde-cf4a-45ec-a4ed-4ba5fe50cc68',
    type: 'empty',
    order: 7,
    list: null,
    document: null,
  },
  {
    id: 'c43d791a-8b66-45b5-9f0b-312faefc987f',
    type: 'empty',
    order: 8,
    list: null,
    document: null,
  },
  {
    id: '16124b97-1bfb-4849-ba7d-3667b8ee6445',
    type: 'empty',
    order: 9,
    list: null,
    document: null,
  },
  {
    id: 'd6c55fe6-de26-439d-af93-84f356f0365d',
    type: 'empty',
    order: 10,
    list: null,
  },
  {
    id: 'd0faa970-0b11-4f72-a781-653d731a5087',
    type: 'empty',
    order: 11,
    list: null,
    document: null,
  },
  {
    id: '303ab559-dc1f-4b2e-963e-d79ae9f5b94b',
    type: 'empty',
    order: 12,
    list: null,
    document: null,
  },
  {
    id: '302dee43-e0fd-4636-9681-c10732f77d1a',
    type: 'empty',
    order: 13,
    list: null,
    document: null,
  },
  {
    id: 'a2a60f4e-93d3-4c11-81d3-5e7f097c2c43',
    type: 'empty',
    order: 14,
    list: null,
    document: null,
  },
  {
    id: '14136f7e-f1c6-4dc2-9817-363a8760fd11',
    type: 'empty',
    order: 15,
    list: null,
    document: null,
  },
  {
    id: '92cbe0de-74ec-4d1e-b04b-c1979ce777d0',
    type: 'empty',
    order: 16,
    list: null,
    document: null,
  },
];
const MosaicStarterKit = () => {
  const store = useStore();
  let { id } = useParams();
  let navigate = useNavigate();
  const [columnId, setColumnId] = useState('');
  const [modalAddList, setModalAddList] = useState(false);
  const [dataList, setDataList] = useState(null);
  const [indexList, setIndexList] = useState(null);
  const [indexDok, setIndexDok] = useState(null);
  const [modalAddDok, setModalAddDok] = useState(false);
  const [dataDok, setDataDok] = useState(null);
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState(true);

  const params = useSelector((state) => state?.admin?.paramsDoks);
  const paramsExpertise = useSelector((state) => state?.admin?.paramsExpertise);
  useEffect(() => {
    if (start) {
      setStart(false);
      getStarterKitById(id, store);
      getAllDoks(store, params,true);
      getSpecialitiesDoctolib(store, { ...paramsExpertise, limit: 180 });
    } else setStart(false);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store, loading]);

  const columns = useSelector((state) => state?.starterKit?.columns);
  const starterKit = useSelector((state) => state?.starterKit?.starterKit);
  const listDoks = useSelector((state) => state?.admin?.listDoks);
  const specialities = useSelector(
    (state) => state?.admin?.listSpecialitiesDoctolib.data,
  );
  console.log(starterKit);
  console.log(useSelector((state) => state?.starterKit?.columns));

  const updateColumns = (index, value) => {
    console.log(index);
    console.log(columns[index]);
    if (value) {
      let dok = columns.find(
        (item) => item.document && item.document.id === value.id,
      );
      console.log(dok);
      if (!dok) {
        store.dispatch({
          type: ADD_STARTER_KIT,
          payload: {
            value,
            index,
          },
        });
        setModalAddDok(false);
      } else toast.showError(store, 'Dok déjà existe dans la mosaique');
    } else {
      store.dispatch({
        type: ADD_STARTER_KIT,
        payload: {
          value: null,
          index,
        },
      });
      setModalAddDok(false);
    }
  };
  const onChange = async (sourceId, sourceIndex, targetIndex, targetId) => {
    if (columns[sourceIndex].type !== 'empty' && sourceIndex !== targetIndex) {
      console.log(sourceIndex);
      console.log(targetIndex);
      store.dispatch({
        type: UPDATE_STARTER_KIT,
        payload: {
          sourceIndex,
          targetIndex,
        },
      });
    }
    //const nextState = swap(columns, sourceIndex, targetIndex);
    //setColumns(nextState);
    //else setColumns(columns);
    /*if (targetId) {
      const result = move(
        columns[sourceId],
        columns[targetId],
        sourceIndex,
        targetIndex,
      );
      return setColumns({
        ...columns,
        [sourceId]: result[0],
        [targetId]: result[1],
      });
    }

    const result = swap(columns[sourceId], sourceIndex, targetIndex);
    return setColumns({
      ...columns,
      [sourceId]: result,
    });*/
  };
  const dokValidationSchema = yup.object().shape({
    speciality_id: yup.string().required('Champ requis'),
  });
  const [isLoading, setIsLoading] = useState(false);
  console.log(columns);

  const onSubmit = async (values) => {
    console.log(columns.find((item) => item.type === 'dok'));
    try {
      if (columns.find((item) => item.type === 'dok')) {
        setIsLoading(true);
        values.doks = columns;
        const result = await createUpdate(
          starterKit?.starterKit?.id || null,
          values,
          store,
          indexList,
        );
        if (result?.type === 'success') {
          toast.showSuccess(store, result?.message);
          navigate('/admin/starter-kit');
        } else {
          setIsLoading(false);
          toast.showError(store, result?.message);
          store.dispatch({
            type: LOADING_STOPED,
            payload: {},
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response)
        toast.showError(
          store,
          error?.response?.data?.message ||
            'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        );
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  if (loading)
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            {' '}
            <Skeleton animation="wave" height={20} width="20%" />
          </div>
          <div className="card-body">
            <Skeleton animation="wave" height={20} width="20%" />
            <Skeleton animation="wave" height={20} width="20%" />
            <div className="row">
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0">
              {id ? 'Modifier un starter kit' : 'Créer un starter kit'}
            </h5>
          </div>
          <Formik
            initialValues={{
              speciality_id:
                starterKit?.starterKit?.speciality_data_source?.id || '',
            }}
            validationSchema={dokValidationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              handleChange,
              values,
            }) => (
              <>
                <div className="card-body">
                  <SelectField
                    isMulti={false}
                    width="col-lg-6"
                    options={specialities || []}
                    label="Spécialité"
                    handleChange={({ value }) =>
                      setFieldValue('speciality_id', value)
                    }
                    name="speciality_id"
                    value={values.speciality_id}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    touched={touched}
                  />

                  <GridContextProvider onChange={onChange}>
                    <GridDropZone
                      id="items"
                      boxesPerRow={isMobile ? 1 : 4}
                      rowHeight={175}
                      className="row"
                      style={{
                        height: isMobile
                          ? `${columns.length * 175}px`
                          : `${(columns.length / 4) * 175}px`,
                      }}
                    >
                      {columns.length > 0 &&
                        columns.map((item, index) => {
                          return (
                            <GridItem
                              key={item.id}
                              id={index}
                              className={
                                isMobile
                                  ? 'col-md-12 col-sm-12'
                                  : 'col-md-3 col-sm-3'
                              }
                              style={{
                                //zIndex: 10,
                                opacity: 'inherit',
                                width: -1,
                                height: -1,
                                boxSizing: -1,
                              }}
                            >
                              {item.type === 'empty' ? (
                                <Empty
                                  columnId={item.id}
                                  setColumnId={setColumnId}
                                  setDataList={setDataList}
                                  setIndexList={setIndexList}
                                  setIndexDok={setIndexDok}
                                  setOpenModalDok={setModalAddDok}
                                  setDataDok={setDataDok}
                                  setModalAddList={setModalAddList}
                                  index={index}
                                />
                              ) : (
                                <Dok
                                  updateColumns={updateColumns}
                                  //setColumns={setColumns}
                                  columns={columns}
                                  dok={item.document}
                                  index={index}
                                  columnId={item.id}
                                  col="col-md-12 col-sm-12"
                                />
                              )}
                            </GridItem>
                          );
                        })}
                    </GridDropZone>
                  </GridContextProvider>
                </div>
                <div className="card-footer d-flex justify-content-between align-items-center">
                  <div>
                    <Link to="/admin/starter-kit">
                      <i className="fas fa-reply-all me-2"></i>Retour à la liste
                    </Link>
                  </div>
                  <div>
                    <button
                      onClick={() => navigate('/admin/starter-kit')}
                      className="btn btn-danger"
                      type="button"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="btn btn-primary ms-3"
                      type="button"
                    >
                      {id ? 'Modifier' : 'Ajouter'}
                    </button>
                  </div>
                </div>
              </>
            )}
          </Formik>
        </div>
        <FormModal
          body={
            <FormDokAdmin
              columnId={columnId}
              // setIsLoadingPage={setIsLoadingPage}
              data={dataDok}
              listDoks={listDoks}
              index={indexDok}
              indexList={indexList}
              setOpenModal={setModalAddDok}
              updateColumns={updateColumns}
            />
          }
          edge="end"
          onHide={() => setModalAddDok(false)}
          show={modalAddDok}
          size="lg"
        />
      </div>
    );
};

export default MosaicStarterKit;
