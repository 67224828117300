import { apiCall } from '../../../utils/helpers';
import { GET_NBR_DOK_REFERENCE } from '../../../redux/reducers/statistic';

export async function getNbrDokReference(store) {
  const url = `/api/statistic/nbr-dok-reference`;
  const { data } = await apiCall({
    method: 'get',
    url,
  });
  console.log(data);
  if (data?.type === 'success')
    store.dispatch({
      type: GET_NBR_DOK_REFERENCE,
      payload: {
        nbrDokReference: data.data,
      },
    });
  return data;
}
