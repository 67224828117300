import React, { useEffect } from 'react';
import Select from 'react-select';

const SelectField = (props) => {
  const {
    touched,
    handleSubmit,
    options,
    errors,
    handleChange,
    value,
    name,
    label,
    isMulti,
    closeMenuOnSelect,
    components,
    width,
    handleChangeSearch,
    isLoading,
  } = props;
  const keyPressed = (event, submit) => {
    if (event.key === 'Enter') {
      submit();
    }
  };
  /*useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);*/

  return (
    <div className={`mb-3 ${width}`}>
      <div className="form-group">
        <label>{label}</label>
        <div
        //style={{ width: width ? width : '100%' }}
        // className="input-group mb-3"
        >
          <Select
            className="md"
            closeMenuOnSelect={closeMenuOnSelect}
            components={components}
            isLoading={isLoading}
            isMulti={isMulti}
            //onKeyDown={(e) => keyPressed(e, handleSubmit)}
            pageSize={3}
            maxMenuHeight={200}
            noOptionsMessage={() => 'Aucune option'}
            isSearchable={true}
            onInputChange={(value) => {
              if (name === 'pds') {
                handleChangeSearch(value);
              }
            }}
            styles={{
              control: (styles) => ({
                ...styles,
                borderRadius: 10,
                height: 20,
                borderColor:
                  errors[name] && touched[name]
                    ? '#e23e3d'
                    : !errors[name] && touched[name] && value
                    ? '#58c995'
                    : styles.borderColor,
                /*'&:hover': {
              borderColor: errors.crypto
                ? '#e23e3d'
                : styles['&:hover'].borderColor,
            },*/
              }),
            }}
            value={
              Array.isArray(value)
                ? value
                : options.filter((option) => option.value === value)
            }
            classNamePrefix="Select-lg"
            options={options}
            placeholder={label}
            onChange={handleChange}
            name={name}
            defaultValue={value}
          />
          {errors && errors[name] && touched[name] && (
            <>
              <small id="emailHelp" className="form-text text-danger">
                {errors[name]}
              </small>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectField;
