import { apiCall } from '../../utils/helpers';
import { GET_DOK } from '../../redux/reducers/contributor';

export async function getDok(id, store) {
  try {
    const url = `/api/dok/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data?.type === 'success')
      store.dispatch({
        type: GET_DOK,
        payload: {
          data: data.data,
        },
      });
    return data;
  } catch (e) {}
}
