import { apiCall } from '../../../utils/helpers';
import { GET_STATS_OPEN_DOK } from '../../../redux/reducers/statistic';

export async function getStatsOpenDoks(store) {
  const url = `/api/statistic/stats-open-dok`;
  const { data } = await apiCall({
    method: 'get',
    url,
  });
  console.log(data);
  if (data?.type === 'success')
    store.dispatch({
      type: GET_STATS_OPEN_DOK,
      payload: {
        statsOpenDok: data.data,
      },
    });
  return data;
}
