import { apiCall } from '../../../utils/helpers';
import { GET_LIST_STARTER_KIT } from '../../../redux/reducers/starter-kit';

export async function getAllStarterKit(store, params) {
  try {
    let url = `/api/starter-kit?limit=${params.limit || 5}&current_page=${
      params.current_page
    }`;
    if (params.speciality_id && params.speciality_id !== 'undefined') {
      url += `&speciality_id=${params.speciality_id}`;
    }
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data?.total > 0) {
      store.dispatch({
        type: GET_LIST_STARTER_KIT,
        payload: {
          list: data,
          params,
        },
      });
    } else
      store.dispatch({
        type: GET_LIST_STARTER_KIT,
        payload: {
          list: { data: [] },
          params,
        },
      });
    return data;
  } catch (error) {
    store.dispatch({
      type: GET_LIST_STARTER_KIT,
      payload: {
        list: { data: [] },
        params,
      },
    });
  }
}
