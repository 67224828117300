import { apiCall } from '../../utils/helpers';
import {
  GET_SPECIALITIES,
  GET_SPECIALITIES_DOCTOLIB,
} from '../../redux/reducers/admin';
import axios from 'axios';
import Input from '../../components/Inputs';
import { Formik } from 'formik';
import React from 'react';

export async function getSpecialitiesDoctolib(store, params) {
  try {
    let url = `${
      process.env.REACT_APP_PUBLIC_URL
    }/api/admin/data/specialities/doctolib?limit=${
      params.limit || 5
    }&current_page=${params.current_page}&search=${params.search}`;
    if (params.order && params.order !== 'undefined') {
      url += `&order=${params.order}`;
    }
    if (params.field && params.field !== 'undefined') {
      url += `&field=${params.field}`;
    }
    const { data } = await axios.get(url);
    if (data?.total > 0)
      store.dispatch({
        type: GET_SPECIALITIES_DOCTOLIB,
        payload: {
          list: data,
          params,
        },
      });
    else
      store.dispatch({
        type: GET_SPECIALITIES_DOCTOLIB,
        payload: {
          list: { data: [] },
          params,
        },
      });
    return data;
  } catch (error) {
    store.dispatch({
      type: GET_SPECIALITIES_DOCTOLIB,
      payload: {
        list: { data: [] },
        params,
      },
    });
  }
}
