import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import Input from '../../../components/Inputs';
import toast from '../../../utils/toast';
import { Card } from 'react-bootstrap';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createNotification } from '../../../actions/admin/create-notification';
import TextArea from '../../../components/TextArea';
import SelectField from '../../../components/Select/Select';
import { getAllPds } from '../../../actions/admin/getAllPds';
import makeAnimated from 'react-select/animated';
import { getActiveSpecialities } from '../../../actions/getActiveSpecialities';

const animatedComponents = makeAnimated();

const CreateUpdateNotification = ({
  setOpenModal,
  lists,
  setIsLoadingPage,
}) => {
  console.log(lists);
  let navigate = useNavigate();
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const notificationValidationSchemaNoProof = yup.object().shape({
    title: yup.string().required('Champ requis !'),
    message: yup.string().required('Champ requis !'),
  });
  const notificationValidationSchemaNamePds = yup.object().shape({
    title: yup.string().required('Champ requis !'),
    message: yup.string().required('Champ requis !'),
    pds: yup.array().min(1, 'Champ requis !').required('Champ requis !'),
  });
  const notificationValidationSchemaSpeciality = yup.object().shape({
    specialities: yup
      .array()
      .min(1, 'Champ requis !')
      .required('Champ requis !'),
    title: yup.string().required('Champ requis !'),
    message: yup.string().required('Champ requis !'),
  });
  const [typeCible, setTypeCible] = useState('name_pds');
  const [start, setStart] = useState(true);
  const paramsExpertise = useSelector((state) => state?.admin?.paramsExpertise);
  const params = useSelector((state) => state?.admin?.paramsPDS);
  useEffect(() => {
    if (start) {
      setStart(false);
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      getActiveSpecialities(store, { ...paramsExpertise, limit: 180 });
      getAllPds(store, params);
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
      return () => {};
    } else setStart(false);
  }, [start]);

  const specialities = useSelector(
    (state) => state?.speciality?.activeSpecialities,
  );
  const listPDS = useSelector((state) => state?.admin?.listPDS);
  console.log(listPDS?.data);
  const [loading, setLoading] = useState(true);

  let { id } = useParams();
  const handleChangeSearch = async (value) => {
    setLoading(true);
    const newParams = {
      ...params,
      search: value,
    };
    await getAllPds(store, newParams);
    setLoading(false);
  };
  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const result = await createNotification(id || null, values, store);
      if (result?.type === 'success') {
        navigate('/admin/notifications');
        setIsLoading(false);
        setIsLoadingPage(true);
        setOpenModal(false);
        toast.showSuccess(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response)
        toast.showError(
          store,
          error?.response?.data?.message ||
            'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        );
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };

  return (
    <div className="page-content w-100">
      <div className="card admin-card shadow-sm border-0">
        <div className="card-header">
          <h5 className="mb-0">
            {id ? 'Modifier une notification' : 'Créer une notification'}
          </h5>
        </div>
        <Formik
          initialValues={{
            title: '',
            message: '',
            filterBy: 'name_pds',
            type: 'dokatis',
            specialities: [],
            pds: [],
          }}
          validationSchema={
            typeCible === 'name_pds'
              ? notificationValidationSchemaNamePds
              : typeCible === 'speciality'
              ? notificationValidationSchemaSpeciality
              : notificationValidationSchemaNoProof
          }
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            handleChange,
            values,
          }) => (
            <>
              <div className="card-body">
                <Input
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  width="col-lg-6"
                  value={values.title}
                  errors={errors}
                  touched={touched}
                  label="Titre"
                  type="string"
                  name="title"
                  placeholder="Titre"
                />
                <div className="form-group">
                  <label htmlFor="basic-url">Message</label>
                  <TextArea
                    cols="60"
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    width="col-lg-8"
                    value={values.message}
                    errors={errors}
                    touched={touched}
                    label="Message"
                    type="text"
                    name="message"
                    placeholder="Message"
                    rows={4}
                  />
                </div>
                <div className="form-group">
                  <div className="d-flex mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="formCheck-5"
                        name="filterBy"
                        value="name_pds"
                        checked={values.filterBy === 'name_pds'}
                        onChange={(e) => {
                          setFieldValue('specialities', []);
                          setTypeCible('name_pds');
                          handleChange(e);
                        }}
                      />
                      <label className="form-check-label" htmlFor="formCheck-5">
                        Par nom
                      </label>
                    </div>
                    <div className="form-check ms-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="formCheck-6"
                        name="filterBy"
                        value="speciality"
                        checked={values.filterBy === 'speciality'}
                        onChange={(e) => {
                          setFieldValue('pds', []);
                          setTypeCible('speciality');
                          handleChange(e);
                        }}
                      />
                      <label className="form-check-label" htmlFor="formCheck-6">
                        Par spécialité
                      </label>
                    </div>
                    <div className="form-check ms-4">
                      <input
                        className="form-check-input"
                        id="formCheck-7"
                        name="filterBy"
                        type="radio"
                        value="no_proof"
                        checked={values.filterBy === 'no_proof'}
                        onChange={(e) => {
                          setFieldValue('specialities', []);
                          setFieldValue('pds', []);
                          setTypeCible('no_proof');
                          handleChange(e);
                        }}
                      />
                      <label className="form-check-label" htmlFor="formCheck-7">
                        Pds non justificatif
                      </label>
                    </div>
                  </div>
                </div>
                {values.filterBy === 'speciality' ? (
                  <SelectField
                    isMulti={true}
                    width="col-lg-6"
                    options={specialities || []}
                    label="Spécialité"
                    handleChange={(value) =>
                      setFieldValue('specialities', value)
                    }
                    name="specialities"
                    value={values.specialities}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                  />
                ) : values.filterBy === 'name_pds' ? (
                  <SelectField
                    isMulti={true}
                    width="col-lg-6"
                    options={listPDS.data || []}
                    label="Pds"
                    handleChange={(value) => setFieldValue('pds', value)}
                    name="pds"
                    value={values.pds}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    handleChangeSearch={handleChangeSearch}
                    isLoading={loading}
                  />
                ) : (
                  ''
                )}
              </div>

              <div className="card-footer d-flex justify-content-between align-items-center">
                <div>
                  <Link to="/admin/notifications">
                    <i className="fas fa-reply-all me-2"></i>Retour à la liste
                  </Link>
                </div>
                <div>
                  <button
                    onClick={() => navigate('/admin/notifications')}
                    className="btn btn-danger"
                    type="button"
                  >
                    Annuler
                  </button>
                  <button
                    onClick={handleSubmit}
                    className={
                      isLoading
                        ? 'link-light btn btn-primary ms-3'
                        : ' login100-form-btn link-light btn btn-primary ms-3'
                    }
                    type="button"
                  >
                    {id ? 'Modifier' : 'Ajouter'}
                  </button>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateUpdateNotification;
