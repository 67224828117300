import { createSlice } from '@reduxjs/toolkit';

export const specialitySlice = createSlice({
  name: 'speciality',
  initialState: {
    list: [],
    activeSpecialities: [],
  },
  reducers: {
    getList: (state, action) => {
      const { list } = action.payload;
      state.list = list.data;
    },
    getActiveSpecialties: (state, action) => {
      const { list } = action.payload;
      state.activeSpecialities = list;
    },
  },
});

export const GET_LIST_SPECIALITIES = 'speciality/getList';
export const GET_LIST_ACTIVE_SPECIALITIES = 'speciality/getActiveSpecialties';

export default specialitySlice.reducer;
