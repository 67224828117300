import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { getNbrKeywords } from '../../../../actions/admin/statistic/getNbrKeywords';
import { Badge, Button, Col, Row } from 'react-bootstrap';

export default function GetKeywords() {
  const store = useStore();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getNbrKeywords(store);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);

  const keywords = useSelector((state) => state?.statistic?.keywords);
  console.log(keywords);
  if (loading)
    return (
      <div className="page-content w-100">
        <div className="card shadow-sm border-0 p-2">
          <section className="mb-5"></section>
        </div>
      </div>
    );

  return (
    <Row>
      <Col md={2}>
        <span>Mots clés référencés</span>
      </Col>
      <Col md={10}>
        <div className="">
          {keywords.map((item) => (
            <Button style={{ margin: 5, cursor: 'default' }} variant="light">
              {item.title} <Badge bg="info">{item.totalCount} </Badge>
              <span className="visually-hidden">unread messages</span>
            </Button>
          ))}
        </div>
      </Col>
    </Row>
  );
}
