import { apiCall } from '../../utils/helpers';
import { GET_LIST_DOK_CONTRIBUTOR } from '../../redux/reducers/contributor';

export async function getAllDoks(store, params) {
  try {
    const url = `/api/dok`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });

    if (data?.total > 0) {
      store.dispatch({
        type: GET_LIST_DOK_CONTRIBUTOR,
        payload: {
          list: data,
          params,
        },
      });
    } else
      store.dispatch({
        type: GET_LIST_DOK_CONTRIBUTOR,
        payload: {
          list: { data: [] },
          params,
        },
      });
    return data;
  } catch (error) {
    store.dispatch({
      type: GET_LIST_DOK_CONTRIBUTOR,
      payload: {
        list: { data: [] },
        params,
      },
    });
  }
}
