import { apiCall } from '../../../utils/helpers';
import { GET_SETTING } from '../../../redux/reducers/admin';

export async function getConfig(store) {
  const url = `/api/app-config`;
  const { data } = await apiCall({
    method: 'get',
    url,
  });
  if (data?.type === 'success') {
    store.dispatch({
      type: GET_SETTING,
      payload: {
        setting: data?.data,
      },
    });
  }
  return data;
}
