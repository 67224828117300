import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { getCode } from '../../../actions/admin/getCode';

export default function DetailsCode() {
  const store = useStore();
  let { id } = useParams();
  const [load, setLoad] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getCode(id, store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }, [load]);
  const code = useSelector((state) => state?.admin?.code);

  if (load)
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Détail code</h3>
              </div>
              <div className="card-body">
                <div className="mb-5">
                  <Skeleton animation="wave" width="70%" height={10} />
                </div>
                <div className="mb-5">
                  <Skeleton animation="wave" width="70%" height={10} />
                </div>
                <div className="mb-5">
                  <Skeleton animation="wave" width="70%" height={10} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="page-content w-100">
      <div className="card admin-card shadow-sm border-0">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Détail code</h3>
          </div>
          <div className="card-body">
            {code?.title && (
              <div className="mb-3">
                <Row style={{ minHeight: 5 }}>
                  <Col md={2}>
                    <div className="d-flex align-items-center mb-3">
                      <h5 className="mb-0">
                        Titre
                        <br />
                      </h5>
                    </div>
                  </Col>
                  <Col md={10}>
                    <p className="mb-0">
                      {' '}
                      {`${code.title} ${code.reduction}%`}
                    </p>
                  </Col>
                </Row>
              </div>
            )}

            {code?.created_at && (
              <div className="mb-5">
                <Row style={{ minHeight: 5 }}>
                  <Col md={2}>
                    <div className="d-flex align-items-center mb-3">
                      <h5 className="mb-0">
                        Date de création
                        <br />
                      </h5>
                    </div>
                  </Col>
                  <Col md={10}>
                    <p className="mb-0">
                      {moment(code?.created_at).format('DD/MM/YYYY')}
                    </p>
                  </Col>
                </Row>
              </div>
            )}
            {code?.url && (
              <div className="mb-5">
                <Row style={{ minHeight: 5 }}>
                  <Col md={2}>
                    <div className="d-flex align-items-center mb-3">
                      <h5 className="mb-0">
                        Lus
                        <br />
                      </h5>
                    </div>
                  </Col>
                  <Col md={10}>
                    <p className="mb-0">
                      {moment(code?.date).format('DD/MM/YYYY')}
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <div className="card-footer d-flex justify-content-between align-items-center">
            <div>
              <Link to="/admin/advantages-code">
                <i className="fas fa-reply-all me-2"></i>Retour à la liste
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
