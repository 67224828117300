import { createSlice } from '@reduxjs/toolkit';

export const listSlice = createSlice({
  name: 'list',
  initialState: {
    list: { data: [] },
    listById: null,
  },
  reducers: {
    getListById: (state, action) => {
      const { list } = action.payload;
      state.listById = list;
    },
    getList: (state, action) => {
      const { list } = action.payload;
      state.list = list;
    },
    add: (state, action) => {
      const { data } = action.payload;
      state.list.data.unshift(data);
    },
    addDok: (state, action) => {
      const { data, listId } = action.payload;
      let index = state.list.data.findIndex((item) => item.id === listId);
      state.list.data[index].documents.unshift(data);
    },
    update: (state, action) => {
      const { data, index } = action.payload;

      state.list.data[index] = {
        ...data,
      };
    },
    updateDok: (state, action) => {
      const { data, index, listId } = action.payload;
      let indexList = state.list.data.findIndex((item) => item.id === listId);
      state.list.data[indexList].documents[index] = {
        ...data,
      };
    },
    removeDok: (state, action) => {
      const { index, removedIds } = action.payload;
      state.list.data[index].documents.splice(removedIds, 1);
    },
    removeList: (state, action) => {
      const { index } = action.payload;
      state.list.data.splice(index, 1);
    },
  },
});

export const GET_LIST_LIST = 'list/getList';
export const GET_LIST_BY_ID = 'list/getListById';
export const ADD_LIST = 'list/add';
export const UPDATE_LIST = 'list/update';
export const UPDATE_LIST_DOK = 'list/updateDok';
export const REMOVE_DOK = 'list/removeDok';
export const REMOVE_LIST = 'list/removeList';
export const ADD_DOK = 'list/addDok';

export default listSlice.reducer;
