import React, { useState } from 'react';
import './ContactForm.css'; // Import the CSS file for styling
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        let data = axios
            .post(`${process.env.REACT_APP_PUBLIC_URL}/api/contactus/send`, formData)
            .then(async ({ data }) => {
                const response = data;
                console.log('Email successfully sent!');
                alert('Email successfully sent!');
                // Clear form
                setFormData({
                  name: '',
                  email: '',
                  subject: '',
                  message: ''
                });
                return response;
            })
            .catch((apiError) => {
                if (apiError.response && apiError.response.status === 503) {
                
                    console.error('Error:', apiError);
                    alert('Failed to send email. Please try again later.');
                }
                return Promise.reject(apiError);
            });

    } catch (error) {
        
      console.error('Error:', error);
      alert('Failed to send email. Please try again later.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <div className="form-group">
        <label htmlFor="name">Nom:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">E-mail:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="subject">Objet:</label>
        <input
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>
      <button type="submit" className="submit-button">Envoyer</button>
    </form>
  );
};

export default ContactForm;
