import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './paymentForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_test_51MQtRiI42EzgFsQMz8TtTlrbcfymQzkqaFwzvao76BeVNcXiZBFYASMTk4gtFAdCQ4jWFua7iI5c6EebaWKlGQ1J00XxIPxwcx',
);

export default function FormPayment({
  amount,
  setModalPayment,
  selected,
  type,
  pack,
  onSubmit,
  initialValues,
  nameDok,
}) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        pack={pack}
        type={type}
        selected={selected}
        amount={amount}
        setModalPayment={setModalPayment}
        initialValues={initialValues}
        onSubmit={onSubmit}
        nameDok={nameDok}
      />
    </Elements>
  );
}
