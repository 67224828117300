import React from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalComponent({titlecontent, bodycontent, ...props}) {
  return (
    <Modal className={props.class} fullscreen="lg-down" {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
          <span style={{ fontWeight: 500 }}>{titlecontent ? titlecontent : props.title}</span>
      </Modal.Header>
      <Modal.Body style={{ height: props.height ? '40rem' : '' }}>
        {bodycontent ? bodycontent : props.body}
      </Modal.Body>
      {props.showFooter ? (
        ''
      ) : (
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={props.onHide}
            type="button"
          >
            Annuler
          </button>
          {props?.footer}
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ModalComponent;
