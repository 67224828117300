import React, { Fragment, lazy, useState, useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { getMe } from './actions/getMe';
import './index.scss';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LOADING_STARTED, LOADING_STOPED } from './redux/reducers/loader';
import { hasRole } from './utils/helpers';
import CreateUpdateSpeciality from './pages/admin/specility/create-update-speciality';
import StatisticDok from './pages/admin/doks/statistic';
import Notifications from './pages/admin/notifications';
import CreateUpdateNotification from './pages/admin/notifications/create-notification';
import DetailsNotification from './pages/admin/notifications/details';
import AdvantageCode from './pages/admin/advantage-code';
import CreateUpdateCode from './pages/admin/advantage-code/create-code';
import DetailsCode from './pages/admin/advantage-code/details';
import Campagnes from './pages/admin/campagnes';
import Skeleton from '@mui/material/Skeleton';
import CreateUpdateCampagne from './pages/admin/campagnes/create-update-campagne';
import DetailsCampagne from './pages/admin/campagnes/details';
import DetailsCampagneC from './pages/contibutor/campagnes/details';
import CreateUpdateContributor from './pages/admin/contributor/create-update';
import DetailsContributor from './pages/admin/contributor/details';
import PacksAdmin from './pages/admin/packs';
import CreateUpdatePack from './pages/admin/packs/create-update';
import DetailsPack from './pages/admin/packs/details';
import Settings from './pages/admin/app-config';
import CreateUpdateDokContributor from './pages/contibutor/doks/create-update-dok';
import DoksContributor from './pages/contibutor/doks';
import CampagnesContributor from './pages/contibutor/campagnes';
import CreateUpdateCampagneCont from './pages/contibutor/campagnes/create-update-campagne';
import Bills from './pages/admin/bill';
import DetailsBill from './pages/admin/bill/details';
import Payment from './pages/contibutor/payment';
import CommandeFacture from './pages/contibutor/payment/commande';
import PaymentCampagne from './pages/contibutor/campagnes/payment';
import FacturePdf from './pages/contibutor/payment/bill-pdf';
import NotificationsContributor from './pages/contibutor/notification';
import UpgradePack from './pages/contibutor/buy-pack/payment';
import StarterKit from './pages/admin/starter-kit';
import MosaicStarterKit from './pages/admin/starter-kit/create-update';
import DetailsStarterKit from './pages/admin/starter-kit/details';
import Statistic from './pages/admin/statistic';
import Curation from './pages/general/curation/index.js';
import Corporate from './pages/general/corporate/index.js';
import ComingSoon from './pages/general/comingsoon/index.js';
import PacksSurMesureAdmin from './pages/admin/packs_surmesure';
import CreateUpdatePackSurMesure from './pages/admin/packs_surmesure/create-update';
import DetailsPackSurMesure from './pages/admin/packs_surmesure/details';

/**Auth Page*/
const Auth = lazy(() => import('./pages/auth'));
const RegisterPds = lazy(() => import('./pages/auth/register/pds'));
const RegisterContibutor = lazy(() =>
  import('./pages/auth/register/contibutor'),
);
const Login = lazy(() => import('./pages/auth/login'));
const Landing = lazy(() => import('./pages/landingPage'));
const DokatisAi = lazy(() => import('./pages/dokatisAi'));
const CompleteAccount = lazy(() => import('./pages/auth/complete-account'));
const ResetPassword = lazy(() =>
  import('./pages/auth/Forgot Password/resetPassword'),
);

const ForgotPassword = lazy(() => import('./pages/auth/Forgot Password'));

/**Error Page*/
const Error403 = lazy(() => import('./pages/errorPage/Error403'));

/**PDS Page*/
const Apps = lazy(() => import('./components/App.js'));
const PDSProfile = lazy(() => import('./pages/pds/profile'));
const PDSApropos = lazy(() => import('./pages/pds/profile/a-propos'));
const List = lazy(() => import('./pages/pds/list'));
const MySelection = lazy(() => import('./pages/pds/my-selection'));
const Mosaic = lazy(() => import('./pages/pds/mosaic'));
const NotificationsUser = lazy(() => import('./pages/pds/notification'));

/**Admin Page*/
const AppAdmin = lazy(() => import('./pages/admin/layouts/appAdmin'));
const DataSources = lazy(() => import('./pages/admin/data'));
const ProfileAdmin = lazy(() => import('./pages/admin/profil'));
const PdsAdmin = lazy(() => import('./pages/admin/pds'));
const ContributorAdmin = lazy(() => import('./pages/admin/contributor'));
const CreateUpdatePds = lazy(() => import('./pages/admin/pds/create-update'));
const DetailsPdsAdmin = lazy(() => import('./pages/admin/pds/details'));
const DOKS = lazy(() => import('./pages/admin/doks'));
const CreateUpdateDok = lazy(() =>
  import('./pages/admin/doks/create-update-dok'),
);
const Specility = lazy(() => import('./pages/admin/specility'));
/**Search*/
const PdsSearch = lazy(() => import('./pages/landingPage/search/pds'));
const DetailsPds = lazy(() => import('./pages/landingPage/search/details-pds'));
const MosaicPds = lazy(() => import('./pages/landingPage/search/mosaic'));
const DokSearch = lazy(() => import('./pages/landingPage/search/doks'));
const ListPublic = lazy(() =>
  import('./pages/landingPage/search/mosaic/listPublic'),
);
/**Contibutor Page*/
const AppContibutor = lazy(() => import('./pages/contibutor/layouts/appAdmin'));
const ProfileContibutor = lazy(() => import('./pages/contibutor/profile'));
const PacksContibutor = lazy(() => import('./pages/contibutor/packs'));
const BuyPack = lazy(() => import('./pages/contibutor/buy-pack'));

function App() {
  const store = useStore();
  const loading = useSelector((state) => state.loader.loading);
  const userProfile = useSelector((state) => state.auth.userProfile);
  const [userConnected, setUserConnected] = useState(true);
  const connected = localStorage.getItem('connected');
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });

    if (userConnected) {
      getMe(store);
      setUserConnected(false);
    } else {
      setUserConnected(false);
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
    return () => {};
  }, [connected, loading, store]);

  let app;
  if (loading) {
    app = (
      <Fragment>
        <main style={{ padding: 200 }}>
          <Skeleton animation="wave" width="100%" height={50} />
          <Skeleton animation="wave" width="100%" height={50} />
          <Skeleton animation="wave" width="100%" height={50} />
        </main>
      </Fragment>
    );
  } else if (!connected || connected === 'false') {
    app = (
      <Fragment>
        <React.Suspense>
          <Routes>
            <Route path={`/error403`} element={<Error403 />} />
            {/* Auth */}
            <Route path={`/`} element={<Auth />}>
              <Route path="*" element={<Navigate replace to={`/`} />} />
              <Route path={`/`} element={<Landing />} />
              <Route path={`/auth/register/pds`} element={<RegisterPds />} />
              <Route
                path={`/auth/register/contributor`}
                element={<RegisterContibutor />}
              />
              <Route
                path={`/auth/complete-account/:id`}
                element={<CompleteAccount />}
              />
              <Route path={`/auth/login`} element={<Login />} />
              <Route path={`/auth/get-account`} element={<ForgotPassword />} />
              <Route
                path={`/auth/forgotpassword`}
                element={<ResetPassword />}
              />
              <Route path={`/:category/pds`} element={<PdsSearch />} />
              <Route path={`/:category/dok`} element={<DokSearch />} />
              <Route
                path={`/:category/:town/:pdsName/a-propos`}
                element={<DetailsPds />}
              />
              <Route
                path={`/:pdsName/:pdsId/a-propos`}
                element={<DetailsPds />}
              />
              <Route
                path={`/:category/:town/:pdsName`}
                element={<MosaicPds />}
              />
              <Route
                path={`/:category/:town/:pdsName/:pdsId`}
                element={<MosaicPds />}
              />
              <Route
                path={`/:category/:town/:pdsName/list/:id`}
                element={<ListPublic />}
              />
              <Route path={`/contributor/buy-pack`} element={<BuyPack />} />
              
              <Route path={`/curation`} element={<Curation />} />
              <Route path={`/corporate`} element={<Corporate />} />
              <Route path={`/comingsoon`} element={<ComingSoon />} />
              <Route
                path={`/contributor/signup/doks/create`}
                element={<CreateUpdateDokContributor noDirectSave="true" />}
              />
            </Route>
          </Routes>
        </React.Suspense>
      </Fragment>
    );
  } else if (hasRole(userProfile && userProfile?.user?.roles, 'ADM')) {
    app = (
      <Fragment>
        <React.Suspense>
          <Routes>
            <Route>
              <Route path={`/`} element={<Auth />}>
                <Route path={`/`} element={<Landing />} />
                <Route path={`/dokatis-ai`} element={<DokatisAi />} />
                <Route path={`/pds/profile`} element={<PDSProfile />} />
                <Route path={`/pds/a-propos`} element={<PDSApropos />} />
                <Route path={`/pds/list/:id`} element={<List />} />
                <Route path={`/pds`} element={<Mosaic />} />
                <Route path={`/pds/my-selection`} element={<MySelection />} />
                <Route path={`/:category/pds`} element={<PdsSearch />} />
                <Route path={`/:category/dok`} element={<DokSearch />} />
                <Route
                  path={`/:category/:town/:pdsName/a-propos`}
                  element={<DetailsPds />}
                />
                <Route
                  path={`/:pdsName/:pdsId/a-propos`}
                  element={<DetailsPds />}
                />
                <Route
                  path={`/:category/:town/:pdsName`}
                  element={<MosaicPds />}
                />
                <Route
                  path={`/:category/:town/:pdsName/:pdsId`}
                  element={<MosaicPds />}
                />
                <Route
                  path={`/:category/:town/:pdsName/list/:id`}
                  element={<ListPublic />}
                />
                
                <Route path={`/curation`} element={<Curation />} />
                <Route path={`/corporate`} element={<Corporate />} />
                <Route path={`/comingsoon`} element={<ComingSoon />} />
                <Route
                  path={`/contributor/signup/doks/create`}
                  element={<CreateUpdateDokContributor />}
                />
              </Route>
              <Route path={`/`} element={<AppAdmin />}>
                <Route
                  path="*"
                  element={<Navigate replace to={`/admin/pds`} />}
                />
                <Route path={`/admin/pds`} element={<PdsAdmin />} />
                <Route path={`/admin/doks`} element={<DOKS />} />
                <Route
                  path={`/admin/doks/create`}
                  element={<CreateUpdateDok />}
                />
                <Route
                  path={`/admin/doks/statistic/:id`}
                  element={<StatisticDok />}
                />
                <Route
                  path={`/admin/pds/create`}
                  element={<CreateUpdatePds />}
                />
                <Route
                  path={`/admin/pds/update/:id`}
                  element={<CreateUpdatePds />}
                />
                <Route path={`/admin/profile`} element={<ProfileAdmin />} />
                <Route path={`/admin/data-sources`} element={<DataSources />} />
                <Route
                  path={`/admin/pds/a-propos/:id`}
                  element={<DetailsPdsAdmin />}
                />
                <Route path={`/admin/specialities`} element={<Specility />} />
                <Route
                  path={`/admin/specialities/create`}
                  element={<CreateUpdateSpeciality />}
                />
                <Route
                  path={`/admin/specialities/update/:id`}
                  element={<CreateUpdateSpeciality />}
                />
                <Route
                  path={`/admin/specialities/update/:id`}
                  element={<CreateUpdateSpeciality />}
                />
                <Route
                  path={`/admin/notifications`}
                  element={<Notifications />}
                />{' '}
                <Route
                  path={`/admin/notifications/create`}
                  element={<CreateUpdateNotification />}
                />
                <Route
                  path={`/admin/notifications/detail/:id`}
                  element={<DetailsNotification />}
                />
                <Route
                  path={`/admin/advantages-code`}
                  element={<AdvantageCode />}
                />{' '}
                <Route
                  path={`/admin/advantages-code/create`}
                  element={<CreateUpdateCode />}
                />
                <Route
                  path={`/admin/advantages-code/detail/:id`}
                  element={<DetailsCode />}
                />
                <Route path={`/admin/campagnes`} element={<Campagnes />} />
                <Route
                  path={`/admin/campagnes/create`}
                  element={<CreateUpdateCampagne />}
                />
                <Route
                  path={`/admin/campagnes/detail/:id`}
                  element={<DetailsCampagne />}
                />
                <Route
                  path={`/admin/campagnes/update/:id`}
                  element={<CreateUpdateCampagneCont />}
                />
                <Route
                  path={`/admin/campagnes/details/:id`}
                  element={<DetailsCampagne />}
                />
                <Route
                  path={`/admin/contributor`}
                  element={<ContributorAdmin />}
                />
                <Route
                  path={`/admin/contributor/create`}
                  element={<CreateUpdateContributor />}
                />
                <Route
                  path={`/admin/contributor/update/:id`}
                  element={<CreateUpdateContributor />}
                />
                <Route
                  path={`/admin/contributor/details/:id`}
                  element={<DetailsContributor />}
                />
                <Route path={`/admin/packs`} element={<PacksAdmin />} />
                <Route
                  path={`/admin/packs/create`}
                  element={<CreateUpdatePack />}
                />
                <Route
                  path={`/admin/packs/update/:id`}
                  element={<CreateUpdatePack />}
                />
                <Route
                  path={`/admin/packs/details/:id`}
                  element={<DetailsPack />}
                />
                <Route path={`/admin/setting`} element={<Settings />} />
                <Route path={`/admin/sales`} element={<Bills />} />
                <Route
                  path={`/admin/sales/details/:id`}
                  element={<DetailsBill />}
                />
                <Route path={`/admin/starter-kit`} element={<StarterKit />} />
                <Route
                  path={`/admin/starter-kit/create`}
                  element={<MosaicStarterKit />}
                />
                <Route
                  path={`/admin/starter-kit/update/:id`}
                  element={<MosaicStarterKit />}
                />
                <Route
                  path={`/admin/starter-kit/detail/:id`}
                  element={<DetailsStarterKit />}
                />
                <Route path={`/admin/statistic`} element={<Statistic />} />
                {/* Pack Sur Mesure Routes */}
                <Route path={`/admin/pack-sur-mesure`} element={<PacksSurMesureAdmin />} />
                <Route
                  path={`/admin/pack-sur-mesure/create`}
                  element={<CreateUpdatePackSurMesure />}
                />
                <Route
                  path={`/admin/pack-sur-mesure/update/:id`}
                  element={<CreateUpdatePackSurMesure />}
                />
                <Route
                  path={`/admin/pack-sur-mesure/details/:id`}
                  element={<DetailsPackSurMesure />}
                />
                
              </Route>
            </Route>
          </Routes>
          {/*
          <Toast />*/}
        </React.Suspense>
      </Fragment>
    );
  } else if (hasRole(userProfile && userProfile?.user?.roles, 'PDS')) {
    app = (
      <Fragment>
        <React.Suspense>
          <Routes>
            <Route>
              <Route path={`/`} element={<Auth />}>
                <Route path={`/`} element={<Landing />} />
                <Route path={`/dokatis-ai`} element={<DokatisAi />} />
                <Route path="*" element={<Navigate replace to={`/pds`} />} />
                <Route path={`/pds/profile`} element={<PDSProfile />} />
                <Route path={`/pds/a-propos`} element={<PDSApropos />} />
                <Route path={`/pds/list/:id`} element={<List />} />
                <Route path={`/pds`} element={<Mosaic />} />
                <Route path={`/pds/my-selection`} element={<MySelection />} />
                <Route path={`/:category/pds`} element={<PdsSearch />} />
                <Route path={`/:category/dok`} element={<DokSearch />} />
                <Route
                  path={`/:category/:town/:pdsName/a-propos`}
                  element={<DetailsPds />}
                />
                <Route
                  path={`/:pdsName/:pdsId/a-propos`}
                  element={<DetailsPds />}
                />
                <Route
                  path={`/pds/notifications`}
                  element={<NotificationsUser />}
                />
                <Route
                  path={`/:category/:town/:pdsName`}
                  element={<MosaicPds />}
                />
                <Route
                  path={`/:category/:town/:pdsName/:pdsId`}
                  element={<MosaicPds />}
                />
                <Route
                  path={`/:category/:town/:pdsName/list/:id`}
                  element={<ListPublic />}
                />
                
                <Route path={`/curation`} element={<Curation />} />
                <Route path={`/corporate`} element={<Corporate />} />
                <Route path={`/comingsoon`} element={<ComingSoon />} />
                <Route
                  path={`/contributor/signup/doks/create`}
                  element={<CreateUpdateDokContributor noDirectSave="true" />}
                />
              </Route>
            </Route>
          </Routes>
          {/*
          <Toast />*/}
        </React.Suspense>
      </Fragment>
    );
  } else if (hasRole(userProfile && userProfile?.user?.roles, 'CBT')) {
    app = (
      <Fragment>
        <React.Suspense>
          <Routes>
            {userProfile?.packs &&
            userProfile?.packs.length === 0 &&
            userProfile?.pack_id ? (
              <Route>
                <Route path={`/`} element={<Auth />}>
                  <Route
                    path="*"
                    element={<Navigate replace to={`/contributor/buy-pack`} />}
                  />
                  <Route path={`/contributor/buy-pack`} element={<BuyPack />} />
                  <Route path={`/`} element={<Landing />} />
                  <Route path={`/pds/profile`} element={<PDSProfile />} />
                  <Route path={`/pds/a-propos`} element={<PDSApropos />} />
                  <Route path={`/pds/list/:id`} element={<List />} />
                  <Route path={`/pds`} element={<Mosaic />} />
                  <Route path={`/pds/my-selection`} element={<MySelection />} />
                  <Route path={`/:category/pds`} element={<PdsSearch />} />
                  <Route path={`/:category/dok`} element={<DokSearch />} />
                  <Route
                    path={`/:category/:town/:pdsName/a-propos`}
                    element={<DetailsPds />}
                  />
                  <Route
                    path={`/:pdsName/:pdsId/a-propos`}
                    element={<DetailsPds />}
                  />
                  <Route
                    path={`/:category/:town/:pdsName`}
                    element={<MosaicPds />}
                  />
                  <Route
                    path={`/:category/:town/:pdsName/:pdsId`}
                    element={<MosaicPds />}
                  />
                  <Route
                    path={`/:category/:town/:pdsName/list/:id`}
                    element={<ListPublic />}
                  />
                  <Route path={`/contributor/payment`} element={<Payment />} />
                  <Route
                    path={`/contributor/payment/detail/:id`}
                    element={<CommandeFacture />}
                  />
                  
                  <Route path={`/curation`} element={<Curation />} />
                  <Route path={`/corporate`} element={<Corporate />} />
                  <Route path={`/comingsoon`} element={<ComingSoon />} />
                  <Route
                    path={`/contributor/signup/doks/create`}
                    element={<CreateUpdateDokContributor noDirectSave="true" />}
                  />
                </Route>
              </Route>
            ) : userProfile?.packs && userProfile?.packs.length === 0 ? (
              <Route>
                <Route path={`/`} element={<Auth />}>
                  <Route
                    path="*"
                    element={<Navigate replace to={`/contributor/buy-pack`} />}
                  />
                  <Route path={`/`} element={<Landing />} />
                  <Route path={`/pds/profile`} element={<PDSProfile />} />
                  <Route path={`/pds/a-propos`} element={<PDSApropos />} />
                  <Route path={`/pds/list/:id`} element={<List />} />
                  <Route path={`/pds`} element={<Mosaic />} />
                  <Route path={`/pds/my-selection`} element={<MySelection />} />
                  <Route path={`/:category/pds`} element={<PdsSearch />} />
                  <Route path={`/:category/dok`} element={<DokSearch />} />
                  <Route
                    path={`/:category/:town/:pdsName/a-propos`}
                    element={<DetailsPds />}
                  />
                    <Route
                      path={`/:pdsName/:pdsId/a-propos`}
                    element={<DetailsPds />}
                  />
                  <Route
                    path={`/:category/:town/:pdsName`}
                    element={<MosaicPds />}
                  />
                  <Route
                    path={`/:category/:town/:pdsName/:pdsId`}
                    element={<MosaicPds />}
                  />
                  <Route
                    path={`/:category/:town/:pdsName/list/:id`}
                    element={<ListPublic />}
                  />
                  <Route path={`/contributor/buy-pack`} element={<BuyPack />} />
                  <Route
                    path={`/contributor/payment/detail/:id`}
                    element={<CommandeFacture />}
                  />
                  
                  <Route path={`/curation`} element={<Curation />} />
                  <Route path={`/corporate`} element={<Corporate />} />
                  <Route path={`/comingsoon`} element={<ComingSoon />} />
                  <Route
                    path={`/contributor/signup/doks/create`}
                    element={<CreateUpdateDokContributor noDirectSave="true" />}
                  />
                </Route>
              </Route>
            ) : (
              <Route>
                <Route path={`/`} element={<Auth />}>
                  <Route path={`/`} element={<Landing />} />
                  <Route path={`/contributor/buy-pack`} element={<BuyPack />} />
                  <Route path={`/contributor/buy-pack`} element={<Payment />} />

                  <Route path={`/pds/profile`} element={<PDSProfile />} />
                  <Route path={`/pds/a-propos`} element={<PDSApropos />} />
                  <Route path={`/pds/list/:id`} element={<List />} />
                  <Route path={`/pds`} element={<Mosaic />} />
                  <Route path={`/pds/my-selection`} element={<MySelection />} />
                  <Route path={`/:category/pds`} element={<PdsSearch />} />
                  <Route path={`/:category/dok`} element={<DokSearch />} />
                  <Route
                    path={`/:category/:town/:pdsName/a-propos`}
                    element={<DetailsPds />}
                  />
                  <Route
                    path={`/:pdsName/:pdsId/a-propos`}
                    element={<DetailsPds />}
                  />
                  <Route
                    path={`/:category/:town/:pdsName`}
                    element={<MosaicPds />}
                  />
                  <Route
                    path={`/:category/:town/:pdsName/:pdsId`}
                    element={<MosaicPds />}
                  />
                  <Route
                    path={`/:category/:town/:pdsName/list/:id`}
                    element={<ListPublic />}
                  />
                  
                  <Route path={`/curation`} element={<Curation />} />
                  <Route path={`/corporate`} element={<Corporate />} />
                  <Route path={`/comingsoon`} element={<ComingSoon />} />
                  <Route
                    path={`/contributor/signup/doks/create`}
                    element={<CreateUpdateDokContributor noDirectSave="true" />}
                  />
                </Route>
                <Route path={`/`} element={<AppContibutor />}>
                  <Route
                    path={`/contributor/payment/detail/:id`}
                    element={<UpgradePack />}
                  />
                  <Route
                    path="*"
                    element={<Navigate replace to={`/contributor/doks`} />}
                  />
                  <Route
                    path={`/contributor/doks`}
                    element={<DoksContributor />}
                  />
                  <Route
                    path={`/contributor/doks/create`}
                    element={<CreateUpdateDokContributor />}
                  />
                  <Route
                    path={`/contributor/doks/update/:id`}
                    element={<CreateUpdateDokContributor />}
                  />
                  <Route
                    path={`/contributor/doks/statistic/:id`}
                    element={<StatisticDok />}
                  />
                  <Route
                    path={`/contributor/campagnes`}
                    element={<CampagnesContributor />}
                  />
                  <Route
                    path={`/contributor/campagnes/create`}
                    element={<CreateUpdateCampagneCont />}
                  />
                  <Route
                    path={`/contributor/campagnes/update/:id`}
                    element={<CreateUpdateCampagneCont />}
                  />
                  <Route
                    path={`/contributor/campagnes/statistic/:id`}
                    element={<DetailsCampagneC />}
                  />
                  <Route
                    path={`/contributor/profile`}
                    element={<ProfileContibutor />}
                  />
                  <Route
                    path={`/contributor/packs`}
                    element={<PacksContibutor />}
                  />
                  <Route
                    path={`/contributor/packs/details/:id`}
                    element={<DetailsPack />}
                  />
                  <Route
                    path={`/payment/campagne`}
                    element={<PaymentCampagne />}
                  />
                  <Route
                    path={`/contributor/upgrade-pack`}
                    element={<UpgradePack />}
                  />
                  <Route
                    path={`/contributor/notifications`}
                    element={<NotificationsContributor />}
                  />
                  <Route path={`/generate-bill`} element={<FacturePdf />} />
                  
                </Route>
              </Route>
            )}
          </Routes>
          {/*
          <Toast />*/}
        </React.Suspense>
      </Fragment>
    );
  } else app = '';
  return app;
}

export default App;
