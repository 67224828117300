import { createSlice } from '@reduxjs/toolkit';

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    pdsById: null,
    pds: { data: [] },
    resultsSearchGoogle: [],
    count: null,
    params: {
      current_page: 1,
      limit: 10,
      search: '',
      searchFiledPds: '',
      location: '',
      speciality_id: '',
      nameSpeciality: 'Catégories',
      typeSearch: 'pds',
    },
    doks: { data: [] },
    countDoks: null,
    paramsDok: {
      current_page: 1,
      limit: 10,
      search: '',
      searchFiledPds: '',
      speciality_id: '',
      nameSpeciality: 'Catégories',
      typeSearch: 'dok',
    },
    lines: [],
    columns: [],
  },
  reducers: {
    getResultSearchGoogle: (dataSet, action) => {
      const { data } = action.payload;
      dataSet.resultsSearchGoogle = data;
    },
    getDoks: (dataSet, action) => {
      const { data, params } = action.payload;
      dataSet.doks = data;
      dataSet.params = params;
    },
    getCountDoks: (dataSet, action) => {
      const { count, params } = action.payload;

      console.log(count);
      dataSet.countDoks = count;
      dataSet.params = params;
    },
    changeParamsDoks: (dataSet, action) => {
      const { params } = action.payload;
      dataSet.params = params;
    },
    getListLine: (state, action) => {
      const { data } = action.payload;
      state.lines = data.lines;
      state.columns = data.columns;
    },
    getPdsById: (dataSet, action) => {
      const { data } = action.payload;
      dataSet.pdsById = data;
    },
    getPds: (dataSet, action) => {
      const { data, params } = action.payload;
      dataSet.pds = data;
      dataSet.params = params;
    },
    getCountPds: (dataSet, action) => {
      const { count, params } = action.payload;
      dataSet.count = count;
      dataSet.params = params;
    },
    changeParams: (dataSet, action) => {
      const { params } = action.payload;
      dataSet.params = params;
    },
  },
});

export const GET_SEARCH_GOOGLE = 'search/getResultSearchGoogle';
export const GET_DOKS = 'search/getDoks';
export const GET_COUNT_DOKS = 'search/getCountDoks';
export const CHANGE_PARAMS_DOKS = 'search/changeParamsDoks';

export const GET_PDS_BY_ID = 'search/getPdsById';
export const GET_PDS = 'search/getPds';
export const GET_COUNT_PDS = 'search/getCountPds';
export const CHANGE_PARAMS = 'search/changeParams';
export const GET_LINE_PDS = 'search/getListLine';

export default searchSlice.reducer;
