import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import Input from '../../../../components/Inputs';
import toast from '../../../../utils/toast';
import Modal from 'react-bootstrap/Modal';
import { Card, Col, Form, Row } from 'react-bootstrap';
import TextArea from '../../../../components/TextArea';
import { createUpdateDok } from '../../../../actions/create-update-dok';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import SelectField from '../../../../components/Select/Select';
import { Link } from 'react-router-dom';
import { getSpecialitiesDoctolib } from '../../../../actions/admin/getSpecialitiesDoctolib';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export function FormDokAdmin({
  setOpenModal,
  data = {},
  indexList,
  index,
  listID,
  updateColumns,
  listDoks,
}) {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [switchDok, setSwitchDok] = useState(true);
  const [value, setValue] = useState(null);

  const dokValidationSchema = yup.object().shape({
    title: yup
      .string()
      .max(50, ({ max }) => 'Maximum 50 caractères')
      .required('Champ requis !'),
    url: yup.string().required('Champ requis !'),
    source: yup.string(),
    color: yup.string(),//.required('Champ requis !'),
    description: yup.string().required('Champ requis !'),
    skills: yup.array().required('Champ requis !'),
    speciality_data_source_id: yup.string().required('Champ requis'),
  });
  const dokValidationSchema1 = yup.object().shape({
    dok: yup.string().required('Champ requis !'),
  });

  const paramsExpertise = useSelector((state) => state?.admin?.paramsExpertise);
  const [start, setStart] = useState(true);
  useEffect(() => {
    if (start) {
      setStart(false);
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      getSpecialitiesDoctolib(store, { ...paramsExpertise, limit: 180 });
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
      return () => {};
    } else setStart(false);
  }, [start]);
  const specialities = useSelector(
    (state) => state?.admin?.listSpecialitiesDoctolib.data,
  );
  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      if (values.list_id === 'empty') values.list_id = '';
      if (!data?.id) values.type = 'my_selection';
      const result = await createUpdateDok(
        data?.id || null,
        values,
        store,
        indexList,
        index,
        'my_selection',
      );
      if (result?.type === 'success') {
        toast.showSuccess(store, result?.message);
        updateColumns(index, result.data);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response)
        toast.showError(
          store,
          error?.response?.data?.message ||
            'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        );
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  const onSubmitDok = async (values) => {
    let value = listDoks?.data.find((item) => item.id === values.dok);
    console.log(value);
    updateColumns(index, value);
    setOpenModal(false);
  };
  return (
    <>
      {' '}
      <>
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0">DOK</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <div className="mb-6">
                  <label className="custom-control custom-checkbox-md mb-0">
                    <input
                      onClick={(e) => setSwitchDok(true)}
                      type="checkbox"
                      className="custom-control-input"
                      name="status"
                      checked={switchDok === true}
                      value="option3"
                    />{' '}
                    <span className="custom-control-label">Dok existant</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-6">
                  <label className="custom-control custom-checkbox-md mb-0">
                    <input
                      onClick={(e) => setSwitchDok(false)}
                      type="checkbox"
                      className="custom-control-input"
                      name="status"
                      checked={switchDok === false}
                      value="option3"
                    />{' '}
                    <span className="custom-control-label">Ajouter un dok</span>
                  </label>
                </div>
              </div>
            </div>
            <br />
            {switchDok ? (
              <Formik
                className="login100-form validate-form"
                initialValues={{
                  dok: '',
                }}
                validationSchema={dokValidationSchema1}
                enableReinitialize
                //onSubmit={onSubmitDok}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  handleChange,
                  values,
                }) => (
                  <>
                    <SelectField
                      isMulti={false}
                      width="col-lg-6"
                      options={listDoks?.data || []}
                      label="Doks"
                      handleChange={(value) => {
                        setFieldValue('dok', value.id);
                        setValue(value);
                      }}
                      name="dok"
                      value={values.dok}
                      errors={errors}
                      handleSubmit={handleSubmit}
                      touched={touched}
                    />
                    <Modal.Footer>
                      <button
                        className="btn btn-danger"
                        onClick={() => setOpenModal(false)}
                        type="button"
                      >
                        Annuler
                      </button>
                      <button
                        type="submit"
                        onClick={() => {
                          updateColumns(index, value);
                          //setOpenModal(false);
                        }}
                        className={
                          isLoading
                            ? 'btn-loading btn btn-primary me-2'
                            : 'btn btn-primary me-2'
                        }
                      >
                        Ajouter
                      </button>
                    </Modal.Footer>
                  </>
                )}
              </Formik>
            ) : (
              <Formik
                className="login100-form validate-form"
                initialValues={{
                  color: '',
                  title: '',
                  speciality_data_source_id: '',
                  url: '',
                  skills: [],
                  description: '',
                  source: '',
                  status: false,
                  //column_id: columnId,
                }}
                validationSchema={dokValidationSchema}
                enableReinitialize
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  handleChange,
                  values,
                }) => (
                  <>
                    <div style={{ display: 'grid', gap: 10 }}>
                      <Input
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        width="100%"
                        value={values.url}
                        errors={errors}
                        touched={touched}
                        label="Url"
                        type="url"
                        pattern="https://.*"
                        name="url"
                        placeholder="https://."
                      />
                      <Input
                        counter={true}
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        width="100%"
                        value={values.title}
                        errors={errors}
                        touched={touched}
                        label="Titre"
                        type="text"
                        name="title"
                        placeholder="Titre"
                      />
                      <SelectField
                        isMulti={false}
                        width="col-lg-6"
                        options={specialities || []}
                        label="Spécialité"
                        handleChange={({ value }) =>
                          setFieldValue('speciality_data_source_id', value)
                        }
                        name="speciality_data_source_id"
                        value={values.speciality_data_source_id}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        touched={touched}
                      />
                      {!listID && (
                        <Row
                          style={{
                            minHeight: 30,
                          }}
                        >
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>
                                Veuillez sélectionner une couleur
                              </Form.Label>
                              <div className="row ">
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      className="colorinput-input"
                                      value="#45aaf2"
                                      checked={values.color === '#45aaf2'}
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-azure"></span>
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      className="colorinput-input"
                                      value="#6574cd"
                                      checked={values.color === '#6574cd'}
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-indigo"></span>
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#8927ec"
                                      checked={values.color === '#8927ec'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-purple"></span>
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#fc5296"
                                      checked={values.color === '#fc5296'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-pink"></span>
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#e73827"
                                      checked={values.color === '#e73827'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-red"></span>
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#fc7303"
                                      checked={values.color === '#fc7303'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-orange"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#FBB034"
                                      checked={values.color === '#FBB034'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-yellow"></span>
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#7bd235"
                                      checked={values.color === '#7bd235'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-lime"></span>
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#4ecc48"
                                      checked={values.color === '#4ecc48'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-green"></span>
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#1caf9f"
                                      checked={values.color === '#1caf9f'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-teal"></span>
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#007ea7"
                                      checked={values.color === '#007ea7'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-cyan"></span>
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#3223f1"
                                      checked={values.color === '#3223f1'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-blue"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#5a6970"
                                      checked={values.color === '#5a6970'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-gray"></span>
                                  </label>
                                </div>
                                <div className="col-auto">
                                  <label className="colorinput">
                                    <input
                                      name="color"
                                      type="radio"
                                      value="#05c3fb"
                                      checked={values.color === '#05c3fb'}
                                      className="colorinput-input"
                                      onChange={handleChange}
                                    />
                                    <span className="colorinput-color bg-secondary"></span>
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div
                                  className="col-auto"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <input
                                    name="color"
                                    type="color"
                                    value={values.color}
                                    defaultValue={values.color}
                                    onChange={handleChange}
                                  />{' '}
                                  <label
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  >
                                    Choisir une autre couleur
                                  </label>
                                </div>
                              </div>

                              {errors && errors?.color && touched?.color && (
                                <Row>
                                  <div
                                    className="bg-danger-transparent-2 text-danger"
                                    role="alert"
                                    style={{
                                      width: '100%',
                                      fontSize: '11px',
                                    }}
                                  >
                                    {errors?.color}
                                  </div>
                                </Row>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                    </div>
                    <hr />
                    <h3 className="card-title">Options de référencement</h3>
                    <div style={{ display: 'grid', gap: 10 }}>
                      <TextArea
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        width="100%"
                        value={values.description}
                        errors={errors}
                        touched={touched}
                        label="Url"
                        type="text"
                        name="description"
                        placeholder="Annoter votre description et la source du lien"
                        rows={4}
                      />
                      <Input
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        width="100%"
                        value={values.source}
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="source"
                        placeholder="Source (conseillé)"
                        label="Source (conseillé)"
                      />
                      <Input
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        width="85%"
                        value={values.skill}
                        errors={errors}
                        touched={touched}
                        label="Associer des mots-clés"
                        type="text"
                        name="skill"
                        placeholder="ex : diabète"
                        icon={
                          <button
                            onClick={() => {
                              if (
                                values.skill /*&&
                          chipData.some((elem) => elem.label === values.skill)*/
                              )
                                setFieldValue('skills', [
                                  ...values.skills,
                                  {
                                    key:
                                      values?.skills.length === 0
                                        ? 1
                                        : values.skills[
                                            values?.skills.length - 1
                                          ].key + 1,
                                    label: values.skill,
                                  },
                                ]);
                              setFieldValue('skill', '');
                            }}
                            className="btn btn-default"
                          >
                            Ajouter
                          </button>
                        }
                      />
                      {values && values.skills && values.skills.length > 0 && (
                        <Paper
                          sx={{
                            display: 'flex',
                            width: '85%',
                            justifyContent: 'flex-start',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                          }}
                          component="ul"
                        >
                          {values.skills.map((data) => {
                            return (
                              <ListItem key={data.key}>
                                <Chip
                                  label={data.label}
                                  onDelete={() =>
                                    setFieldValue(
                                      'skills',
                                      values.skills.filter(
                                        (chip) => chip.key !== data.key,
                                      ),
                                    )
                                  }
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>
                      )}
                      {errors && errors.skills && (
                        <>
                          <small
                            id="emailHelp"
                            className="form-text text-danger"
                          >
                            {errors.skills}
                          </small>
                        </>
                      )}
                    </div>
                    <Modal.Footer>
                      <button
                        className="btn btn-danger"
                        onClick={() => setOpenModal(false)}
                        type="button"
                      >
                        Annuler
                      </button>
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className={
                          isLoading
                            ? 'btn-loading btn btn-primary me-2'
                            : 'btn btn-primary me-2'
                        }
                      >
                        Enregistrer
                      </button>
                    </Modal.Footer>
                  </>
                )}
              </Formik>
            )}
          </div>
        </div>
      </>
    </>
  );
}
