import React, { useEffect } from 'react';
import BgHomeHeader from '../../../assets/img/bg-home-header.png';

const comingSoon = () => {

  return (
    <>
      
      <section>
        <div className="container mb-4 mt-4">
          <h1>
            Coming Soon
          </h1><br/>
        </div>
      </section>
    </>
  );
};

export default comingSoon;
