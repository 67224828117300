import React from 'react';

export default function DataTable({ data, headers, handleChangeSort, filter }) {
  const [sort, setSort] = React.useState(0);
  const [title, setTitle] = React.useState('');
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead className="table-light">
            <tr>
              {headers.map((header, i) => (
                <th
                  key={i}
                  onClick={() => {
                    if (header.title !== 'Action') setTitle(header.title);
                    if (sort === 1) {
                      setSort(2);
                      handleChangeSort(2, header.filed);
                    } else {
                      setSort(1);
                      handleChangeSort(1, header.filed);
                    }
                  }}
                  className={header.className}
                  style={{
                    width: header.width,
                    cursor: !filter ? 'pointer' : 'default',
                  }}
                >
                  {header.title}
                  {header.title !== 'Action' && !filter ? (
                    header.title === title ? (
                      sort === 1 ? (
                        <i
                          style={{ float: 'right' }}
                          className="fa  fa-sort-desc"
                        ></i>
                      ) : (
                        <i
                          style={{ float: 'right' }}
                          className="fa  fa-sort-asc"
                        ></i>
                      )
                    ) : (
                      <i
                        style={{ float: 'right', color: '#cecece' }}
                        className="fa  fa-sort"
                      ></i>
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{data}</tbody>
        </table>
      </div>
    </div>
  );
}
