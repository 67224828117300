import toast from '../../utils/toast';
import { apiCall } from '../../utils/helpers';

export async function createNotification(id, values, store = {}) {
  console.log(values);
  const { data } = await apiCall({
    method: id ? 'patch' : 'post',
    url: id ? `/api/notification/update/${id}` : `/api/notification/create`,
    data: values,
  });
  if (data?.type === 'success') {
    toast.showSuccess(store, data?.message);
  } else {
    const message = data?.message ? data?.message :  'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.';
    toast.showError(store, message);
  }
  return data;
}
