import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useStore } from 'react-redux';
import { LOADING_STARTED } from '../../../redux/reducers/loader';
import { getAllPacks } from '../../../actions/admin/packs/getAllPacks';
import { Col, Row } from 'react-bootstrap';
import PaymentForm from './form';
import Skeleton from '@mui/material/Skeleton';

const CommandeFacture = () => {
  const store = useStore();
  const [loading, setLoading] = useState(true);
  let { id } = useParams();
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });

    getAllPacks(store);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);
  const packs = useSelector((state) => state?.admin?.packs);
  const pack = packs.find((item) => item.id === id);
  let price;
  if (pack)
    price =
      localStorage.getItem('packType') === 'monthly'
        ? pack?.price_monthly
        : pack?.price_annual;
  if (loading) {
    return (
      <div className="container  d-flex" style={{ marginTop: 30 }}>
        <div className="w-100 mx-auto">
          <div className="card" style={{ alignItems: 'center' }}>
            <Skeleton
              animation="wave"
              height={20}
              width="20%"
              style={{ marginBottom: 6 }}
            />
            <div style={{ width: '55%' }} className="panel panel-primary">
              <div className="form-group">
                <Row>
                  <Col md={6}>
                    {' '}
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />
                  </Col>
                  <Col md={6}>
                    {' '}
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {' '}
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />
                  </Col>
                  <Col md={6}>
                    {' '}
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="100%"
                      style={{ marginBottom: 6 }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container d-flex" style={{ marginTop: 30 }}>
      <div className="w-100 mx-auto">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0"> Commande</h5>
          </div>
          <div className="card-body">
            <Row>
              <PaymentForm
                pack={localStorage.getItem('packType')}
                type="pack"
                amount={price}
                selected={pack}
                setModalPayment={() => console.log()}
              />
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommandeFacture;
