import toast from '../utils/toast';
import { GET_LIST_ACTIVE_SPECIALITIES } from '../redux/reducers/speciality';
import axios from 'axios';

export async function getActiveSpecialities(store) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/api/admin/data/active-specialities`,
    );
    if (data?.type === 'success') {
      store.dispatch({
        type: GET_LIST_ACTIVE_SPECIALITIES,
        payload: {
          list: data.data,
        },
      });
    } else {
      const message = data?.message
        ? data?.message
        : 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.';
      toast.showError(store, message);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}
