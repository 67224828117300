import React, { useState, useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import toast from '../../../utils/toast';
import ModalComponent from '../../../components/Modal/Modal';
import 'leaflet/dist/leaflet.css';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { getALlNotificationsUser } from '../../../actions/getALlNotificationsUser';
import Notification from '../../pds/Components/notification';
import Skeleton from '@mui/material/Skeleton';
import { deleteNotification } from '../../../actions/delete-notification';
import PaginatedItems from '../../../components/Paginations';
import scrollTo from 'gatsby-plugin-smoothscroll';

const NotificationsContributor = () => {
  let navigate = useNavigate();
  const store = useStore();
  const [switchTab, setSwitchTab] = useState('all');
  const [isLoading, setIsLoading] = useState(false);

  const [index, setIndex] = useState(false);
  const [modalDeleteNotif, setModalDeleteNotif] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingTab, setLoadingTab] = useState(false);
  const paramsNotifications = useSelector(
    (state) => state?.pds?.paramsNotifications,
  );
  const [modalPageDok, setModalPageDok] = useState(false);
  const [dataDok, setDataDok] = useState(null);
  const [dataNotif, setDataNotif] = useState(null);
  const [modalSowDescriptionDok, setModalSowDescriptionDok] = useState(false);

  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getALlNotificationsUser(store, {
      ...paramsNotifications,
      current_page: page || 1,
    });
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => {};
  }, [store, loading]);

  const notifications = useSelector((state) => state?.pds?.notifications);

  const totalPage = notifications?.total / 10;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [rowsCount, setRowsCount] = useState(10);
  const [page, setPage] = React.useState(
    parseInt(localStorage.getItem('pageNotifPds')),
  );
  const onSubmitDeleteNotif = async (id, index) => {
    try {
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      setIsLoading(true);
      const result = await deleteNotification(id || null, index, store);
      if (result?.type === 'success') {
        setIsLoading(false);
        setModalDeleteNotif(false);
        toast.showSuccess(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response)
        toast.showError(
          store,
          error?.response?.data?.message ||
            'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        );
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  const handlePageClick = async (e) => {
    setLoading(true);
    const selectedPage = e.selected;
    const nextSelectedPage = e.nextSelectedPage;
    if (nextSelectedPage || nextSelectedPage === 0) {
      const offset = selectedPage * rowsPerPage;
      setOffset(offset);
      setRowsCount(rowsCount + 10);
      const newParams = {
        ...paramsNotifications,
        limit: 10,
        current_page: nextSelectedPage + 1,
      };
      setPage(nextSelectedPage + 1);
      await getALlNotificationsUser(store, newParams);
      localStorage.setItem('pageNotifPds', nextSelectedPage + 1);
      navigate(`/pds/notifications?page=${nextSelectedPage + 1}`);
    }
    scrollTo('#pds-data');
    setLoading(false);
  };
  if (loading)
    return (
      <>
        {' '}
        <div className="page-content w-100">
          <div className="card shadow-sm border-0 p-2">
            <div className="d-flex">
              <div className="col-right">
                <Skeleton
                  animation="wave"
                  height={10}
                  width="80%"
                  style={{ marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={10}
                  width="80%"
                  style={{ marginBottom: 6 }}
                />
              </div>
              <div className="col-left">
                <div className="d-flex flex-row align-items-lg-center card p-4 mb-4 result">
                  <div>
                    <Skeleton animation="wave" width={40} height={60} />
                  </div>
                  <div style={{ width: '100%' }} className="ms-4">
                    <h6 className="text-muted">
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                    </h6>
                    <p className="mt-3 mb-0">
                      <Skeleton animation="wave" height={10} width="40%" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <div className="page-content w-100">
      <div className="card shadow-sm border-0 p-2">
        <div className="card-body">
          <div className="d-flex">
            <div className="col-right">
              <div
                style={{
                  display: 'inline-flex',
                  gap: '11px',
                }}
                className="MuiBox-root css-ju13j4"
              >
                <h3 className="MuiTypography-root MuiTypography-body2 css-agj3ga">
                  Notifications
                </h3>
              </div>
            </div>
            <div className="col-left">
              <div style={{ display: 'flex', gap: 13 }}>
                <button
                  style={{
                    backgroundColor: switchTab === 'all' ? '' : 'white',
                    color: switchTab === 'all' ? '' : 'black',
                  }}
                  onClick={() => {
                    localStorage.setItem('pageNotifPds', 1);
                    setPage(1);
                    setLoadingTab(true);
                    setSwitchTab('all');
                    setLoadingTab(false);
                    getALlNotificationsUser(store, {
                      ...paramsNotifications,
                      is_seen: '',
                      current_page: 1,
                    });
                    navigate(`/contributor/notifications?page=1`);
                  }}
                  className="btn btn-primary "
                >
                  Tous
                </button>
                <button
                  onClick={() => {
                    localStorage.setItem('pageNotifPds', 1);
                    setPage(1);
                    setLoadingTab(true);
                    setSwitchTab('notSeen');
                    setLoadingTab(false);
                    getALlNotificationsUser(store, {
                      ...paramsNotifications,
                      is_seen: true,
                      current_page: 1,
                    });
                    navigate(`/contributor/notifications?page=1`);
                  }}
                  style={{
                    backgroundColor: switchTab === 'notSeen' ? '' : 'white',
                    color: switchTab === 'notSeen' ? '' : 'black',
                  }}
                  className="btn btn-primary "
                >
                  Non Lus
                </button>
              </div>
              <br />
              {notifications && notifications.data
                ? notifications.data.map((notif, index) => (
                    <Notification
                      setDataDok={setDataDok}
                      setModalPageDok={setModalPageDok}
                      setModalSowDescriptionDok={setModalSowDescriptionDok}
                      setDataNotif={setDataNotif}
                      setModalDeleteNotif={setModalDeleteNotif}
                      modalDeleteNotif={modalDeleteNotif}
                      setIndex={setIndex}
                      notification={notif}
                      index={index}
                    />
                  ))
                : ''}
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-end">
              <nav style={{ margin: 0 }}>
                {notifications?.total > 10 && (
                  <PaginatedItems
                    className="mb-0"
                    handlePageClick={handlePageClick}
                    items={notifications?.data}
                    itemsPerPage={10}
                    initialPage={page}
                    totalPage={Math.ceil(totalPage)}
                  />
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        body={
          <span
            style={{ whiteSpace: 'pre-wrap', fontSize: 17, fontWeight: 400 }}
          >
            {dataNotif?.message}
          </span>
        }
        title="Dokatis vous informe"
        edge="end"
        size="lg"
        showFooter={true}
        onHide={() => {
          setModalSowDescriptionDok(false);
        }}
        show={modalSowDescriptionDok}
      />
      <ModalComponent
        body="Voulez-vous supprimé cette notification"
        title="Supprimer Notification"
        show={modalDeleteNotif}
        onHide={() => setModalDeleteNotif(false)}
        footer={
          <button
            onClick={() => onSubmitDeleteNotif(dataNotif?.id, index)}
            className={
              isLoading
                ? 'btn-loading btn btn-primary me-2'
                : 'btn btn-primary me-2'
            }
            type="button"
          >
            Supprimer
          </button>
        }
        size="lg"
      />
    </div>
  );
};

export default NotificationsContributor;
