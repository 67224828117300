import React, { useEffect, useState } from 'react';
import DataTable from '../../../components/DataTable';
import { LOADING_STARTED } from '../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import PaginatedItems from '../../../components/Paginations';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Link, useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { getAllBills } from '../../../actions/admin/getAllBills';
import moment from 'moment';

export default function Bills() {
  const store = useStore();
  const navigate = useNavigate();
  const [modalDelete, setModalDelete] = useState(false);
  const [index, setIndex] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const [page, setPage] = React.useState(
    parseInt(localStorage.getItem('pageBill')),
  );

  const [search, setSearch] = React.useState(
    localStorage.getItem('searchBill'),
  );
  const params = useSelector((state) => state?.admin?.paramsBillms);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getAllBills(store, {
      ...params,
      current_page: page || 1,
      search: search || '',
    });
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);

  const listBills = useSelector((state) => state?.admin?.listBills);
  const totalPage = listBills?.total / 10;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [rowsCount, setRowsCount] = useState(10);
  console.log(listBills);
  const handlePageClick = async (e) => {
    setLoading(true);
    const selectedPage = e.selected;
    const nextSelectedPage = e.nextSelectedPage;
    if (nextSelectedPage || nextSelectedPage === 0) {
      const offset = selectedPage * rowsPerPage;
      setPage(selectedPage);
      setOffset(offset);
      setRowsCount(rowsCount + 10);
      const newParams = {
        ...params,
        limit: 10,
        current_page: nextSelectedPage + 1,
      };
      await getAllBills(store, newParams);
      localStorage.setItem('pageBill', nextSelectedPage + 1);
      if (localStorage.getItem('searchBill')) {
        navigate(
          `/admin/sales?page=${
            nextSelectedPage + 1
          }&search=${localStorage.getItem('searchBill')}`,
        );
      }
      if (!localStorage.getItem('searchBill'))
        navigate(`/admin/sales?page=${nextSelectedPage + 1}`);
    }
    scrollTo('#pds-data');
    setLoading(false);
  };
  const handleChangeSearch = async (value) => {
    setLoading(true);
    const newParams = {
      ...params,
      search: value,
      current_page: 1,
    };
    setSearch(value);
    await getAllBills(store, newParams);
    localStorage.setItem('searchBill', value);
    if (localStorage.getItem('pageBill')) {
      navigate(`/admin/sales?page=${1}&search=${value}`);
    }
    if (!localStorage.getItem('pageBill'))
      navigate(`/admin/sales?search=${value}`);
    setLoading(false);
  };
  const handleChangeSort = async (order, field) => {
    setLoading(true);
    localStorage.setItem('order', order);
    localStorage.setItem('field', field);
    const newParams = {
      ...params,
      order,
      field,
    };

    await getAllBills(store, newParams);
    setLoading(false);
  };
  console.log(listBills);

  const headers = [
    {
      title: 'Titre',
      filed: `"pack"."title"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50 sorting_desc',
    },
    {
      title: 'Contributeur',
      filed: `"user->contributor"."society"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50',
    },
    {
      title: 'Date',
      filed: `"created_at"`,
      width: 50,
      className: 'text-uppercase fw-semibold text-center text-black-50',
    },
    {
      title: 'Action',
      width: 65,
      className: 'text-uppercase fw-semibold text-center text-black-50',
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="card shadow-sm border-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div>
              <input
                onChange={(e) => handleChangeSearch(e.target.value)}
                value={search}
                type="text"
                className="form-control"
                placeholder="Filtrer par mot clé ..."
              />
            </div>
          </div>
          <DataTable
            headers={headers}
            handleChangeSort={handleChangeSort}
            data={getColumns(
              listBills?.data,
              setModalDelete,
              setIndex,
              setSelectedId,
              loading,
            )}
          />
          <div>
            <div className="d-flex justify-content-end">
              <nav style={{ margin: 0 }}>
                {listBills?.total > 10 && (
                  <PaginatedItems
                    className="mb-0"
                    handlePageClick={handlePageClick}
                    items={listBills?.data}
                    itemsPerPage={10}
                    initialPage={page}
                    totalPage={totalPage}
                  />
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const getColumns = (
  data,
  setModalDelete,
  setIndex,
  setSelectedId,
  isLoading,
) => {
  return (
    <>
      {isLoading ? (
        <>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton
                animation="wave"
                variant="circular"
                width={10}
                height={10}
              />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton
                animation="wave"
                variant="circular"
                width={10}
                height={10}
              />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
        </>
      ) : data && data.length > 0 ? (
        data.map((item, index) => (
          <tr>
            <td>
              {item?.pack ? item?.pack?.title : item?.campagne?.document?.title}
              <br />
            </td>
            <td>
              {<span>{item?.user?.contributor?.society || '-'}</span>}
              <br />
            </td>
            <td className="text-center">
              {moment(item.date).format('DD/MM/YYYY')}
            </td>
            <td className="text-center">
              <Link
                to={`/admin/sales/details/${item.id}`}
                className="btn btn-primary btn-sm btn-action"
                href="#"
              >
                <i className="far fa-eye"></i>
              </Link>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="text-center" colSpan={4}>
            Vous n'avez pas encore de factures
          </td>
        </tr>
      )}
    </>
  );
};
