import React, { useEffect, useState } from 'react';
import DataTable from '../../../components/DataTable';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import PaginatedItems from '../../../components/Paginations';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Link, useNavigate } from 'react-router-dom';
import { getActiveSpecialities } from '../../../actions/getActiveSpecialities';
import ModalComponent from '../../../components/Modal/Modal';
import toast from '../../../utils/toast';
import { GET_PDS_BY_ID } from '../../../redux/reducers/search';
import Skeleton from '@mui/material/Skeleton';
import { getAllCampagnes } from '../../../actions/admin/getAllCampagnes';
import { deleteCampagne } from '../../../actions/admin/delete-campagne';

export default function Campagnes() {
  const store = useStore();
  const navigate = useNavigate();
  const [modalDelete, setModalDelete] = useState(false);
  const [index, setIndex] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = React.useState(
    parseInt(localStorage.getItem('pageCampagne')),
  );
  const [status, setStatus] = React.useState(
    localStorage.getItem('statusCampagne'),
  );
  const [search, setSearch] = React.useState(
    localStorage.getItem('searchCampagne'),
  );
  const params = useSelector((state) => state?.admin?.paramsCampagnes);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getAllCampagnes(store, {
      ...params,
      current_page: page || 1,
      search: search || '',
      status: status || '',
    });
    getActiveSpecialities(store);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);
  const rows = useSelector((state) => state?.speciality?.activeSpecialities);
  const listCampagnes = useSelector((state) => state?.admin?.listCampagnes);
  const totalPage = listCampagnes?.total / 10;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [rowsCount, setRowsCount] = useState(10);
  console.log(listCampagnes);
  const handlePageClick = async (e) => {
    setLoading(true);
    const selectedPage = e.selected;
    const nextSelectedPage = e.nextSelectedPage;
    if (nextSelectedPage || nextSelectedPage === 0) {
      const offset = selectedPage * rowsPerPage;
      setPage(selectedPage);
      setOffset(offset);
      setRowsCount(rowsCount + 10);
      const newParams = {
        ...params,
        limit: 10,
        current_page: nextSelectedPage + 1,
      };
      await getAllCampagnes(store, newParams);
      localStorage.setItem('pageCampagne', nextSelectedPage + 1);
      if (localStorage.getItem('searchCampagne')) {
        navigate(
          `/admin/campagnes?page=${
            nextSelectedPage + 1
          }&search=${localStorage.getItem('searchCampagne')}`,
        );
      }
      if (
        !localStorage.getItem('searchCampagne') &&
        !localStorage.getItem('statusCampagne')
      )
        navigate(`/admin/campagnes?page=${nextSelectedPage + 1}`);
    }
    scrollTo('#pds-data');
    setLoading(false);
  };
  const handleChangeSearch = async (value) => {
    setLoading(true);
    const newParams = {
      ...params,
      search: value,
      current_page: 1,
    };
    setSearch(value);
    await getAllCampagnes(store, newParams);
    localStorage.setItem('searchCampagne', value);
    if (localStorage.getItem('pageCampagne')) {
      navigate(`/admin/campagnes?page=${1}&search=${value}`);
    }
    if (
      !localStorage.getItem('pageCampagne') &&
      !localStorage.getItem('statusCampagne')
    )
      navigate(`/admin/campagnes?search=${value}`);
    setLoading(false);
  };
  const handleChangeSort = async (order, field) => {
    setLoading(true);
    localStorage.setItem('order', order);
    localStorage.setItem('field', field);
    const newParams = {
      ...params,
      order,
      field,
    };

    await getAllCampagnes(store, newParams);
    setLoading(false);
  };
  const handleChangeStatus = async (value) => {
    setLoading(true);
    const newParams = {
      ...params,
      status: value,
      current_page: 1,
    };
    setStatus(value);
    await getAllCampagnes(store, newParams);
    localStorage.setItem('statusCampagne', value);
    if (localStorage.getItem('statusCampagne')) {
      navigate(`/admin/campagnes?page=${1}&status=${value}`);
    }
    if (localStorage.getItem('statusCampagne')) {
      navigate(
        `/admin/campagnes?search=${localStorage.getItem(
          'searchCampagne',
        )}&status=${value}`,
      );
    }
    if (
      !localStorage.getItem('page') &&
      !localStorage.getItem('searchCampagne')
    )
      navigate(`/admin/campagnes?status=${value}`);
    setLoading(false);
  };
  console.log(listCampagnes);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmitDeletePds = async (id, indexDok) => {
    try {
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      setIsLoading(true);
      const result = await deleteCampagne(id || null, indexDok, store);
      if (result?.type === 'success') {
        setIsLoading(false);
        setModalDelete(false);
        toast.showSuccess(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response)
        toast.showError(
          store,
          error?.response?.data?.message ||
            'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        );
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  const headers = [
    {
      title: 'Dok',
      filed: `"document"."skills"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50 sorting_desc',
    },
    {
      title: 'Spécialité',
      filed: `"speciality_data_source"."title"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50',
    },
    {
      title: 'Status',
      filed: `"status"`,
      width: 50,
      className: 'text-uppercase fw-semibold text-center text-black-50',
    },
    {
      title: 'Action',
      width: 65,
      className: 'text-uppercase fw-semibold text-center text-black-50',
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="card shadow-sm border-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            {/*<Link
              onClick={() =>
                store.dispatch({
                  type: GET_PDS_BY_ID,
                  payload: {
                    data: null,
                  },
                })
              }
              className="link-light btn btn-lg btn-primary"
              to="/admin/campagnes/create"
            >
              <i className="fas fa-plus-circle me-2"></i>Créer une Campagne
            </Link>*/}
            <div>
              <select
                onChange={(e) => handleChangeStatus(e.target.value)}
                value={status}
                className="form-control form-select w-auto"
              >
                <option value={true}>Actif</option>
                <option value={false}>inActif</option>
              </select>
            </div>
            <div>
              <input
                onChange={(e) => handleChangeSearch(e.target.value)}
                value={search}
                type="text"
                className="form-control"
                placeholder="Filtrer par mot clé ..."
              />
            </div>
          </div>
          <DataTable
            headers={headers}
            handleChangeSort={handleChangeSort}
            data={getColumns(
              listCampagnes?.data,
              setModalDelete,
              setIndex,
              setSelectedId,
              loading,
            )}
          />
          <div>
            <div className="d-flex justify-content-end">
              <nav style={{ margin: 0 }}>
                {listCampagnes?.total > 10 && (
                  <PaginatedItems
                    className="mb-0"
                    handlePageClick={handlePageClick}
                    items={listCampagnes?.data}
                    itemsPerPage={10}
                    initialPage={page}
                    totalPage={totalPage}
                  />
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        body="Voulez-vous vraiment supprimer cette Campagne"
        title="Supprimer Campagne"
        show={modalDelete}
        onHide={() => setModalDelete(false)}
        footer={
          <button
            style={{
              backgroundColor: '#00B0F0',
              borderColor: '#00B0F0',
              color: 'white',
            }}
            onClick={() => onSubmitDeletePds(selectedId, index)}
            className="btn btn-primary me-2"
            type="button"
          >
            Supprimer
          </button>
        }
        size="md"
      />
    </div>
  );
}

const getColumns = (
  data,
  setModalDelete,
  setIndex,
  setSelectedId,
  isLoading,
) => {
  return (
    <>
      {isLoading ? (
        <>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton
                animation="wave"
                variant="circular"
                width={10}
                height={10}
              />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton
                animation="wave"
                variant="circular"
                width={10}
                height={10}
              />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
        </>
      ) : data && data.length > 0 ? (
        data.map((item, index) => (
          <tr>
            <td>
              {item.document.title}
              <br />
            </td>
            <td>
              {<span>{item?.speciality_data_source?.label || '-'}</span>}
              <br />
            </td>
            <td className="text-center">
              {item.status ? (
                <svg height="14" width="14">
                  <circle cx="6" cy="6" r="6" fill="#00949E"></circle>
                </svg>
              ) : (
                <svg height="14" width="14">
                  <circle cx="6" cy="6" r="6" fill="#E61610"></circle>
                </svg>
              )}
            </td>
            <td className="text-center">
              <Link
                to={`/admin/campagnes/details/${item.id}`}
                className="btn btn-primary"
                href="#"
              >
                Consulter
              </Link>

              {/*<Link
                to={`/admin/campagnes/update/${item.id}`}
                className="btn btn-primary btn-sm btn-action"
                href="#"
              >
                <i className="fas fa-pen"></i>
              </Link>*/}
              {/* <button
                onClick={() => {
                  setIndex(index);
                  setSelectedId(item.id);
                  setModalDelete(true);
                }}
                className="btn btn-primary btn-sm btn-action"
              >
                <i className="fas fa-times"></i>
              </button>*/}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="text-center" colSpan={4}>
            Vous n'avez pas encore de campagnes
          </td>
        </tr>
      )}
    </>
  );
};
