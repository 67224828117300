import React, { useEffect } from 'react';
import BgHomeHeader from '../../../assets/img/bg-home-header.png';
import IconKine from '../../../assets/template/img/logo-dokatis.png';
import ImageGruet from '../../../assets/images/gruet.jpg';
import ImagePhilipe from '../../../assets/images/philipe.jpg';
import ContactForm from './ContactForm';

const Corporate = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return (
    <>
      
      <section>
        <div className="container mb-4 mt-4">
          <h1>
          A propos de nous

          </h1><br/>
          <u id="pourquoi-dokatis">Pourquoi Dokatis?</u>
          <p>
          <b>Réunir le meilleur de l'information santé.</b> <br />
          En matière de santé, le volume d'informations disponibles peut être colossal. Comment savoir ce qui est fiable et utile? Dokatis rassemble des contenus vérifiés par les praticiens. Nous partageons la conviction qu'ils sont les mieux placés pour guider chacun vers une santé meilleure. C'est pourquoi nous avons repensé les interactions avec eux. Prévention, prise en charge et suivi des patients sont au cœur de notre démarche. Dokatis combine les avantages de la curation et la puissance de l'intelligence artificielle.
          <br /><br/>
          <b>Partager pour être utile.</b> <br/>
          La plate-forme de curation dédiée aux professionnels de santé. <br />
          <br/>
           
          </p>
          <u id="qui-sommes-nous">Qui sommes-nous?</u>
          <br/>
          <b>Des connexions pour avancer. </b>
          <div class="mx-4">
          <div class="mx-2"> 
                  <img style={{ width: 160, height: 160 }} src={ImageGruet} /><br/>
            <a href="https://www.linkedin.com/in/ludovic-gruet-99b5223b/" target="_blank">Ludovic Gruet</a>
            </div>
            <div class="mx-2">
              
            <img style={{ width: 160, height: 160 }} src={ImagePhilipe} /><br/>
            <a href="https://www.linkedin.com/in/philipperacine/" target="_blank">Philippe Racine</a>
            </div>
            </div>
          <br /><br/>
          <p><b>Des idées qui nous rassemblent.</b>
          <br /><br/>
          Oser de nouvelles voies.<br />
          L'expérience des patients est plus large que la consultation. C'est un parcours qui commence avant même de franchir les portes du cabinet et qui se poursuit bien après. Il existe un potentiel d'innovations considérable pour l'optimiser.
          <br /><br/>
          Créer des liens de confiance.<br />
          Les dernières technologies ont ouvert la création de contenus au plus grand nombre. Plus que jamais, la pertinence de l'information repose sur le crédit que nous portons à celui qui l'émet. Pour nous, croiser les avis d'experts et se référer à celui auquel on fait confiance est la clé.
          <br /><br/>
          L'avenir se construit ensemble.<br />
          La santé est un besoin universel. L'enjeu est de créer un environnement ouvert et inclusif où chacun peut contribuer à l'amélioration du bien-être collectif. C'est pourquoi nous cultivons un esprit de collaboration et facilitons les échanges entre les professionnels de santé et les autres acteurs.
          <br /><br/>
          Vos libertés. Nos responsabilités.<br />
          Chez Dokatis, la liberté de choix et d'information va de pair avec le respect de la vie privée. Nous participons activement à la construction d'un monde digital responsable.
          <br />
          </p>
          <u id="nous-contacter">Nous contacter</u>
          <p>
            <b>On s'occupe de vous dès maintenant.</b>
            <br />
            <br />
            <ContactForm />
          </p>
          
        </div>
      </section>
    </>
  );
};

export default Corporate;
