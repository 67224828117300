import React from 'react';
import { Link } from 'react-router-dom';

const Dok = (props) => {
  const {
    dok,
    updateColumns,
    col,
    columnId,
    index,
    type,
    hideBtnMore,
    showBtn,
  } = props;

  return (
    <div key={columnId} className={col}>
      <div style={{ height: '10em' }} className="card mb-3 shadow">
        <div
          className="card-header d-flex justify-content-between align-items-center"
          style={{
            zIndex: 'var(--bs-backdrop-zindex)',
            backgroundColor:
              (dok?.lists && dok?.lists.length > 0 && dok?.lists[0]?.color) ||
              dok?.color ||
              'rgb(252, 115, 3)',
            padding: 7,
            cursor: type === 'my_selection' || hideBtnMore ? '0' : 'all-scroll',
          }}
        >
          {' '}
          <div className="media" style={{ lineHeight: 1, cursor: 'pointer' }}>
            {showBtn ? (
              ''
            ) : (
              <span
                style={{ color: 'white' }}
                onClick={() => {
                  updateColumns(index, null);
                }}
              >
                X
              </span>
            )}
          </div>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          className="card-body d-flex align-items-center"
        >
          <p
            className="card-text"
            style={{ fontSize: '13px', fontFamily: 'Open Sans' }}
          >
            {dok?.title}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dok;
