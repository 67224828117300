import React, { useEffect, useState } from 'react';
import Dok from './Components/dok';
import Empty from './Components/empty';
import { useSelector, useStore } from 'react-redux';
import { GridContextProvider, GridDropZone, GridItem } from 'react-grid-dnd';
import { isMobile } from 'react-device-detect';
import SkeletonDok from '../../pds/Components/dokSkeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import SelectField from '../../../components/Select/Select';
import { getStarterKitById } from '../../../actions/admin/starter-kit/getStarterKitById';
import { Formik } from 'formik';
import FormModal from '../../../components/Modal/FormModal';
import { FormDokAdmin } from './Components/formDok';
import { Col, Row } from 'react-bootstrap';
import { formatAmount } from '../../../utils/helpers';

const DetailsStarterKit = () => {
  const store = useStore();
  let { id } = useParams();
  let navigate = useNavigate();
  const [columnId, setColumnId] = useState('');
  const [modalAddList, setModalAddList] = useState(false);
  const [dataList, setDataList] = useState(null);
  const [indexList, setIndexList] = useState(null);
  const [indexDok, setIndexDok] = useState(null);
  const [modalAddDok, setModalAddDok] = useState(false);
  const [dataDok, setDataDok] = useState(null);
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState(true);
  useEffect(() => {
    if (start) {
      setStart(false);
      getStarterKitById(id, store);
    } else setStart(false);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store, loading]);

  const columns = useSelector((state) => state?.starterKit?.columns);
  const starterKit = useSelector((state) => state?.starterKit?.starterKit);

  if (loading)
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            {' '}
            <Skeleton animation="wave" height={20} width="20%" />
          </div>
          <div className="card-body">
            <Skeleton animation="wave" height={20} width="20%" />
            <Skeleton animation="wave" height={20} width="20%" />
            <div className="row">
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
              <div className="col-md-3 col-sm-3">
                <SkeletonDok />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0">Détail starter kit</h5>
          </div>
          <div className="card-body">
            {starterKit?.starterKit?.speciality_data_source && (
              <div className="mb-3">
                <Row style={{ minHeight: 5 }}>
                  <Col md={2}>
                    <div className="d-flex align-items-center mb-3">
                      <h5 className="mb-0">
                        Spécialité
                        <br />
                      </h5>
                    </div>
                  </Col>
                  <Col md={10}>
                    <p className="mb-0">
                      {starterKit?.starterKit?.speciality_data_source?.title}
                    </p>
                  </Col>
                </Row>
              </div>
            )}
            <div className="d-flex align-items-center mb-3">
              <h5 className="mb-0">
                Doks
                <br />
              </h5>
            </div>
            <div
              id="items"
              boxesPerRow={isMobile ? 1 : 4}
              rowHeight={175}
              className="row"
              style={{
                height: isMobile
                  ? `${columns.length * 175}px`
                  : `${(columns.length / 4) * 175}px`,
              }}
            >
              {columns.length > 0 &&
                columns.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      id={index}
                      className={
                        isMobile ? 'col-md-12 col-sm-12' : 'col-md-3 col-sm-3'
                      }
                      style={{
                        //zIndex: 10,
                        opacity: 'inherit',
                        width: -1,
                        height: -1,
                        boxSizing: -1,
                      }}
                    >
                      {item.type === 'empty' ? (
                        <Empty
                          columnId={item.id}
                          setColumnId={setColumnId}
                          setDataList={setDataList}
                          setIndexList={setIndexList}
                          setIndexDok={setIndexDok}
                          setOpenModalDok={setModalAddDok}
                          setDataDok={setDataDok}
                          setModalAddList={setModalAddList}
                          index={index}
                          showBtn={true}
                        />
                      ) : (
                        <Dok
                          //setColumns={setColumns}
                          columns={columns}
                          dok={item.document}
                          index={index}
                          columnId={item.id}
                          col="col-md-12 col-sm-12"
                          showBtn={true}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
            <div className="card-footer d-flex justify-content-between align-items-center">
              <div>
                <Link to="/admin/starter-kit">
                  <i className="fas fa-reply-all me-2"></i>Retour à la liste
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default DetailsStarterKit;
