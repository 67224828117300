import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { LOADING_STARTED } from '../../../redux/reducers/loader';
import { Col, Row } from 'react-bootstrap';
import PaymentForm from '../payment/form';
import Skeleton from '@mui/material/Skeleton';
import { useLocation } from 'react-router-dom';
import { formatAmount } from '../../../utils/helpers';
import { createUpdateCampagne } from '../../../actions/admin/create-update-campagne';

const PaymentCampagne = () => {
  const store = useStore();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  let { values, nameDok } = location.state;
  const payment = useSelector((state) => state.contributor.payment);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (values) => {
    setIsLoading(true);
    values.price = formatAmount(
      payment.searchCount +
      payment.lateralBarCount +
      payment.notificationCount,
    );
    const result = await createUpdateCampagne(null, values, store);
    if (result.type === 'success') {
      return result?.data;
    } else {
      return false;
    }
  };
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);

  if (loading) {
    return (
      <div className="page-content w-100">
        <div className="card shadow-sm border-0 p-2">
          <div style={{ backgroundColor: '#ffffff' }} className="card-header">
            <span className="fs-4 fw-semibold text-center mb-4 mt-2">
              <Skeleton
                animation="wave"
                height={20}
                width="20%"
                style={{ marginBottom: 6 }}
              />
            </span>
          </div>
          <div className="card">
            <div className="form-group">
              <Row>
                <Col md={6}>
                  {' '}
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 6 }}
                  />
                </Col>
                <Col md={6}>
                  {' '}
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 6 }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {' '}
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 6 }}
                  />
                </Col>
                <Col md={6}>
                  {' '}
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 6 }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="page-content w-100">
      <div className="card admin-card shadow-sm border-0">
        <div className="card-header">
          <h5 className="mb-0">Commande</h5>
        </div>
        <div className="card-body">
          <PaymentForm
            pack=""
            type="campagne"
            amount={parseFloat(values.price).toFixed(2)}
            nameDok={nameDok}
            initialValues={values}
            setModalPayment={() => console.log()}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentCampagne;
