import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useSelector, useStore } from 'react-redux';
import toast from '../../../utils/toast';
import * as yup from 'yup';
import SelectField from '../../../components/Select/Select';
import SkeletonCreateUpdatePds from './Components/skeletonCreateUpdatePds';
import { getCampagne } from '../../../actions/admin/getCampagne';
import { getDoksByUser } from '../../../actions/getDoksByUser';
import { getActiveSpecialities } from '../../../actions/getActiveSpecialities';
import { PAYMENT } from '../../../redux/reducers/contributor';
import { getConfig } from '../../../actions/admin/config/getConfig';
import { formatAmount, hasRole } from '../../../utils/helpers';

const CreateUpdateCampagneCont = () => {
  let navigate = useNavigate();
  const store = useStore();
  const [nameDok, setNameDok] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [countPsdBySpeciality, setCountPsdBySpeciality] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const userProfile = useSelector((state) => state.auth.userProfile);
  const paramsExpertise = useSelector((state) => state?.admin?.paramsExpertise);
  let { id } = useParams();
  useEffect(() => {
    getConfig(store);
    getActiveSpecialities(store, { ...paramsExpertise, limit: 300 });
    getDoksByUser(store);
    return () => {};
  }, [store]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: PAYMENT,
      payload: {
        payment: {
          notificationCount: 0,
          searchCount: 0,
          lateralBarCount: 0,
        },
      },
    });
    if (id) {
      getCampagne(id, store);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => {};
  }, [store, loading]);

  const listDoksByUser = useSelector((state) => state?.admin?.listDoksByUser);
  const campagne = useSelector((state) => state?.admin?.campagne);
  const setting = useSelector((state) => state.admin.setting);
  console.log(setting);

  const openModal = async (values) => {
    //setModalPayment(true);
    if (!id)
      values.price = formatAmount(
        payment.searchCount +
          payment.lateralBarCount +
          payment.notificationCount,
      );
    values.sidebar_qt = values.sidebar_qt ? values.sidebar_qt : 0;
    values.notification_qt = values?.notification_qt
      ? values?.notification_qt
      : 0;
    values.in_search_qt = values?.in_search_qt ? values?.in_search_qt : 0;
    setInitialValues(values);
    navigate('/payment/campagne', { state: { values, nameDok } });
  };
  const createUpdateCampagneValidationSchema = yup.object({
    speciality_data_source_id: yup.string().required('Champ requis'),
    dok_id: yup.string().required('Champ requis'),
    in_search_qt: yup.number(),
    sidebar_qt: yup.number(),
    notification_qt: yup.number(),
    in_search: yup.boolean(),
    //in_search: yup.boolean(),
    sidebar: yup.boolean(),
    notification: yup.boolean(),
    /*starter_kit: yup.boolean(),
    end_date: yup.date().required('Champ requis'),
    start_date: yup.date().required('Champ requis'),*/
  });
  const specialities = useSelector(
    (state) => state?.speciality?.activeSpecialities,
  );
  const payment = useSelector((state) => state.contributor.payment);

  if (loading)
    return (
      <div className="page-content w-100">
        <div className="card shadow-sm border-0 p-2">
          <SkeletonCreateUpdatePds />
        </div>
      </div>
    );
  else
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0">Créer une campagne</h5>
          </div>
          <Formik
            className="login100-form validate-form"
            initialValues={{
              speciality_data_source_id:
                campagne?.speciality_data_source?.id || '',
              /*start_date:
                  moment(campagne?.start_date).format('YYYY-MM-DD') || '',
                end_date: moment(campagne?.end_date).format('YYYY-MM-DD') || '',
                starter_kit: campagne?.starter_kit || false,*/
              notification: campagne?.notification || false,
              sidebar: campagne?.sidebar || false,
              in_search: campagne?.in_search || false,
              sidebar_qt: campagne?.sidebar_qt ? campagne?.sidebar_qt : '',
              notification_qt: campagne?.notification_qt
                ? campagne?.notification_qt
                : '',
              in_search_qt: campagne?.in_search_qt
                ? campagne?.in_search_qt
                : '',
              dok_id: campagne?.document.id || '',
            }}
            validationSchema={createUpdateCampagneValidationSchema}
            enableReinitialize
            onSubmit={openModal}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              handleChange,
              values,
            }) => (
              <div>
                <div className="card-body">
                  <SelectField
                    isMulti={false}
                    width="60%"
                    options={listDoksByUser || []}
                    label="Dok"
                    handleChange={(value) => {
                      setNameDok(value.title);
                      setFieldValue('dok_id', value.id);
                    }}
                    name="dok_id"
                    value={values.dok_id}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    touched={touched}
                  />

                  <SelectField
                    width="60%"
                    options={specialities || []}
                    label="Spécialité"
                    handleChange={(value) => {
                      if (
                        parseInt(value.pdsCount) <
                        parseInt(parseInt(values.notification_qt))
                      ) {
                        setFieldValue('notification_qt', '');
                        toast.showError(
                          store,
                          `Le nombre de pds de la spécialité ${value.title} est ${value.pdsCount}`,
                        );
                      }
                      if (values.notification)
                        store.dispatch({
                          type: PAYMENT,
                          payload: {
                            payment: {
                              ...payment,
                              notificationCount: parseFloat(
                                countPsdBySpeciality *
                                  (setting?.price_notification || 0.5),
                              ),
                            },
                          },
                        });
                      console.log(value);
                      setCountPsdBySpeciality(value.pdsCount);
                      setFieldValue('speciality_data_source_id', value.id);
                    }}
                    name="speciality_data_source_id"
                    value={values.speciality_data_source_id}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    isMulti={false}
                  />
                  <hr />
                  <h4 className="mb-4 mt-4">Emplacements</h4>
                  <div className="mb-4 col-lg-6 d-lg-flex justify-content-lg-between align-items-lg-center">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="formCheck-1"
                        onChange={(e) => {
                          setFieldValue('notification', e.target.checked);
                          if (e.target.checked) {
                            store.dispatch({
                              type: PAYMENT,
                              payload: {
                                payment: {
                                  ...payment,
                                  notificationCount: parseFloat(
                                    countPsdBySpeciality *
                                      (setting?.price_notification || 0.5),
                                  ),
                                },
                              },
                            });
                            //   setFieldValue('notification_qt', 1);
                          } else {
                            store.dispatch({
                              type: PAYMENT,
                              payload: {
                                payment: {
                                  ...payment,
                                  notificationCount: 0,
                                },
                              },
                            });
                            setFieldValue('notification_qt', '');
                          }
                        }}
                        checked={values.notification}
                        value={values.notification}
                        name="notification"
                      />
                      <label className="form-check-label" htmlFor="formCheck-1">
                        Notification
                      </label>
                    </div>
                    <div className="form-floating col-lg-3">
                      <input
                        placeholder="Quantité"
                        disabled={!values.notification}
                        value={values.notification_qt}
                        type="number"
                        name="notification_qt"
                        className={`form-control ${
                          errors['notification_qt'] &&
                          touched['notification_qt']
                            ? 'is-invalid state-invalid'
                            : !errors['notification_qt'] &&
                              touched['notification_qt'] &&
                              values.notification_qt
                            ? 'is-valid state-valid'
                            : ''
                        }`}
                        onChange={(e) => {
                          handleChange(e);
                          store.dispatch({
                            type: PAYMENT,
                            payload: {
                              payment: {
                                ...payment,
                                notificationCount: parseFloat(
                                  e.target.value *
                                    (setting?.price_notification || 0.5),
                                ),
                              },
                            },
                          });
                          if (
                            parseInt(parseInt(e.target.value)) >
                            parseInt(countPsdBySpeciality || 256)
                          ) {
                            store.dispatch({
                              type: PAYMENT,
                              payload: {
                                payment: {
                                  ...payment,
                                  notificationCount: 0,
                                },
                              },
                            });
                            setFieldValue('notification_qt', '');
                            toast.showError(
                              store,
                              `Le nombre de pds de la spécialité sélectionner est ${countPsdBySpeciality}`,
                            );
                          }
                        }}
                      />
                      <label className="form-label">Quantité</label>
                    </div>
                  </div>
                  <div className="mb-4 col-lg-6 d-lg-flex justify-content-lg-between align-items-lg-center">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="formCheck-4"
                        onChange={(e) => {
                          setFieldValue('sidebar', e.target.checked);
                          if (e.target.checked) {
                            //setFieldValue('sidebar_qt', 1);
                            store.dispatch({
                              type: PAYMENT,
                              payload: {
                                payment: {
                                  ...payment,
                                  lateralBarCount: parseFloat(
                                    0 *
                                      //  (setting?.max_lateral_bar || 1000) *
                                      (setting?.price_lateral_bar || 0.5),
                                  ),
                                },
                              },
                            });
                          } else {
                            store.dispatch({
                              type: PAYMENT,
                              payload: {
                                payment: {
                                  ...payment,
                                  lateralBarCount: 0,
                                },
                              },
                            });
                            setFieldValue('sidebar_qt', '');
                          }
                        }}
                        checked={values.sidebar}
                        value={values.sidebar}
                        name="sidebar"
                      />
                      <label className="form-check-label" htmlFor="formCheck-4">
                        Suggestions en barre latérale
                      </label>
                    </div>
                    <div className="form-floating col-lg-3">
                      <input
                        disabled={!values.sidebar}
                        value={values.sidebar_qt}
                        type="number"
                        name="sidebar_qt"
                        className={`form-control ${
                          errors['sidebar_qt'] && touched['sidebar_qt']
                            ? 'is-invalid state-invalid'
                            : !errors['sidebar_qt'] &&
                              touched['sidebar_qt'] &&
                              values.sidebar_qt
                            ? 'is-valid state-valid'
                            : ''
                        }`}
                        placeholder="Quantité"
                        onChange={(e) => {
                          handleChange(e);
                          store.dispatch({
                            type: PAYMENT,
                            payload: {
                              payment: {
                                ...payment,
                                lateralBarCount: parseFloat(
                                  e.target.value *
                                    //(setting?.max_lateral_bar || 1000) *
                                    (setting?.price_lateral_bar || 0.5),
                                ),
                              },
                            },
                          });
                        }}
                      />
                      <label className="form-label">Quantité</label>
                      <small>x100 impressions</small>
                    </div>
                  </div>
                  <div className="mb-4 col-lg-6 d-lg-flex justify-content-lg-between align-items-lg-center">
                    <div className="form-check">
                      <input
                        onChange={(e) => {
                          setFieldValue('in_search', e.target.checked);
                          if (e.target.checked) {
                            store.dispatch({
                              type: PAYMENT,
                              payload: {
                                payment: {
                                  ...payment,
                                  searchCount: parseFloat(
                                    0 *
                                      //(setting?.max_search || 1000) *
                                      (setting?.price_search || 0.5),
                                  ),
                                },
                              },
                            });
                            //setFieldValue('in_search_qt', 1);
                          } else {
                            store.dispatch({
                              type: PAYMENT,
                              payload: {
                                payment: {
                                  ...payment,
                                  searchCount: 0,
                                },
                              },
                            });
                            setFieldValue('in_search_qt', '');
                          }
                        }}
                        value={values.in_search}
                        checked={values.in_search}
                        name="in_search"
                        type="checkbox"
                        className="form-check-input"
                        id="formCheck-2"
                      />
                      <label className="form-check-label" htmlFor="formCheck-2">
                        Suggestions sous le moteur de recherche
                      </label>
                    </div>
                    <div className="form-floating col-lg-3">
                      <input
                        placeholder="Quantité"
                        disabled={!values.in_search}
                        value={values.in_search_qt}
                        type="number"
                        name="in_search_qt"
                        className={`form-control ${
                          errors['in_search_qt'] && touched['in_search_qt']
                            ? 'is-invalid state-invalid'
                            : !errors['in_search_qt'] &&
                              touched['in_search_qt'] &&
                              values.in_search_qt
                            ? 'is-valid state-valid'
                            : ''
                        }`}
                        onChange={(e) => {
                          handleChange(e);
                          store.dispatch({
                            type: PAYMENT,
                            payload: {
                              payment: {
                                ...payment,
                                searchCount: parseFloat(
                                  e.target.value *
                                    //(setting?.max_search || 1000) *
                                    (setting?.price_search || 0.5),
                                ),
                              },
                            },
                          });
                        }}
                      />
                      <label className="form-label">Quantité</label>
                      <small>x100 impressions</small>
                    </div>
                  </div>
                  <br />
                </div>
                <div className="card-footer d-flex justify-content-between align-items-center">
                  <div>
                    <Link
                      to={
                        hasRole(userProfile && userProfile?.user?.roles, 'CBT')
                          ? '/contributor/campagnes'
                          : '/admin/campagnes'
                      }
                    >
                      <i className="fas fa-reply-all me-2"></i>Retour à la liste
                    </Link>
                  </div>
                  <div>
                    <button
                      onClick={() => navigate(-1)}
                      className="btn btn-danger"
                      type="button"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={(e) => {
                        if (values.notification && !values.notification_qt) {
                        } else if (values.sidebar && !values.sidebar_qt) {
                        } else if (values.in_search && !values.in_search_qt) {
                        } else if (
                          !values.in_search &&
                          !values.sidebar &&
                          !values.notification
                        ) {
                        } else {
                          handleSubmit(e);
                        }
                      }}
                      className={
                        isLoading
                          ? 'link-light btn btn-primary ms-3'
                          : ' login100-form-btn link-light btn btn-primary ms-3'
                      }
                      type="button"
                    >
                      {id ? 'Modifier' : 'Valider'}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    );
};

export default CreateUpdateCampagneCont;
