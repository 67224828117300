import React, { useEffect, useState } from 'react';
import Input from '../../../components/Inputs';
import { Formik } from 'formik';
import { useSelector, useStore } from 'react-redux';
import toast from '../../../utils/toast';
import * as yup from 'yup';
import { updateConfig } from '../../../actions/admin/config/update-config';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { getConfig } from '../../../actions/admin/config/getConfig';
import { Col, Row } from 'react-bootstrap';

const Settings = () => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getConfig(store);
    store.dispatch({
      type: LOADING_STOPED,
      payload: {},
    });
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [loading]);

  const setting = useSelector((state) => state.admin.setting);
  console.log(setting);
  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const result = await updateConfig(values, store);

      if (result.type === 'success') {
        setIsLoading(false);
        toast.showSuccess(store, 'Paramètres mis à jour');
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.showError(
        store,
        error?.response?.data?.message ||
          'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      );
    }
  };

  const loginValidationSchema = yup.object().shape({
    max_search: yup
      .number()
      .typeError('Ne doit être que des chiffres')
      .required('Champ requis'),
    max_lateral_bar: yup
      .number()
      .typeError('Ne doit être que des chiffres')
      .required('Champ requis'),
    price_lateral_bar: yup
      .number()
      .typeError('Ne doit être que des chiffres')
      .required('Champ requis'),
    price_search: yup
      .number()
      .typeError('Ne doit être que des chiffres')
      .required('Champ requis'),
    price_notification: yup
      .number()
      .typeError('Ne doit être que des chiffres')
      .required('Champ requis'),
  });

  return (
    <div className="page-content w-100">
      <div className="card admin-card  shadow-sm border-0">
        <div className="card-header">
          <h5 className="mb-0">Paramétres</h5>
        </div>
        <Formik
          className=""
          initialValues={{
            price_notification: setting?.price_notification || '',
            price_search: setting?.price_search || '',
            price_lateral_bar: setting?.price_lateral_bar || '',
            max_search: setting?.max_search || '',
            max_lateral_bar: setting?.max_lateral_bar || '',
          }}
          validationSchema={loginValidationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            handleChange,
            values,
          }) => (
            <>
              <div className="card-body">
                <Row>
                  <Input
                    handleChange={handleChange}
                    value={values.price_notification}
                    errors={errors}
                    touched={touched}
                    label="Prix notification"
                    type="text"
                    name="price_notification"
                    placeholder="Prix notification"
                    width="col-lg-3"
                    notif1={true}
                    icon={
                      <i
                        style={{ color: '#646464' }}
                        className="fas fa-euro"
                      ></i>
                    }
                  />
                  <div className={`mb-3 col-lg-3`}>
                    <div className="form-group">
                      <label htmlFor="basic-url"> </label>
                      <div className="input-group mb-1">/ Notification</div>
                    </div>
                  </div>
                </Row>

                <Row>
                  <Input
                    notif={true}
                    width="col-lg-3"
                    handleChange={handleChange}
                    value={values.price_search}
                    errors={errors}
                    touched={touched}
                    label="Prix moteur de recherche"
                    type="text"
                    name="price_search"
                    placeholder="Prix moteur de recherche"
                    icon={
                      <i
                        style={{ color: '#646464' }}
                        className="fas fa-euro"
                      ></i>
                    }
                  />
                  <span style={{ marginTop: 36, width: '2%' }} className="">
                    /
                  </span>
                  <Input
                    width="col-lg-2-1"
                    handleChange={handleChange}
                    value={values.max_lateral_bar}
                    errors={errors}
                    touched={touched}
                    label="Nbr impressions"
                    type="text"
                    name="max_lateral_bar"
                    placeholder="Nbr impressions"
                  />
                </Row>
                <Row>
                  <Input
                    notif={true}
                    width="col-lg-3"
                    handleChange={handleChange}
                    value={values.price_lateral_bar}
                    errors={errors}
                    touched={touched}
                    label="Prix barre latérale"
                    type="text"
                    name="price_lateral_bar"
                    placeholder="Prix barre latérale"
                    icon={
                      <i
                        style={{ color: '#646464' }}
                        className="fas fa-euro"
                      ></i>
                    }
                  />
                  <span style={{ marginTop: 36, width: '2%' }} className="">
                    /
                  </span>
                  <Input
                    width="col-lg-2-1"
                    handleChange={handleChange}
                    value={values.max_search}
                    errors={errors}
                    touched={touched}
                    label="Nbr impressions"
                    type="text"
                    name="max_search"
                    placeholder="Nbr impressions"
                  />
                </Row>
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <div>
                  <button
                    onClick={handleSubmit}
                    className={
                      isLoading
                        ? 'link-light btn btn-primary ms-3'
                        : ' login100-form-btn link-light btn btn-primary ms-3'
                    }
                    type="button"
                  >
                    Valider
                  </button>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Settings;
