import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';

import { REMOVE_DOK } from '../redux/reducers/mosaic';

export async function deleteDok(id, removedIds, index, store, type) {
  const { data } = await apiCall({
    method: 'delete',
    url: '/api/dok/destroy/' + id,
  });
  if (data?.type === 'success') {
    if (!type)
      store.dispatch({
        type: REMOVE_DOK,
        payload: {
          removedIds,
          index,
        },
      });
    return data;
  } else {
    const message = data?.message
      ? data?.message
      : 'error occured while deleting the card';
    toast.showError(store, message);
    return data;
  }
}
