import { apiCall } from '../utils/helpers';
import { GET_ALL_DOKS_BY_USER } from '../redux/reducers/admin';

export async function getDoksByUser(store) {
  const url = `/api/dok?limit=100&current_page=1`;
  const { data } = await apiCall({
    method: 'get',
    url,
  });
  console.log(data);
  if (data?.total > 0)
    store.dispatch({
      type: GET_ALL_DOKS_BY_USER,
      payload: {
        list: data.data,
      },
    });
  else
    store.dispatch({
      type: GET_ALL_DOKS_BY_USER,
      payload: {
        list: [],
      },
    });
  return data;
}
