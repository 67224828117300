import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { REMOVE_NOTIFICATIONS_USER } from '../redux/reducers/pds';

export async function deleteNotification(id, removedIds, store) {
  const { data } = await apiCall({
    method: 'delete',
    url: '/api/notification/destroy/' + id,
  });
  console.log(data);
  if (data?.type === 'success') {
    store.dispatch({
      type: REMOVE_NOTIFICATIONS_USER,
      payload: {
        removedIds,
      },
    });
    return data;
  } else {
    const message = data?.message
      ? data?.message
      : 'error occured while deleting the card';
    toast.showError(store, message);
    return data;
  }
}
