import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Input from '../../../components/Inputs';
import { Formik } from 'formik';
import { useSelector, useStore } from 'react-redux';
import toast from '../../../utils/toast';
import * as yup from 'yup';
import SelectField from '../../../components/Select/Select';
import SkeletonCreateUpdatePds from './Components/skeletonCreateUpdatePds';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Col, Row } from 'react-bootstrap';
import { createUpdateCampagne } from '../../../actions/admin/create-update-campagne';
import { getCampagne } from '../../../actions/admin/getCampagne';
import { getDoksByUser } from '../../../actions/getDoksByUser';
import { getActiveSpecialities } from '../../../actions/getActiveSpecialities';

const CreateUpdateCampagne = () => {
  let navigate = useNavigate();
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [countPsdBySpeciality, setCountPsdBySpeciality] = useState(false);
  const paramsExpertise = useSelector((state) => state?.admin?.paramsExpertise);
  let { id } = useParams();
  useEffect(() => {
    getActiveSpecialities(store, { ...paramsExpertise, limit: 300 });
    getDoksByUser(store);
    return () => {};
  }, [store]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id) {
      getCampagne(id, store);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => {};
  }, [store, loading]);

  const listDoksByUser = useSelector((state) => state?.admin?.listDoksByUser);
  const campagne = useSelector((state) => state?.admin?.campagne);
  const onSubmit = async (values) => {
    console.log(values);
    setIsLoading(true);
    try {
      const result = await createUpdateCampagne(id, values, store);
      if (result.type === 'success') {
        navigate('/admin/campagnes');
        setIsLoading(false);
        toast.showSuccess(store, result?.message);
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
      }
      //}
    } catch (error) {
      console.log(error?.response?.data?.message);
      setIsLoading(false);
      toast.showError(
        store,
        error?.response?.data?.message ||
          'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      );
    }
  };
  const createUpdateCampagneValidationSchema = yup.object({
    speciality_data_source_id: yup.string().required('Champ requis'),
    dok_id: yup.string().required('Champ requis'),
    in_search_qt: yup.number(),
    sidebar_qt: yup.number(),
    notification_qt: yup.number(),
    in_search: yup.boolean(),
    //in_search: yup.boolean(),
    sidebar: yup.boolean(),
    notification: yup.boolean(),
    /*starter_kit: yup.boolean(),
    end_date: yup.date().required('Champ requis'),
    start_date: yup.date().required('Champ requis'),*/
  });
  console.log(createUpdateCampagneValidationSchema);
  const specialities = useSelector(
    (state) => state?.speciality?.activeSpecialities,
  );
  console.log(specialities);

  if (loading)
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <SkeletonCreateUpdatePds />
        </div>
      </div>
    );
  else
    return (
      <div className="page-content w-100">
        <div className="card admin-card shadow-sm border-0">
          <div className="card-header">
            <h5 className="mb-0">
              {id ? 'Modifier une campagne' : 'Créer une campagne'}
            </h5>
          </div>
          <Formik
            initialValues={{
              speciality_data_source_id:
                campagne?.speciality_data_source?.id || '',
              /*start_date:
                  moment(campagne?.start_date).format('YYYY-MM-DD') || '',
                end_date: moment(campagne?.end_date).format('YYYY-MM-DD') || '',
                starter_kit: campagne?.starter_kit || false,*/
              notification: campagne?.notification || false,
              sidebar: campagne?.sidebar || false,
              in_search: campagne?.in_search || false,
              sidebar_qt: campagne?.sidebar_qt
                ? campagne?.sidebar_qt / 1000
                : 0,
              notification_qt: campagne?.notification_qt
                ? campagne?.notification_qt
                : 0,
              in_search_qt: campagne?.in_search_qt
                ? campagne?.in_search_qt / 1000
                : 0,
              dok_id: campagne?.document.id || '',
            }}
            validationSchema={createUpdateCampagneValidationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              handleChange,
              values,
            }) => (
              <div>
                <div className="card-body">
                  <SelectField
                    isMulti={false}
                    width="col-lg-6"
                    options={listDoksByUser || []}
                    label="Dok"
                    handleChange={({ value }) => setFieldValue('dok_id', value)}
                    name="dok_id"
                    value={values.dok_id}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    touched={touched}
                  />

                  <SelectField
                    width="col-lg-6"
                    options={specialities || []}
                    label="Spécialité"
                    handleChange={(value) => {
                      if (
                        parseInt(value.pdsCount) <
                        parseInt(parseInt(values.notification_qt))
                      ) {
                        setFieldValue('notification_qt', '');
                        toast.showError(
                          store,
                          `Le nombre de pds de la spécialité ${value.title} est ${value.pdsCount}`,
                        );
                      }
                      console.log(value);
                      setCountPsdBySpeciality(value.pdsCount);
                      setFieldValue('speciality_data_source_id', value.id);
                    }}
                    name="speciality_data_source_id"
                    value={values.speciality_data_source_id}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    isMulti={false}
                  />
                  {/*   <hr />
                  <Row style={{ minHeight: 70 }}>
                      <Col md={1}>Emplacements</Col>
                      <Col md={8}>
                        <div className="input-group">
                          <label className="custom-control custom-checkbox ">
                            <input
                              className=""
                              onChange={(e) =>
                                setFieldValue('starter_kit', e.target.checked)
                              }
                              checked={values.starter_kit}
                              value={values.starter_kit}
                              name="starter_kit"
                              type="checkbox"
                            />{' '}
                            <span className="custom-control-label">
                              Start Kit{' '}
                              <span style={{ fontSize: 12 }}>
                                (La position est définie en fonction de l'ordre
                                des réservations)
                              </span>
                            </span>
                          </label>
                        </div>
                        <div
                          className="input-group"
                          data-bs-validate="Valid email is required: ex@abc.xyz"
                        >
                          <label className="custom-control custom-checkbox">
                            <input
                              onChange={(e) =>
                                setFieldValue('notification', e.target.checked)
                              }
                              checked={values.notification}
                              value={values.notification}
                              name="notification"
                              type="checkbox"
                            />{' '}
                            <span className="custom-control-label">
                              Notification
                            </span>
                          </label>
                        </div>
                      </Col>
                    </Row>*/}
                  {/*<Row style={{ minHeight: 70 }}>
                      <Col md={4}>
                        <Input
                          width="60%"
                          handleChange={handleChange}
                          value={values.start_date}
                          errors={errors}
                          touched={touched}
                          label="Date de début"
                          type="date"
                          name="start_date"
                          handleSubmit={handleSubmit}
                          placeholder="Date de début"
                        />
                      </Col>
                      <Col md={4}>
                        <Input
                          width="60%"
                          handleChange={handleChange}
                          value={values.end_date}
                          errors={errors}
                          touched={touched}
                          label="Date de fin"
                          type="date"
                          name="end_date"
                          handleSubmit={handleSubmit}
                          placeholder="Date de fin"
                        />
                      </Col>
                    </Row>*/}
                  <hr />

                  <Row style={{ minHeight: 70 }}>
                    <Col md={2}>Emplacements</Col>
                    <Col md={4}>
                      <div style={{ height: 48 }} className="input-group">
                        <label className="custom-control custom-checkbox ">
                          <input
                            onChange={(e) => {
                              setFieldValue('notification', e.target.checked);
                              if (e.target.checked) {
                                setFieldValue('notification_qt', 1);
                              } else {
                                setFieldValue('notification_qt', '');
                              }
                            }}
                            checked={values.notification}
                            value={values.notification}
                            name="notification"
                            type="checkbox"
                          />{' '}
                          <span className="custom-control-label">
                            Notification
                          </span>
                        </label>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div style={{ gap: 10 }} className="input-group">
                        <label className="custom-control custom-checkbox ">
                          Quantité
                        </label>
                        <Input
                          disabled={!values.notification}
                          handleChange={(e) => {
                            handleChange(e);
                            if (
                              parseInt(parseInt(e.target.value)) >
                              parseInt(countPsdBySpeciality || 256)
                            ) {
                              setFieldValue('notification_qt', '');
                              toast.showError(
                                store,
                                `Le nombre de pds de la spécialité sélectionner est ${countPsdBySpeciality}`,
                              );
                            }
                          }}
                          handleSubmit={handleSubmit}
                          value={values.notification_qt}
                          errors={values.notification && errors}
                          touched={touched}
                          type="number"
                          name="notification_qt"
                          width="col-lg-3"
                          min={0}
                        />
                        <label className="custom-control custom-checkbox ">
                          {countPsdBySpeciality &&
                            `(${countPsdBySpeciality}
                              maximum)`}
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ minHeight: 70 }}>
                    <Col md={2}></Col>
                    <Col md={4}>
                      <div style={{ height: 48 }} className="input-group">
                        <label className="custom-control custom-checkbox ">
                          <input
                            onChange={(e) => {
                              setFieldValue('sidebar', e.target.checked);
                              if (e.target.checked) {
                                setFieldValue('sidebar_qt', 1);
                              } else {
                                setFieldValue('sidebar_qt', '');
                              }
                            }}
                            checked={values.sidebar}
                            value={values.sidebar}
                            name="sidebar"
                            type="checkbox"
                          />{' '}
                          <span className="custom-control-label">
                            Suggestions en barre latérale
                          </span>
                        </label>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div style={{ gap: 10 }} className="input-group">
                        <label className="custom-control custom-checkbox ">
                          Quantité
                        </label>
                        <Input
                          disabled={!values.sidebar}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          value={values.sidebar_qt}
                          errors={values.sidebar && errors}
                          touched={touched}
                          type="number"
                          name="sidebar_qt"
                          width="col-lg-3"
                          min={0}
                        />
                        <label className="custom-control custom-checkbox ">
                          x1000 impressions
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ minHeight: 70 }}>
                    <Col md={2}></Col>
                    <Col md={4}>
                      <div
                        className="input-group"
                        data-bs-validate="Valid email is required: ex@abc.xyz"
                      >
                        <label className="custom-control custom-checkbox">
                          <input
                            onChange={(e) => {
                              setFieldValue('in_search', e.target.checked);
                              if (e.target.checked) {
                                setFieldValue('in_search_qt', 1);
                              } else {
                                setFieldValue('in_search_qt', '');
                              }
                            }}
                            value={values.in_search}
                            checked={values.in_search}
                            name="in_search"
                            type="checkbox"
                          />{' '}
                          <span className="custom-control-label">
                            Suggestions sous le moteur de recherche
                          </span>
                        </label>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div style={{ gap: 10 }} className="input-group">
                        <label className="custom-control custom-checkbox ">
                          Quantité
                        </label>
                        <Input
                          disabled={!values.in_search}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          value={values.in_search_qt}
                          errors={values.in_search && errors}
                          touched={touched}
                          type="number"
                          name="in_search_qt"
                          width="col-lg-3"
                          min={0}
                        />
                        <label className="custom-control custom-checkbox ">
                          x1000 impressions
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />
                </div>
                <div className="card-footer d-flex justify-content-between align-items-center">
                  <div>
                    <Link to="/admin/campagnes">
                      <i className="fas fa-reply-all me-2"></i>Retour à la liste
                    </Link>
                  </div>
                  <div>
                    <button
                      onClick={() => navigate('/admin/campagnes')}
                      className="btn btn-danger"
                      type="button"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={handleSubmit}
                      className={
                        isLoading
                          ? 'link-light btn btn-primary ms-3'
                          : ' login100-form-btn link-light btn btn-primary ms-3'
                      }
                      type="button"
                    >
                      {id ? 'Modifer' : 'Ajouter'}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    );
};

export default CreateUpdateCampagne;
