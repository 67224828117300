import React, { useState } from 'react';
import { notificationIsSeen } from '../../../actions/notification-is-seen';
import { useSelector, useStore } from 'react-redux';
import { getALlNotificationsUser } from '../../../actions/getALlNotificationsUser';

export default function Notification({
  notification,
  setModalPageDok,
  setDataDok,
  setDataNotif,
  setModalSowDescriptionDok,
  setIndex,
  setModalDeleteNotif,
  index,
  openDok,
}) {
  const [imgError, setImgError] = useState(false);
  const store = useStore();
  const paramsNotifications = useSelector(
    (state) => state?.pds?.paramsNotifications,
  );
  return (
    <div
      key={notification.id}
      className="d-flex flex-row align-items-lg-center card p-4 mb-4"
    >
      <div
        onClick={async () => {
          setIndex(index);
          if (!notification.is_seen) {
            await notificationIsSeen(notification.id, store);
            await getALlNotificationsUser(store, paramsNotifications);
          }
          if (notification?.dok_id || notification?.document) {
            openDok(notification?.dok_id || notification?.document?.id);
          }
          if (notification?.notification.document) {
            setDataDok(notification?.notification.document);
            setModalPageDok(true);
          } else {
            setDataNotif(notification?.notification);
            setModalSowDescriptionDok(true);
          }
        }}
        style={{ display: 'contents', cursor: 'pointer' }}
      >
        <div>
        {!imgError ? (
          <img
            alt=""
            src={
              notification?.notification.type === 'dokatis'
                ? require('../../../assets/template/img/notification_ring_icon.png')
                : require('../../../assets/template/img/dok.PNG')
            }
            className="brround"
            style={{
              borderRadius: '1%',
              color: 'rgb(153,157,161)',
              fontSize: '4em',
              width: '1em',
              height: '1em'
            }}
            onError={() => setImgError(true)}
          />
        ) : (
          <div>
            <i
              className="material-icons"
              style={{
                borderRadius: '50%',
                background: 'var(--bs-gray-300)',
                color: 'rgb(153, 157, 161)',
                fontSize: '6em',
              }}
            >
              person
            </i>
          </div>
        )}
        </div>
        <div style={{ width: '100%' }} className="ms-4">
          {notification?.notification.type === 'dokatis' ? (
            <span style={{ fontSize: 15, fontWeight: 700, color: '#00B0F0' }}>
              Dokatis vous informe:
              <br />
            </span>
          ) : (
            <span style={{ fontSize: 15, fontWeight: 700, color: '#bebebe' }}>
              {notification?.notification.title}
              <br />
            </span>
          )}

          <p className="mt-3 mb-0">
            {notification?.notification.message ||
              notification?.notification?.document?.title}
          </p>
        </div>
      </div>
      <div>
        {notification.is_seen ? (
          <button
            onClick={() => {
              setDataNotif(notification);
              setModalDeleteNotif(true);
            }}
            className="btn btn-danger btn-sm btn-action"
          >
            <i className="fas fa-times"></i>
          </button>
        ) : (
          <svg height="14" width="14">
            <circle cx="6" cy="6" r="6" fill="#0d6efd"></circle>
          </svg>
        )}
      </div>
    </div>
  );
}
