import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import Input from '../../../components/Inputs';
import toast from '../../../utils/toast';
import { Card } from 'react-bootstrap';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createUpdateCode } from '../../../actions/admin/create-update-code';

const CreateUpdateCode = ({ setOpenModal, lists, setIsLoadingPage }) => {
  console.log(lists);
  let navigate = useNavigate();
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const notificationValidationSchema = yup.object().shape({
    title: yup.string().required('Champ requis !'),
    reduction: yup.string().required('Champ requis !'),
  });
  let { id } = useParams();
  const onSubmit = async (values) => {
    try {
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      setIsLoading(true);
      const result = await createUpdateCode(id || null, values, store);
      if (result?.type === 'success') {
        setIsLoading(false);
        setIsLoadingPage(true);
        setOpenModal(false);
        toast.showSuccess(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response)
        toast.showError(
          store,
          error?.response?.data?.message ||
            'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        );
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };

  return (
    <div className="page-content w-100">
      <div className="card admin-card  shadow-sm border-0">
        <div className="card-header">
          <h5 className="mb-0">{id ? 'Modifier un code' : 'Créer un code'}</h5>
        </div>
        <Formik
          initialValues={{
            title: '',
            reduction: '',
          }}
          validationSchema={notificationValidationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            handleChange,
            values,
          }) => (
            <>
              <div className="card-body">
                <Input
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  width="col-lg-6"
                  value={values.title}
                  errors={errors}
                  touched={touched}
                  label="Code"
                  type="string"
                  name="title"
                  placeholder="Code"
                />
                <Input
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  width="col-lg-6"
                  value={values.reduction}
                  errors={errors}
                  touched={touched}
                  label="Réduction"
                  type="string"
                  name="reduction"
                  placeholder="Réduction"
                />
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
                <div>
                  <Link to="/admin/advantages-code">
                    <i className="fas fa-reply-all me-2"></i>Retour à la liste
                  </Link>
                </div>
                <div>
                  <button
                    onClick={() => navigate('/admin/advantages-code')}
                    className="btn btn-danger"
                    type="button"
                  >
                    Annuler
                  </button>
                  <button
                    onClick={handleSubmit}
                    className={
                      isLoading
                        ? 'link-light btn btn-primary ms-3'
                        : ' login100-form-btn link-light btn btn-primary ms-3'
                    }
                    type="button"
                  >
                    Valider
                  </button>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateUpdateCode;
