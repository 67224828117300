import toast from '../utils/toast';
import { apiCall } from '../utils/helpers';
import { ADD_DOK, UPDATE_LIST_DOK } from '../redux/reducers/mosaic';
import {
  REMOVE_SELECTION_DOK,
  UPDATE_SELECTION_DOK,
} from '../redux/reducers/dok';

export async function createUpdateDok(
  id,
  values,
  store = {},
  indexList,
  index,
  type,
  mySelection,
) {
  if (!id) delete values.list_doc_id;
  delete values.skill;
  const { data } = await apiCall({
    method: id ? 'patch' : 'post',
    url: id ? `/api/dok/update/${id}` : `/api/dok/create`,
    data: values,
  });
  if (data?.type === 'success') {
    if (type) {
      if (type === 'mosaic') {
        if (id)
          store.dispatch({
            type: REMOVE_SELECTION_DOK,
            payload: {
              index,
            },
          });
      } else if (!mySelection) {
        if (id)
          store.dispatch({
            type: UPDATE_SELECTION_DOK,
            payload: {
              index,
              data: data.data,
            },
          });
      }
    } else {
      if (!id) {
        if (mySelection) {
        } else
          store.dispatch({
            type: ADD_DOK,
            payload: {
              listId: values.list_id,
              index,
              data: data.data,
            },
          });
      } else
        store.dispatch({
          type: UPDATE_LIST_DOK,
          payload: {
            listId: values.list_id,
            index,
            data: data.data,
          },
        });
    }

    //toast.showSuccess(store, data?.message);
  } else {
    const message = data?.message ? data?.message :  'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.';
    toast.showError(store, message);
  }
  return data;
}
