import { apiCall } from '../../utils/helpers';
import { GET_ALL_CAMPAGNES } from '../../redux/reducers/admin';

export async function getAllCampagnes(store, params, type) {
  try {
    let url = `/api/campagnes?type=${type || ''}&limit=${
      params.limit || 10
    }&current_page=${params.current_page}`;
    if (params.search && params.search !== 'undefined') {
      url += `&search=${params.search}`;
    }
    if (params.order && params.order !== 'undefined') {
      url += `&order=${params.order}`;
    }
    if (params.field && params.field !== 'undefined') {
      url += `&field=${params.field}`;
    }
    if (params.status && params.status !== 'undefined') {
      url += `&status=${params.status}`;
    }

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data?.total > 0) {
      store.dispatch({
        type: GET_ALL_CAMPAGNES,
        payload: {
          list: data,
          params,
        },
      });
    } else
      store.dispatch({
        type: GET_ALL_CAMPAGNES,
        payload: {
          list: { data: [] },
          params,
        },
      });
    return data;
  } catch (error) {
    store.dispatch({
      type: GET_ALL_CAMPAGNES,
      payload: {
        list: { data: [] },
        params,
      },
    });
  }
}
