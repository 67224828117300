import { createSlice } from '@reduxjs/toolkit';

export const statisticSlice = createSlice({
  name: 'statistic',
  initialState: {
    nbrDokByMonth: null,
    pdsBySpeciality: null,
    keywords: null,
    nbrDokReference: null,
    statsOpenDok: null,
  },
  reducers: {
    getNbrDokByMonth: (state, action) => {
      const { nbrDokByMonth } = action.payload;
      state.nbrDokByMonth = nbrDokByMonth;
    },
    getPdsBySpeciality: (state, action) => {
      const { pdsBySpeciality } = action.payload;
      state.pdsBySpeciality = pdsBySpeciality;
    },
    getKeywords: (state, action) => {
      const { keywords } = action.payload;
      state.keywords = keywords;
    },
    getNbrDokReference: (state, action) => {
      const { nbrDokReference } = action.payload;
      state.nbrDokReference = nbrDokReference;
    },
    getStatsOpenDoks: (state, action) => {
      const { statsOpenDok } = action.payload;
      state.statsOpenDok = statsOpenDok;
    },
  },
});

export const GET_NBR_DOK_BY_MONTH = 'statistic/getNbrDokByMonth';
export const GET_PDS_BY_SPECIALITY = 'statistic/getPdsBySpeciality';
export const GET_KEYWORDS = 'statistic/getKeywords';
export const GET_NBR_DOK_REFERENCE = 'statistic/getNbrDokReference';
export const GET_STATS_OPEN_DOK = 'statistic/getStatsOpenDoks';

export default statisticSlice.reducer;
