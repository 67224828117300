import { createSlice } from '@reduxjs/toolkit';
import { swap } from 'react-grid-dnd';

export const mosaicSlice = createSlice({
  name: 'mosaic',
  initialState: {
    isLoadingPage: false,
    lines: [],
    columns: [],
  },
  reducers: {
    updateOrder: (state, action) => {
      const { sourceIndex, targetIndex } = action.payload;
      state.columns = swap(state.columns, sourceIndex, targetIndex);
    },

    loading: (state, action) => {
      const { load } = action.payload;
      state.isLoadingPage = load;
    },
    getList: (state, action) => {
      const { data } = action.payload;
      state.lines = data.lines;
      state.columns = data.columns;
    },
    getListById: (state, action) => {
      const { list } = action.payload;
      state.listById = list;
    },
    add: (state, action) => {
      const { data, index } = action.payload;
      state.columns[index].type = 'list';
      state.columns[index].list = data;
    },
    addDok: (state, action) => {
      const { data, listId, index } = action.payload;
      state.columns[index].document = data;
      state.columns[index].type = 'doc';
    },
    update: (state, action) => {
      const { data, index } = action.payload;
      state.columns[index].list = {
        ...data,
      };
    },
    updateDok: (state, action) => {
      const { data, index, listId } = action.payload;
      state.columns[index].document = { ...data };
    },
    removeDok: (state, action) => {
      const { index } = action.payload;
      state.columns[index].document = null;
      state.columns[index].type = 'empty';
    },
    removeList: (state, action) => {
      const { index } = action.payload;
      state.columns[index].list = null;
      state.columns[index].type = 'empty';
    },
  },
});

export const GET_LIST_LINE = 'mosaic/getList';
export const GET_LIST_BY_ID = 'mosaic/getListById';
export const ADD_LIST = 'mosaic/add';
export const UPDATE_LIST = 'mosaic/update';
export const UPDATE_LIST_DOK = 'mosaic/updateDok';
export const REMOVE_DOK = 'mosaic/removeDok';
export const REMOVE_LIST = 'mosaic/removeList';
export const ADD_DOK = 'mosaic/addDok';
export const LOADING = 'mosaic/loading';
export const UPDATE_ORDER = 'mosaic/updateOrder';

export default mosaicSlice.reducer;
