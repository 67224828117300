import React from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalComposent(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      {props.title && (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{ fontWeight: 500 }}>{props.title}</span>
          </Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>{props.body}</Modal.Body>
    </Modal>
  );
}

export default ModalComposent;
