import { apiCall } from '../utils/helpers';
import { COUNT_NOTIFICATIONS_USER } from '../redux/reducers/pds';

export async function countNotifications(store) {
  try {
    let url = `/api/notification/count`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    if (data?.data > 0) {
      store.dispatch({
        type: COUNT_NOTIFICATIONS_USER,
        payload: {
          count: data?.data,
        },
      });
    } else
      store.dispatch({
        type: COUNT_NOTIFICATIONS_USER,
        payload: {
          count: 0,
        },
      });
    return data;
  } catch (error) {
    store.dispatch({
      type: COUNT_NOTIFICATIONS_USER,
      payload: {
        count: 0,
      },
    });
  }
}
