import { createSlice } from '@reduxjs/toolkit';
import { swap } from 'react-grid-dnd';
const initialColumns = [
  {
    id: 'e0f294fb-e063-4710-ab27-10ebe8e6c78f',
    type: 'empty',
    order: 1,
    list: null,
    document: null,
  },
  {
    id: '194180b5-c813-4486-9b16-a852a521e835',
    type: 'empty',
    order: 2,
    list: null,
    document: null,
  },
  {
    id: '6442ed4d-b0ac-4e29-8de8-dfc694101b99',
    type: 'empty',
    order: 3,
    list: null,
    document: null,
  },
  {
    id: '9e784c1e-7c2d-4ab4-803c-4fa9c6c67df5',
    type: 'empty',
    order: 4,
    list: null,
    document: null,
  },
  {
    id: '33dd70a3-ab1b-4b96-b10e-5716782c0041',
    type: 'empty',
    order: 5,
    list: null,
    document: null,
  },
  {
    id: 'be286b68-c257-428d-8790-64b0d14ace5f',
    type: 'empty',
    order: 6,
    list: null,
    document: null,
  },
  {
    id: '60ce1dde-cf4a-45ec-a4ed-4ba5fe50cc68',
    type: 'empty',
    order: 7,
    list: null,
    document: null,
  },
  {
    id: 'c43d791a-8b66-45b5-9f0b-312faefc987f',
    type: 'empty',
    order: 8,
    list: null,
    document: null,
  },
  {
    id: '16124b97-1bfb-4849-ba7d-3667b8ee6445',
    type: 'empty',
    order: 9,
    list: null,
    document: null,
  },
  {
    id: 'd6c55fe6-de26-439d-af93-84f356f0365d',
    type: 'empty',
    order: 10,
    list: null,
  },
  {
    id: 'd0faa970-0b11-4f72-a781-653d731a5087',
    type: 'empty',
    order: 11,
    list: null,
    document: null,
  },
  {
    id: '303ab559-dc1f-4b2e-963e-d79ae9f5b94b',
    type: 'empty',
    order: 12,
    list: null,
    document: null,
  },
  {
    id: '302dee43-e0fd-4636-9681-c10732f77d1a',
    type: 'empty',
    order: 13,
    list: null,
    document: null,
  },
  {
    id: 'a2a60f4e-93d3-4c11-81d3-5e7f097c2c43',
    type: 'empty',
    order: 14,
    list: null,
    document: null,
  },
  {
    id: '14136f7e-f1c6-4dc2-9817-363a8760fd11',
    type: 'empty',
    order: 15,
    list: null,
    document: null,
  },
  {
    id: '92cbe0de-74ec-4d1e-b04b-c1979ce777d0',
    type: 'empty',
    order: 16,
    list: null,
    document: null,
  },
];
export const starterKitSlice = createSlice({
  name: 'starter_kit',
  initialState: {
    starterKit: null,
    list: { data: [] },
    columns: initialColumns,
    params: {
      current_page: 1,
      limit: 10,
      search: '',
    },
  },
  reducers: {
    updateOrder: (state, action) => {
      const { sourceIndex, targetIndex } = action.payload;
      console.log(sourceIndex);
      console.log(targetIndex);
      state.columns = swap(state.columns, sourceIndex, targetIndex);
    },
    add: (state, action) => {
      const { value, index } = action.payload;
      console.log(state.columns[index])
      if (value) {
        state.columns[index].type = 'dok';
        state.columns[index].document = value;
      } else {
        state.columns[index].type = 'empty';
        state.columns[index].document = null;
      }
    },
    getList: (state, action) => {
      const { list, params } = action.payload;
      state.list = list;
      state.params = params;
    },
    getOne: (state, action) => {
      const { data } = action.payload;
      state.starterKit = data;
      state.columns = data.columns;
    },
    remove: (state, action) => {
      const { removedIds } = action.payload;
      state.list.data.splice(removedIds, 1);
    },
  },
});

export const GET_LIST_STARTER_KIT = 'starter_kit/getList';
export const GET_STARTER_KIT = 'starter_kit/getOne';
export const UPDATE_STARTER_KIT = 'starter_kit/updateOrder';
export const ADD_STARTER_KIT = 'starter_kit/add';
export const REMOVE_STARTER_KIT = 'starter_kit/remove';

export default starterKitSlice.reducer;
